import React, { useCallback, useEffect, useState } from "react";
import { useTheme } from '@mui/material/styles';
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import CardActions from '@mui/material/CardActions';
import TableRow from '@mui/material/TableRow';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Paper from '@mui/material/Paper';
import { useTranslation } from "react-i18next";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {  listNewDashboard } from "../../services/Services";
import { createObject, encryptForm } from "../../services/commonService";
import { BarChart, Bar, XAxis, YAxis, Legend, LabelList, ResponsiveContainer } from "recharts";

const CustomRadio = styled(Radio)(() =>({
  '&.Mui-checked': {
    color: '#8B9635',
  },
}))

const currencyList = ['EUR','CHF','USD','GBP'];

const openInvoiceDays = [
  {
    'day':'current',
    'label':'current',
    'color':'green'
  },
  {
    'day':'6to10days',
    'label':'6to10days',
    'color':'#5dff5d'
  },
  {
    'day':'11to14days',
    'label':'11to14days',
    'color':'yellow'
  },
  {
    'day':'15to30days',
    'label':'15to30days',
    'color':'orange'
  },
  {
    'day':'31days',
    'label':'31days',
    'color':'red'
  }]

const dataset = [
  {
    value: 0,month: 'Jan'
  },
  {
    value: 0,month: 'Feb'
  },
  {
    value: 0,month: 'Mar'
  },
  {
    value: 0,month: 'Apr'
  },
  {
    value: 0,month: 'May'
  },
  {
    value: 0,month: 'June'
  },
  {
    value: 0,month: 'July'
  },
  {
    value: 0,month: 'Aug'
  },
  {
    value: 0,month: 'Sept'
  },
  {
    value: 0,month: 'Oct'
  },
  {
    value: 0,month: 'Nov'
  },
  {
    value: 0,month: 'Dec'
  }];

  const CustomCard = ({ label, currency, amount, t, amountFormatter }) => {
    const StyledCard = styled(Card)(({ theme }) => ({
      background: theme.palette.mode === "light" ? "#f7f7f7" : "text.secondary",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
      '& .MuiCardContent-root:last-child': {
        paddingBottom: 0,
      },
    }));
  
    return (
      <StyledCard>
        <CardContent sx={{paddingBottom:'0px'}}>
          <Typography variant="body2" >
            {currency ? `${t(label)} ${currency}` : t(label)}
          </Typography>
        </CardContent>
        <CardActions
          sx={{
            marginTop: "auto", 
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            paddingLeft: 2,
          }}
        >
          {label === "open_reminder_total" && amount?.length > 0 ? (
            <Box>
              {amount.map((amt, index) => (
                <Box
                  key={index}
                  display="flex"
                  justifyContent="flex-start"
                  width="100%"
                >
                  <Typography
                    variant="p"
                    component="p"
                    sx={{ fontSize: "0.9rem", fontWeight: "500" }}
                  >
                    {amt.currency} :
                  </Typography>
                  <Typography
                    variant="p"
                    component="p"
                    sx={{ fontSize: "0.9rem", fontWeight: "500", marginLeft: 1 }}
                  >
                    {amountFormatter(amt.value)}
                  </Typography>
                </Box>
              ))}
            </Box>
          ) : (
            <Typography
              variant="h5"
              component="div"
              sx={{ fontWeight: "500", textAlign: "left" }} 
            >
              {amount}
            </Typography>
          )}
        </CardActions>
      </StyledCard>
    );
  };

const GeneralTable = ({ data, columns, t, noDataMessage }) => {
  return (
    <TableContainer component={Paper} sx={{ minHeight: '197px' }}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {columns.map((col) => (
              <TableCell key={col.key} align={col.align || 'left'}>
                {col.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length > 0 ? (
            data.map((row, index) => (
              <TableRow
                key={row.key || index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                {columns.map((col) => (
                  <TableCell
                    key={col.key}
                    align={col.align || 'left'}
                    sx={{ border: 'none' }}
                  >
                    {col.render ? col.render(row[col.key], row) : row[col.key]}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell
                colSpan={columns.length}
                align="center"
                sx={{
                  border: 'none',
                  color: 'gray',
                  verticalAlign: 'middle',
                  textAlign: 'center',
                  height: '150px',
                }}
              >
                {noDataMessage || t('common_no_data_available')}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

function OverviewDashboard() {
  const { i18n,t } = useTranslation(["language", "common"]);
  const [currency,setCurrency] = useState('CHF');
  const [card,setCard] = useState({});
  const [openInvoices,setOpenInvoices] = useState(openInvoiceDays);
  const [topInvoices,setTopInvoices] = useState([]);
  const [revenue ,setRevenue] = useState(dataset);
 
  const invoiceColumns = [
    {
      key: 'label',
      label: t('STATUS'),
      render: (value, row) =>(
          <Box display="flex" alignContent="center">
            <FiberManualRecordIcon fontSize="small" sx={{ color: row.color }} />
            &nbsp; {t(value)}
          </Box>
        ),
    },
    {
      key: 'count',
      label: `#${t('common_invoices').toUpperCase()}`,
      align: 'right',
    },
    {
      key: 'amount',
      label: t('TOTAL'),
      align: 'right',
      render: (value) => (value ? amountFormatter(value) : value),
    },
  ];

  const supplierColumns = [
    {
      key: 'name',
      label: t('NAME')
    },
    {
      key: 'currency',
      label: t('currency').toUpperCase(),
      align: 'right',
    },
    {
      key: 'amount',
      label: t('TOTAL'),
      align: 'right',
      render: (value) => (value ? amountFormatter(value) : value),
    },
  ];

  const theme = useTheme();
  const currentLanguage = i18n.language;

  const amountFormatter = useCallback((amount)=>{
    if(currentLanguage === 'en'){
      return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(amount);
    }else{
      return new Intl.NumberFormat('de-CH', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(amount);
    }
  },[currentLanguage])

  const renderCustomizedLabel = useCallback((props) => {
    const { x, y, width, value } = props;
    const radius = 10;
  
    return (
      <g>
        {value && (
          <text
            x={x + width / 2}
            y={y - radius}
            textAnchor="middle"
            dominantBaseline="middle"
            fill=" #8B9635"
          >
            {amountFormatter(value)}
          </text>
        )}
      </g>
    );
  },[amountFormatter]);

  const getOpenInvoiceData = useCallback(() => {
      const _formData = new FormData();
      _formData.append("currency", currency);
      _formData.append("isAuthUser", '');
      let encryptedToken = encryptForm(createObject(_formData));
      let encrData = new FormData();
      encrData.append("encrToken", encryptedToken);
      setTopInvoices([]);

      listNewDashboard(encrData)
        .then(res => {
          if (res.status) {
            let tableData = openInvoiceDays.map((invoice)=>{
              let data = res.data.open_invoices[invoice.day];
              invoice.amount = data?.amount;
              invoice.isBullet = true;
              invoice.count = data?.count ? data.count : 0;
              return invoice;
            })
            setOpenInvoices(tableData);

            setTopInvoices(Object.values(res.data?.top_invoices));
          }
        });
    },[currency])

    const translatedRevenue = revenue.map(item => ({
      ...item,
      month: t(`${item.month}`),
    }));
  const getAllData = useCallback(() => {
      const _formData = new FormData();
      _formData.append("isAuthUser", '');
      let encryptedToken = encryptForm(createObject(_formData));
      let encrData = new FormData();
      encrData.append("encrToken", encryptedToken);

      listNewDashboard(encrData)
        .then(res => {
          if (res.status) {
            let filteredRev = dataset;
            if(res.data?.revenue){
              filteredRev = dataset.map((data)=>{
                let month = data.month.toLowerCase();
                const monthMap = {
                  sept: 'sep',
                  july: 'jul',
                  june: 'jun',
                };
                month = monthMap[month] || month; 
                data.value = res.data.revenue[month];
                return data;
              })
            }
            let cardData = res.data?.cards;
            if(cardData && cardData?.openreminder){
              let fileterdList = currencyList.map((currency)=>({
                currency: currency,
                value: cardData?.openreminder[currency]
              })).filter(item => item.value !== undefined);
              cardData['openreminder'] = fileterdList;
            }
            setRevenue(filteredRev);
            setCard(cardData);
          }
        }).catch(error => {
          console.error('Error fetching dashboard data:', error);
        });
    },[])

  useEffect(()=>{
    getOpenInvoiceData();
  },[getOpenInvoiceData])

  useEffect(()=>{
    getAllData();
  },[getAllData])


  return (
    <>
      <Grid container spacing={2} sx={{paddingLeft:'25px',paddingRight:'25px'}}  mt={3}>
        <Grid item xs={6} sm={2.4} >
          <CustomCard 
            label='open_invoices' 
            currency='CHF' 
            amount={card.totalamountCHF && amountFormatter(card?.totalamountCHF)} 
            t={t} />
        </Grid>
        <Grid item xs={6} sm={2.4}>
          <CustomCard 
            label='open_invoices' 
            currency='EUR' 
            amount={card.totalamountEUR && amountFormatter(card?.totalamountEUR)} 
            t={t} />
        </Grid>
        <Grid item xs={6} sm={2.4}>
          <CustomCard 
            label='open_invoices' 
            currency='USD' 
            amount={card.totalamountUSD && amountFormatter(card?.totalamountUSD)} 
            t={t} />
        </Grid>
        <Grid item xs={6} sm={2.4}>
          <CustomCard 
            label='open_reminder_total' 
            currency='' 
            amount={card?.openreminder} t={t} 
            amountFormatter={amountFormatter}/>
        </Grid>
        <Grid item xs={6} sm={2.4}>
          <CustomCard 
            label='amount_open_invoices' 
            currency='' 
            amount={card?.amountOpenInvoices} 
            t={t} />
        </Grid>
      </Grid>
      <Grid item container xs={12} justifyContent='flex-end' alignContent='center' my={0} py={0}>
          <RadioGroup
              row
              aria-labelledby="tick-placement-radio-buttons-group-label"
              name="tick-placement"
              value={currency}
              onChange={(event) => setCurrency(event.target.value)}
            >
              {currencyList.length > 0 && currencyList.map((currency,index)=>
                <FormControlLabel 
                  key={index} 
                  value={currency} 
                  control={<CustomRadio />} 
                  label={currency} />
              )}
          </RadioGroup>
      </Grid>
      <Grid container spacing={4} px={3}>
        <Grid item xs={12} md={6}>
          <Grid container item xs={12} alignContent='center'>
              <h4 style={{marginTop:'0px'}}>{t("open_invoices")}</h4>
          </Grid>
          <Grid item xs={12}>
            <GeneralTable
              data={openInvoices}
              columns={invoiceColumns}
              t={t}
              amountFormatter={amountFormatter}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container item xs={12}>
              <h4 style={{marginTop:'0px'}}>{t("top_invoices")}</h4>
          </Grid>
          <Grid item xs={12}>
            <GeneralTable
              data={topInvoices}
              columns={supplierColumns}
              t={t}
              amountFormatter={amountFormatter}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item mt={4} xs={5}>
        <ResponsiveContainer width="80%" height={360}>
          <BarChart
            width={800}
            height={300}
            data={translatedRevenue}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis dataKey="month" stroke={theme.palette.text.secondary}/>
            <YAxis stroke={theme.palette.text.secondary} />
            {/* <Tooltip /> */}
            <Legend />
            <Bar
              dataKey="value"
              fill="#8B9635"
              minPointSize={0}
              name={t('revenue_per_month')}
            >
              <LabelList dataKey="value" content={renderCustomizedLabel} />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Grid>
    </>
  );
}

export default OverviewDashboard;
