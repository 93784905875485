import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "./shared/i18n/i18n"
import {store} from './redux/InvoiceStore';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from './themes/config';


ReactDOM.render(
  // <React.StrictMode>
    <Provider store={store}>
      <ConfigProvider>
        <BrowserRouter>
          <App />      
        </BrowserRouter>  
      </ConfigProvider>
    </Provider>,
  // </React.StrictMode>,
  document.getElementById('root')
);
console.log = () => {};
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
