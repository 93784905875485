import React, { useCallback, useEffect, useReducer, useState,useMemo,useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';
import "../create-invoice/create-invoice.scss";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import debounce from 'lodash.debounce'
import Tab from '@mui/material/Tab';
import { openAlertSnackBar,closeAlertSnackBar } from "../../shared/common/utilites/notification/snackbar";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import TodayRoundedIcon from '@mui/icons-material/TodayRounded';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ListIcon from '@mui/icons-material/List';
import TabPanel from '@mui/lab/TabPanel';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import EditIcon from '@mui/icons-material/Edit';
import dayjs from 'dayjs';
import CloseIcon from "@mui/icons-material/Close";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import SaveSharpIcon from "@mui/icons-material/SaveSharp";
import RemoveRedEyeSharpIcon from "@mui/icons-material/RemoveRedEyeSharp";
import IconButton from "@mui/material/IconButton";
import { Autocomplete, Dialog, DialogContent, DialogTitle, Modal, TextField } from "@mui/material";
import { useFormik  } from "formik";
import * as Yup from "yup";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGrid, GridFilterPanel, GridOverlay } from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";
import { Pagination } from "@mui/material";
import { copyInvoiceSheduler, createInvoiceSheduler, deleteInvoice, deleteInvoiceSheduler, generatePdf, getBank, getConditions, getCountry, getCurrency, getExpenseAccounts, getIncomeAccounts, getInvoiceAllItems, getInvoiceItems, getInvoiceType, getLanguages, getListdraftInvoices, getModulelists, getModules, getQrEsr, getTaxcode, getTemplates, listDraftByAddress, loadAddress, loadAllAddress, loadPdf, loadTemplates, previewInvoice, pushToQueue, saveInvoice, updateInvoice, updateInvoiceSheduler } from "../../services/Services";
import { createObject, encryptForm } from "../../services/commonService";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Loader from "../../shared/common/utilites/PageLoader/PageLoader";
import { CustomModal } from "../../shared/common/utilites/customModal";
import { getEncryptedItem } from "../../services/localStorageUtil";
import { DataGridActionTypes ,DataGridReducer} from "../../reducer/datagridReducer";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow ,{ tableRowClasses }from '@mui/material/TableRow';

const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  padding:'3px 4px 0px 0px',
  "&.Mui-checked":{
    color:"#8B9635"
  }
}));

const CutomTableRow = styled(TableRow)(({ theme }) => ({
  [`&.${tableRowClasses.root}`]: {
    height: "44px"
  },
}));

const CustomBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 750,
  borderRadius: "15px",
  bgcolor: "background.paper",
  textAlign: "center",
  backgroundColor: theme.palette.mode === "light" ? "white" : "black",
  border:
    theme.palette.mode === "light"
      ? "1px solid #ccc"
      : "1px solid rgba(255, 255, 255, 0.12)",
}));

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  button: {
    color: "#8B9635",
  },
}));

const CustomTabs = styled(TabList)(({ theme }) => ({
  "& .commoncreateinvoiceTab": {
    textTransform: "none",
    minHeight: '36px',
  },
  '&.MuiTabs-root': {
    minHeight: '0px',
  },
  '& .MuiButtonBase-root.Mui-selected': {
    color: theme.palette.mode === 'light' ? '#000' : '#ffff',
  },
}));

const useStyles = makeStyles({
  customNumberField: {
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type="number"]': {
      '-moz-appearance': 'textfield',
    },
  },
});

const CustomBoxLeft = styled(Box)(({ theme }) => ({
  minHeight:"897px",
  height: "auto",
  borderRadius:'4px',
  padding:"0px",
  margin:"10px 0px 0px 0px",
  border:
      theme.palette.mode === "light"
        ? "1px solid #ccc"
        : "1px solid rgba(255, 255, 255, 0.12)"
}));

const CustomDataGrid = styled(DataGrid)(({ theme }) => ({
  border:
    theme.palette.mode === "light"
      ? "1px solis #ccc"
      : "1px solid rgba(255, 255, 255, 0.12)",
  "& .css-1bf0mkr-MuiButtonBase-root-MuiCheckbox-root.Mui-checked": {
    color: "#8B9635",
  },
  "& .MuiDataGrid-cell:focus": {
    outline: "unset",
  },
  "& .MuiDataGrid-cell:focus-within": {
    outline: "unset",
  },
  "& .MuiDataGrid-row.Mui-selected": {
    backgroundColor: "#8B9635",
    color: theme.palette.mode === "light" ? "#fff" : "#000",
    "& .css-1bf0mkr-MuiButtonBase-root-MuiCheckbox-root.Mui-checked": {
      color: "#fff",
    },
    "&:hover": {
      backgroundColor: "#8B9635",
    },
  },
  "& .MuiDataGrid-footerContainer": {
    borderTop: "unset",
  },
}));

const CustomGridPanel = styled(GridFilterPanel)(({ theme }) => ({
  '& .MuiFormLabel-root.Mui-focused': {
    color: theme.palette.mode === 'light' ? '#000' : '#fff',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: theme.palette.mode === 'light' ? '#000' : '#fff',
  }
}));

const CustomBtn = styled(Button)(({ theme }) => ({
  textTransform: "none",
  borderRadius: "20px",
  background: "unset",
  border: "2px solid #8B9635",
  width:'100%',
  padding: "0px 16px 0px 16px",
  color: theme.palette.mode === "light" ? "black" : "#fff",
  "&:hover": {
    backgroundColor: "#8B9635",
    color: theme.palette.mode === "light" ? "#fff" : "#000",
  },
  "&.MuiLoadingButton-root.Mui-disabled": {
    background: theme.palette.mode === "light" ? "#fff" : "black",
    padding: "0px 0px",
    span: {
      color: "#8B9635",
    },
  },
}));

const defaultQueueParams = { 
  limit: 20, 
  page: 1, 
  column: '', 
  order: '' ,
  filter:'',
  search:'',
  shedulerDraft:''
};

const defaultListDraftByAddressParams = { 
  limit: 20, 
  page: 1, 
  column: '', 
  order: '' ,
  search:'',
  addressId:''
};

const defaultAddressParams = { 
  page: 1, 
};

const defaultItemParams = { 
  page: 1, 
};

const defaultModuleParams = { 
  page: 1, 
};
const defaultTemplateParams = { 
  page: 1, 
};

const defaultFooter = { 
  total_amountExclvat:'',
  totalVat:0.081,
  totalAmntInclVat:''
};

const CustomLoader = styled(Loader)(({ theme }) => ({
  loader: {
    width: "31px",
    height: "31px",
  },
}));

const schedulerInitialState ={
    data:[],
}

const marginTopValue = '104px';
const itemOrFreetextArray = [
  { id:0 ,value: 'item', label: 'item' },
  { id:1 ,value: 'freetext', label: 'freetext' },
]

function Createinvoice() {
  const { t ,i18n} = useTranslation(["language", "common"]);
  const [currency, setCurrency] = useState([]);
  const [tableLoader, setTableLoader] = useState(false);
  const [invoiceListByAddressTableLoader, setInvoiceListByAddressTableLoader] = useState(false);
  const [isItem, setIsItem] = useState(false);
  const [lang, setLanguage] = useState([]);
  const [invoiceType, setInvoiceType] = useState([]);
  const [schedulerCid, setSchedulerCid] = useState(null);
  const [address, setAllAddressData] = useState([]);
  const [pdfData, setPDFData] = useState(null);
  const [queueParams, setQueueParams] = useState(defaultQueueParams);
  const [listDraftByAddressParams, setListDraftByAddressParams] = useState(defaultListDraftByAddressParams);
  const [addressOptionParams, setAddressOptionParams] = useState(defaultAddressParams);
  const [addressByDraftOptionParams, setAddressByDraftOptionParams] = useState(defaultAddressParams);
  const [itemOptionParams, setItemOptionParams] = useState(defaultItemParams);
  const [moduleOptionParams, setModuleOptionParams] = useState(defaultModuleParams);
  const [templateOptionParams, setTemplateOptionParams] = useState(defaultTemplateParams);
  const [country, setCountry] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isMove,setIsMove] = useState(false);
  const [incomeAccount, setincomeAccount] = useState([]);
  const [expenseAccount, setexpenseAccount] = useState([]);
  const [items, setItems] = useState([]);
  const [schedulerStartDate, setSchedulerStartDate] = useState(null);
  const [invoiceListCount, setInvoiceListCount] = useState(0);
  const [invoiceListByAddressCount, setInvoiceListByAddressCount] = useState(0);
  const [invoiceData, setInvoiceData] = useState(([]));
  const [invoiceDataByAddress, setinvoiceDataByAddress] = useState([]);
  const [addressBydraftValue, setAddressBydraftValue] = useState('');
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [isSchedulerShow, setIsSchedulerShow] = useState(true);
  const [invoiceByAddressSelectedId, setInvoiceByAddressSelectedId] = useState([]);
  const [selectedRowIdScheduler, setSelectedRowIdScheduler] = useState([]);
  const [selectedRowIdItem, setSelectedRowIdItem] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [userDetails, setUserDetails] = useState(null);
  const [cid, setCid] = useState(null);
  const [addressData, setAddressData] = useState([]);
  const [addressDataByDraft, setAddressDataByDraft] = useState([]);
  const [itemData, setItemData] = useState([]);
  const [moduleData, setModuleData] = useState([]);
  const [templateData, setTemplateData] = useState([]);
  const [taxCode, settaxCode] = useState([]);
  const [isDelete, setIsDelete] = useState(false);
  const [allTaxCode, setAllTaxCode] = useState([]);
  const [storedInvoice,setStoredInvoice]=useState({});
  const selectedInvoiceId = useRef([]);
  const tempInvoiceId = useRef([]);
  const tempUpdateStatus = useRef('');
  const isCopy = useRef(false);
  const isScheduler = useRef(false);
  const isSearchAddress = useRef(true);
  const isSelectRowEditTemplate = useRef(false);
  const isSearchAddressBydraft = useRef(true);
  const isSearchItem = useRef(true);
  const isSearchModule = useRef(true);
  const isSearchTemplate = useRef(true);
  const [isLoaderOverlay, setIsLoaderOverlay] = useState(false);
  const [pdfModalOpen, setPDFModalOpen] = useState(false);
  const [taxValue, setTaxValue] = useState(0.081);
  const [isNew, setIsNew] = useState(true);
  const [value, setValue] = useState("1");
  const [invoiceTabValue, setInvoiceTabValue] = useState("draft");
  const [selectItem, setSelectItem] = useState(false);
  const [newScheduler, setNewScheduler] = useState(true);
  const [newItem, setNewItem] = useState(true);
  const [isMounted, setIsMounted] = useState(true);
  const [schedulerState, schedulerDispatch] = useReducer(DataGridReducer, schedulerInitialState);
  const [invoiceItems, itemDispatch] = useReducer(DataGridReducer, schedulerInitialState);
  const [addressOption, addressOptionDispatch] = useReducer(DataGridReducer, schedulerInitialState);
  const [addressByDraftOption, addressByDraftOptionDispatch] = useReducer(DataGridReducer, schedulerInitialState);
  const [itemOption, itemOptionDispatch] = useReducer(DataGridReducer, schedulerInitialState);
  const [moduleOption, moduleOptionDispatch] = useReducer(DataGridReducer, schedulerInitialState);
  const [templateOption, templateOptionDispatch] = useReducer(DataGridReducer, schedulerInitialState);
  const [searchColumnAddress, setSearchColumnAddress] = useState("");
  const [searchColumnAddressBydraft, setSearchColumnAddressBydraft] = useState("");
  const [searchColumnModule, setSearchColumnModule] = useState("");
  const [searchColumnTemplate, setSearchColumnTemplate] = useState("");
  const [qrEsrBank, setQrEsrBank] = useState([]);
  const [itemOrFreetext, setItemOrFreetext] = useState("item");
  const [isitemOrFreetext, setIsItemOrFreetext] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [searchColumnItem, setSearchColumnItem] = useState("");
  const [footer, setFooterValues] = useState(defaultFooter);
  const [bank, setBank] = useState([]);
  const [qrAccount, setQrAccount] = useState(null);
  const [qrEsrIban, setqrEsrIban] = useState([]);
  const [condition, setConditions] = useState([]);
  const [isDeleteItem, setIsDeleteItem] = useState(false);
  const [modules, setModules] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [templateDetails, setTemplateDetails] = useState([]);
  const [isItemColumnSelected, setIsItemColumnSelected] = useState(true);
  const [isChangeTemplate, setIsChangeTemplate] = useState(false);
  const classes = useStyles();
  const currentLanguage = i18n.language;

  const [openErrorModal,setOpenErrorModal] =useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [acceptNoReminder,setAcceptNoReminder]=useState(false);
  const [openTemplateConfirmModal, setOpenTemplateConfirmModal] = useState(false);
  const handleCloseTemplateConfirmModal = () => {
    setOpenTemplateConfirmModal(false);
  }

  const moveItems = (index,position)=>{
    setIsItemColumnSelected(false);
      itemDispatch
      ({
          type:DataGridActionTypes.REORDER_DATA,
          payload:{cid:index,move:position}
      })
      setIsMove(!isMove);
  }

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const updatehandleClose = () =>{
    formikScheduler.resetForm();
    setOpenErrorModal(false);
    setAcceptNoReminder(false);
  }
  const acceptReminder = () =>{
    formikScheduler.submitForm();
  }

  useEffect(()=>{
    const flattenedArray = [].concat(...addressOption.data);
    setAddressData(flattenedArray);
    // console.log("Address",flattenedArray);
  },[addressOption]);

  useEffect(()=>{
    const flattenedArray = [].concat(...addressByDraftOption.data);
    setAddressDataByDraft(flattenedArray);
  },[addressByDraftOption]);

  useEffect(()=>{
    const flattenedArray = [].concat(...itemOption.data);
    setItemData([flattenedArray]);
  },[itemOption]);
  
  useEffect(()=>{
    const flattenedArray = [].concat(...moduleOption.data);
    setModuleData([flattenedArray]);
  },[moduleOption]);

  useEffect(()=>{
    const flattenedArray = [].concat(...templateOption.data);
    setTemplateData([flattenedArray]);
  },[templateOption]);

  const resetItemForm=()=>{
    formikItem.resetForm();
    setSelectedRowIdItem([]);
    formikItem.setFieldValue('item_or_freetext','item');
    setItemOrFreetext('item');
    setIsItemOrFreetext(false);
    isSearchItem.current = true ;
    setSearchColumnItem("");
  }
  function CustomNoRowsOverlay() {
    const { t } = useTranslation(["language", "common"]);
    return (
      <GridOverlay>
        <Typography>{t("common_no_data_available")}</Typography>
      </GridOverlay>
    );
  }


  const loadAddressData = useCallback(() => {
    const controller4 = new AbortController();
    const signal4 = controller4.signal;
    const d = new FormData();
    d.append("limit", 100);
    d.append("page", isSearchAddress.current ? 1 : addressOptionParams.page);
    d.append("column", "companyname");
    d.append("order", "asc");
    d.append("iscompanyname", true);
    d.append("search", searchColumnAddress);
    //d.append("isAuthUser", '');
    let object = createObject(d);
    let encryptedToken = encryptForm(object);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
       loadAddress(encrData, signal4)
        .then((res) => {
            if (res.status) {
              if(res.data.length>0)
              {
                let datas = res.data.map((item)=>{item.label = item.companyname;return item});
                setAddressData([]);
                if(isSearchAddress.current)
                {
                  addressOptionDispatch({
                    type:DataGridActionTypes.ASSIGN_DATA,
                    payload:datas
                  })
                }else{
                  addressOptionDispatch({
                    type:DataGridActionTypes.APPEND_DATA,
                    payload:datas
                  })
                }
              }
            } else {
            }
        })
        .catch((err) => {
        })

    return () => {
      controller4.abort();
    };
  }, [searchColumnAddress,addressOptionParams]);

  const loadAddressBydraftData = useCallback(() => {
    const controller4 = new AbortController();
    const signal4 = controller4.signal;
    const d = new FormData();
    d.append("limit", 100);
    d.append("page", isSearchAddressBydraft.current ? 1 : addressByDraftOptionParams.page);
    d.append("column", "companyname");
    d.append("order", "asc");
    d.append("iscompanyname", true);
    d.append("search", searchColumnAddressBydraft);
    //d.append("isAuthUser", '');
    let object = createObject(d);
    let encryptedToken = encryptForm(object);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
       loadAddress(encrData, signal4)
        .then((res) => {
            if (res.status) {
              if(res.data.length>0)
              {
                let datas = res.data.map((item)=>{item.label = item.companyname;return item});
                setAddressDataByDraft([]);
                if(isSearchAddressBydraft.current)
                {
                  addressByDraftOptionDispatch({
                    type:DataGridActionTypes.ASSIGN_DATA,
                    payload:datas
                  })
                }else{
                  addressByDraftOptionDispatch({
                    type:DataGridActionTypes.APPEND_DATA,
                    payload:datas
                  })
                }
              }
            } else {
            }
        })
        .catch((err) => {
        })

    return () => {
      controller4.abort();
    };
  }, [searchColumnAddressBydraft,addressByDraftOptionParams]);

  const loadItemData = useCallback(() => {
    const controller5 = new AbortController();
    const signal5 = controller5.signal;
    const d = new FormData();
    d.append("limit", 100);
    d.append("page", isSearchItem.current ? 1 : itemOptionParams.page );
    d.append("column", "");
    d.append("order", "");
    d.append("search", searchColumnItem);
    //d.append("isAuthUser", '');
    let object = createObject(d);
    let encryptedToken = encryptForm(object);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
      getInvoiceItems(encrData, signal5)
        .then((res) => {
            if (res.status) {
              if(isSearchItem.current)
                {
                  itemOptionDispatch({
                    type:DataGridActionTypes.ASSIGN_DATA,
                    payload:res.data
                  })
                }else{
                  itemOptionDispatch({
                    type:DataGridActionTypes.APPEND_DATA,
                    payload:res.data
                  })
                }
            } else {
            }
        })
        .catch((err) => {
        })

    return () => {
      controller5.abort();
    };
  }, [searchColumnItem,itemOptionParams]);

  const loadModuleData = useCallback(() => {
    const controllerModule = new AbortController();
    const signalModule = controllerModule.signal;
    const d = new FormData();
    d.append("limit", 100);
    d.append("page", isSearchModule.current ? 1 : moduleOptionParams.page );
    d.append("column", "");
    d.append("order", "");
    d.append("search", searchColumnModule);
  //d.append("isAuthUser", '');
    let object = createObject(d);
    let encryptedToken = encryptForm(object);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
      getModulelists(encrData, signalModule)
        .then((res) => {
            if (res.status) {
              if(isSearchModule.current)
                {
                  moduleOptionDispatch({
                    type:DataGridActionTypes.ASSIGN_DATA,
                    payload:res.data
                  })
                }else{
                  moduleOptionDispatch({
                    type:DataGridActionTypes.APPEND_DATA,
                    payload:res.data
                  })
                }
            } else {
            }
        })
        .catch((err) => {
        })

    return () => {
      controllerModule.abort();
    };
  }, [searchColumnModule,moduleOptionParams]);

  const loadInvoiceDraftByAddressData = useCallback(() => {
    const controller = new AbortController();
    const signal =controller.signal;
    const params = new FormData();
    params.append("limit",listDraftByAddressParams.limit);
    params.append("page",listDraftByAddressParams.page);
    params.append("column",listDraftByAddressParams.column);
    params.append("order",listDraftByAddressParams.order);
    params.append("search",listDraftByAddressParams.search);
    params.append("addressId",listDraftByAddressParams.addressId);
    setinvoiceDataByAddress([]);
    setInvoiceListByAddressTableLoader(true);
    let object = createObject(params);
    let encryptedToken = encryptForm(object);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
    listDraftByAddress(encrData,signal)
      .then((res) => {
          if (res.status) {
            setInvoiceListByAddressTableLoader(false);
            setinvoiceDataByAddress(res.data);
            setInvoiceListByAddressCount(Number(res.filtered));
          } else {
          }
      }).catch((err) => {

      })

  },[listDraftByAddressParams])

  const loadTemplateData = useCallback(() => {
    const controllerTemplate = new AbortController();
    const signalTemplate = controllerTemplate.signal;
    const d = new FormData();
    d.append("limit", 100);
    d.append("page", isSearchTemplate.current ? 1 : templateOptionParams.page );
    d.append("column", "");
    d.append("order", "");
    d.append("search", searchColumnTemplate);
    d.append("active", true);
  //  d.append("isAuthUser", '');
    let object = createObject(d);
    let encryptedToken = encryptForm(object);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
      loadTemplates(encrData, signalTemplate)
        .then((res) => {
            if (res.status) {
              if(isSearchTemplate.current)
                {
                  templateOptionDispatch({
                    type:DataGridActionTypes.ASSIGN_DATA,
                    payload:res.data
                  })
                }else{
                  templateOptionDispatch({
                    type:DataGridActionTypes.APPEND_DATA,
                    payload:res.data
                  })
                }
            } else {
            }
        })
        .catch((err) => {
        })

    return () => {
      controllerTemplate.abort();
    };
  }, [searchColumnTemplate,templateOptionParams]);



  useEffect(() => {
    loadModuleData();
    return()=>{
      setIsMounted(false);
      closeAlertSnackBar();
    }
  }, [loadModuleData]);

  useEffect(() => {
    loadTemplateData();
    return()=>{
      setIsMounted(false);
    }
  }, [loadTemplateData]);

  useEffect(() => {
    if (isItemColumnSelected) {
     loadItemData();
    }
    return()=>{
      setIsMounted(false);
    }
  }, [loadItemData]);

  useEffect(() => {
    loadAddressData();
    return()=>{
      setIsMounted(false);
    }
  }, [loadAddressData]);

  useEffect(() => {
    loadAddressBydraftData();
    return()=>{
      setIsMounted(false);
    }
  }, [loadAddressBydraftData]);

  useEffect(() => {
    loadInvoiceDraftByAddressData();
    return()=>{
      setIsMounted(false);
    }
  }, [loadInvoiceDraftByAddressData]);

  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }

  const handleModuleFieldChange = (event) => {
    if(!event){
      isSearchModule.current = true;
      setSearchColumnModule("");
      formik.setFieldValue("module", '');
      formik.setFieldValue("moduleCode", '');
      return;
    } 
    const fieldValue = event.id;
    const moduleItem =modules.filter(item=>item.id ===fieldValue)
    if(moduleItem.length > 0){
      isSearchModule.current = true;
      setSearchColumnModule(moduleItem[0].code);
      formik.setFieldValue("module", moduleItem[0]);
      formik.setFieldValue("moduleCode", moduleItem[0].code);
      if(!(formik.values.address?.template_json)){return}
      const filteredAddress = formik.values.address?.template_json.filter((item)=>(item.module === moduleItem[0].code) );
      if(filteredAddress.length=== 0){return}
      const filteredTemplate =  templates.filter((item)=>item.cid === filteredAddress[0]?.template);
      if(filteredTemplate.length===0){return}
      formik.setFieldValue("template",filteredTemplate[0]);
      formik.setFieldValue("templateid",filteredTemplate[0].cid);
      setSearchColumnTemplate(filteredTemplate[0].name)
    }
    
  }

  const handleTemplateFieldChangeTax = (event,value) => {
    let taxvalue = allTaxCode.filter((item)=>item.code === event.target.value)
    formik.setFieldValue("tax", event.target.value);
    setTaxValue(Number(taxvalue[0].value));
  }

  const handleTemplateFieldChange = (event) => {
    if(!event){
      formik.setFieldValue("template", '');
      formik.setFieldValue("templateid", '');
      isSearchTemplate.current = true;
      setSearchColumnTemplate("");
      // if(selectedRowIds.length !==0 )return
      // formik.setFieldValue("free_text_header", '');
      // formik.setFieldValue("free_text_footer", '');
      // formik.setFieldValue("currency", '');
      // formik.setFieldValue("module", '');
      // formik.setFieldValue("moduleCode", '');
      // formik.setFieldValue("incomeaccount", '');
      // formik.setFieldValue("qr_esr_iban", '');
      // formik.setFieldValue("debitaccount", '');
      // formik.setFieldValue("our_reference", '');
      // formik.setFieldValue("language", '');
      // formik.setFieldValue("condition", '');
      // formik.setFieldValue("tax", 'SSVR81');
      // formik.setFieldValue("bank", '');
      // setFooterValues((footer)=>({
      //   ...footer,
      //   totalVat:0.081
      // }))
      // isSearchModule.current = true;
      // setSearchColumnModule('');
      return;
    }
    const fieldValue = event.cid;
    const templateArray =templates.filter(item=>item.cid ===fieldValue);
    if(templateArray.length===0){return}
    isSelectRowEditTemplate.current = true;
    const templateObj = templateArray[0];
   
    formik.setFieldValue("template", templateObj);
    formik.setFieldValue("templateid", templateObj?.cid);
    isSearchTemplate.current = true;
    setSearchColumnTemplate(templateObj?.name);
    setIsChangeTemplate(false);
  }

  const handleAcceptChangeTemplate =()=>{
    setIsChangeTemplate(true);
    handleCloseTemplateConfirmModal();
  }

  const handleRejectChangeTemplate =()=>{
    setIsChangeTemplate(false);
    handleCloseTemplateConfirmModal();
    formik.setFieldValue("template", '');
    formik.setFieldValue("templateid", '');
    isSearchTemplate.current = true;
    setSearchColumnTemplate("");
  }

  const handleAddressFieldChange = (event) => {
    if(!event){
      // formik.setFieldValue("language", '');
      // formik.setFieldValue("country", '')
      formik.setFieldValue("address", '');
      setCompanyName("");
      formik.setFieldValue("addressid", '');
      // formik.setFieldValue("invoicename", '');
      isSearchAddress.current = true ;
      setSearchColumnAddress("");
      return;
    }
    const fieldValue = event.cid;
    const addressItem =address.filter(item=>item.cid ===fieldValue)
    if(addressItem.length>0){   
      isSearchAddress.current = true ;
      setSearchColumnAddress(addressItem[0].companyname);
      const filteredcountryObject = country.filter(
        (obj) => obj.id === addressItem[0].countryid
      );
      
      const filteredlangObject = lang.filter((obj) => obj.id === addressItem[0].languageid);
      const filteredTaxid = taxCode.filter((obj) => (obj.id === addressItem[0].taxid))[0]

      if(filteredTaxid){
        formik.setFieldValue("tax", filteredTaxid.code);
        setTaxValue(Number(filteredTaxid.value));
      }
      formik.setFieldValue("language", filteredlangObject.length>0 ?filteredlangObject[0]?.code:'');
      formik.setFieldValue("country", filteredcountryObject.length>0 ?filteredcountryObject[0]?.code:'')
      formik.setFieldValue("address", addressItem[0]);
      formik.setFieldValue("invoicename", addressItem[0].companyname);
      formik.setFieldValue("addressid", addressItem[0].addressid);
    }
    
    setCompanyName(event);
    isSearchAddress.current = true ;

    if(!addressItem[0]?.template_json){return}
    if((formik?.values.moduleCode))
    {
      const filteredAddress = addressItem[0]?.template_json.filter((item)=>(item.module === formik?.values.moduleCode) );
      if(filteredAddress.length===0){return}
      const filteredTemplate =  templates.filter((item)=>item.cid === filteredAddress[0]?.template);
      if(filteredTemplate.length===0){return}
      formik.setFieldValue("template",filteredTemplate[0]);
      formik.setFieldValue("templateid",filteredTemplate[0].cid);
      setSearchColumnTemplate(filteredTemplate[0].name)
    }
    
  }; 

  const handleAddressByDraftFieldChange = (event) => {
    if(!event){
      // formik.setFieldValue("address", '');
      setAddressBydraftValue('');
      setCompanyName("");
      formik.setFieldValue("addressid", '');
      isSearchAddressBydraft.current = true ;
      setSearchColumnAddressBydraft("");
      setListDraftByAddressParams((previousParams)=>({
        ...previousParams,
        search:'',
        page:1
      }))
      return;
    }
    const fieldValue = event.cid;
    const addressItem =address.filter(item=>item.cid ===fieldValue)
    if(addressItem.length>0){   
      isSearchAddressBydraft.current = true ;
      setSearchColumnAddressBydraft(addressItem[0].companyname);
      // formik.setFieldValue("address", addressItem[0]);
      setAddressBydraftValue(addressItem[0]);
      // formik.setFieldValue("addressid", addressItem[0].addressid);
    }
    setListDraftByAddressParams((previousParams)=>({
      ...previousParams,
      search:addressItem[0].companyname,
      page:1
    }))
    setCompanyName(event);
    isSearchAddressBydraft.current = true ;
  }; 

  const intervalTypes=[
    {
      id:1,
      label:'Day/s',
      code:'d'
    },
    {
      id:2,
      label:'Week/s',
      code:'w'
    },
    {
      id:3,
      label:'Month/s',
      code:'m'
    },
    {
      id:4,
      label:'Year/s',
      code:'y'
    }
  ];
  
  const columns = [
    { field: "invoiceid", headerName: t("invoiceid"), flex : 1 },
    { field: "customer", headerName: t("common_company_name"),flex:1.8 ,sortable: false},
    { field: "invoicename", headerName: t("invoice_name"),flex:1.8,sortable: false },
    { field: "createddate", headerName: t("template_created_date"), flex:1 ,valueFormatter:(params)=>formatDate(params.value)},
    { field: "currencycode", headerName: t("common_currency"), flex:0.6,sortable: false},
    { field: "status", headerName: t("invoice_queue_status"), flex:1.6,sortable: false ,
      renderCell: (params) => {
        const {value}=params;
        let lowerValue = value.toLowerCase();
        return (
          (value!== undefined)?(lowerValue === "scheduler draft")?(<div style={{color:'#FF0000',fontWeight:'bold'}}>{t("scheduler_draft")}</div>) :(<p>{value}</p>):''
        );
      },
  },
  ];

  const columnsDataByAddress = [
    { field: "invoiceid", headerName: t("invoice_number"), flex : 1 },
    { field: "customer", headerName: t("customer"),flex:1.8 ,sortable: false},
    { field: "invoice_date", headerName: t("invoice_date"), flex:1 ,valueFormatter:(params)=>formatDate(params.value)},
    { field: 'total_price', headerName: t('total_price'),minWidth:80, flex: 0.8, sortable: false,valueFormatter:(params) =>params.value ? formattedAmount(parseFloat(params.value)) : params.value},
    { field: "currencycode", headerName: t("common_currency"), flex:0.6,sortable: false},
    { field: "status", headerName: t("invoice_queue_status"), flex:1.6,sortable: false ,
      renderCell: (params) => {
        const {value}=params;
        let lowerValue = value.toLowerCase();
        return (
          (value!== undefined)?(lowerValue === "scheduler draft")?(<div style={{color:'#FF0000',fontWeight:'bold'}}>{t("scheduler_draft")}</div>) :(<p>{value}</p>):''
        );
      },
  },
  ];

  // useEffect(()=>{
  //   if(invoiceItems.data.length===0){
  //     return setIsItem(false);
  //    }
  // },[invoiceItems]);

  const handleClickDeleteItem = (params) => {
    setIsItemColumnSelected(false);
    itemDispatch
    ({
        type:DataGridActionTypes.DELETE,
        payload:{cid:params.id}
    })
    setIsDeleteItem(true);
    setSelectedRowIdItem([params.id])
    formikItem.resetForm();
    
    setIsMove(!isMove);
  };

  const formattedAmount = (amount)=>{
    return amount.toLocaleString('de-CH', { 
    useGrouping: true,
    minimumFractionDigits: 2,
    maximumFractionDigits: 3 
  });
 }

  const handleOnCellClick = (params) => {
    if((params.row.free_text_item) || (params.field === 'move')){
      setIsItemColumnSelected(false);
    }else{
      setIsItemColumnSelected(true);
    }
  };

  const columnsItems = [
    {  field: "move",
      headerName: "",width: 0.02,sortable: false,
      renderCell: (params) => (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <IconButton style={{padding: '0px',margin:'0px'}} onClick={()=>moveItems(params.id, 'up')}>
              <ArrowDropUpIcon style={{cursor: 'pointer',marginTop:'6px',color:'green'}} />
            </IconButton>
            <IconButton style={{padding: '0px',margin:'0px'}} onClick={()=>moveItems(params.id, 'down')}>
              <ArrowDropDownIcon style={{cursor: 'pointer',marginBottom:'3px',color:'red'}}  />
            </IconButton>
          </div>
      </div>
      )
    },
    { field: "description", headerName: t("item_name"), flex: 2.5},
    { field: "amount", headerName: t("common_amount"), flex: 1,
      valueFormatter:(params) =>params.value ? formattedAmount(parseFloat(params.value)):null},
    { field: "price", headerName: t("common_price"), flex: 1,valueFormatter:(params) =>params.value ?formattedAmount(parseFloat(params.value)) :null},
    { field: "total", headerName: t("common_total"), flex: 1.3,valueFormatter:(params) =>params.value ?formattedAmount(parseFloat(params.value)):null},
    { field: "income_account_name", headerName: t("ertragskonto"),flex: 1.3},
    {
      field: "delete",
      headerName: "",
      sortable: false,
      flex: 0.5,
      renderCell: (params) => {
        return (
          <IconButton onClick={()=>handleClickDeleteItem(params)}>
            <CloseSharpIcon sx={{ fontSize: 30, color: "#ff0000" }} />
          </IconButton>
        );
      }
    }
  ];

  const handleCheckBoxChange = (event)=>{
    if(event.target.checked)
    {
      setQueueParams((previousParams)=>({
        ...previousParams,
        shedulerDraft:true,
        page:1
      }))
    }else{
      setQueueParams((previousParams)=>({
        ...previousParams,
        shedulerDraft:"",
        page:1
      }))
    }
  }

  const deleteSheduler = (cid) => {
    try {
      const d = new FormData();
      d.append("cid", cid);
      d.append("userName",userDetails.username?userDetails.username:'');
      //d.append("isAuthUser", "");
      let object = createObject(d);
      let encryptedToken = encryptForm(object);
      let encrData = new FormData();
      encrData.append("encrToken", encryptedToken);
      deleteInvoiceSheduler(encrData)
       .then((res) => {
          if (res.status) {
            loadInvoiceList(true);
            openAlertSnackBar('success',res.message,marginTopValue);
          }
          else {
            openAlertSnackBar('error',res.message,marginTopValue);
          }
          })
             .catch((err) => {
               setTableLoader(false);
               openAlertSnackBar('error','common_alert',marginTopValue);
             });
    } catch (error) {
      openAlertSnackBar('error','common_alert',marginTopValue);
    }
  };

  const handleClickDeleteScheduler =(params)=>{
    setSelectedRowIdScheduler([]);
    if(isNew)
    {
      schedulerDispatch
      ({
        type:DataGridActionTypes.DELETE,
        payload:{cid:params.id}
      })
    }
    else{
      selectedInvoiceId.current = selectedRowIds;
      deleteSheduler(params.row.cid);
    }
  }
 
  const formatDate =useCallback((date)=>{
    if(!date){ return date }
    if(currentLanguage === "en"){
      return new Date(date).toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
    }else {
      return new Date(date).toLocaleDateString("de-DE", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
    }
  },[currentLanguage])

  const columnsScheduler = [
    { field: "name", headerName: t("common_name"), flex: 1.3 },
    { field: "startdate", headerName: t("common_duedate"),flex: 1.5 ,valueFormatter:(params)=>formatDate(params.value)},
    { field: "enddate", headerName: t("end_date"), flex: 1.5 ,valueFormatter: (params)=>formatDate(params.value)},
    { field: "interval", headerName: t("common_interval"), flex: 1 },
    { field: "type", headerName: t("type"), flex: 1.3 ,
      renderCell: (params) => {
        const filteredValue = intervalTypes.filter((value)=>value.code === params.value);
        let returnValue = params.value;
        if(filteredValue.length>0){
          returnValue = filteredValue[0].label
        }
        return (returnValue);
      }},
    {
      field: "delete",
      headerName: "",
      sortable: false,
      flex: 0.5,
      renderCell: (params) => {
        return (
          <IconButton onClick={()=>handleClickDeleteScheduler(params)}>
            <CloseSharpIcon sx={{ fontSize: 30, color: "#ff0000" }} />
          </IconButton>
        );
      }
    }
  ];

  const debouncedFilterScroll = debounce((query) =>
    setAddressOptionParams({page:query})
  , 300)

  const debouncedFilterScrollAddressByDraft = debounce((query) =>
    setAddressByDraftOptionParams({page:query})
  , 300)

  const debouncedFilterScrollItem = debounce((query) =>
    setItemOptionParams({page:query})
  , 300)

  const debouncedFilterModule = debounce(query =>
    setSearchColumnModule(query)
  , 1000)

  const debouncedFilterTemplate = debounce(query =>
    setSearchColumnTemplate(query)
  , 1000)

  const handleKeyDownModule = (event) => {
    event.preventDefault();
    if (event.target.value === '') {
      formik.setFieldValue("module", "");
    }
    isSearchModule.current = true;
    debouncedFilterModule(event.target.value);
  };

  const handleKeyDownTemplate = (event) => {
    event.preventDefault();
    if (event.target.value === '') {
      formik.setFieldValue("template", "");
    }
    isSearchTemplate.current = true;
    debouncedFilterTemplate(event.target.value);
  };

  const handleClearFieldModule = (e,value) => {
    if (formik.values.module === "") {
      formik.setFieldValue("module", "");
      formik.setFieldValue("moduleCode", "");
      isSearchModule.current = true;
      setSearchColumnModule("");
    }
  };
  const handleClearFieldTemplate = (e,value) => {
    if (formik.values.template === "") {
      formik.setFieldValue("template", "");
      isSearchTemplate.current = true;
      setSearchColumnTemplate("");
    }
  };

  const handleKeyDownItem = (event) => {
    event.preventDefault();
    if (event.code === 8) { formikItem.setFieldValue("cid", "")};
    isSearchItem.current = true ;
    setSearchColumnItem(event.target.value);
  };

  const handleClearFieldItem = () => {
    if (formikItem.values.address === "") {
      setCompanyName("")
      isSearchItem.current = true ;
      setSearchColumnItem("");
      formikItem.setFieldValue("cid", "");
    }
  };

  const DeleteModalContent=()=>{
    return(
      <>
        <p id="child-modal-description">{t("confirm_delete")}</p>
        <Box style={{ display: "flex", justifyContent: "space-between" }}>
          <CustomBtn onClick={handleCloseDeleteModal} sx={{ mt: 2 }}>
            {t("common_cancel")}
          </CustomBtn>
          <CustomBtn onClick={handleDeleteInvoice} sx={{ mt: 2 }}>
            {t("common_delete")}
          </CustomBtn>
        </Box>
      </>)
  }
 

  const ErrorModalContent = () => {
    return (
      <>
      <div style={{marginTop:"2%",marginBottom:"2%"}}>{errorMessage}</div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          
          <CustomBtn onClick={updatehandleClose} sx={{ mt: 2 }}>
             {t("common_cancel")}
          </CustomBtn>
          <CustomBtn onClick={acceptReminder} sx={{ mt: 2 }}>
          {t("common_submit")}
          </CustomBtn>
      
        </div>
      </>
    );
  };


  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeInvoicesTabs = (event, newValue) => {
    handleClear();
    setSelectedRowIds([]);
    setInvoiceByAddressSelectedId([]);
    setInvoiceTabValue(newValue);
    if(newValue === 'draft'){
      setQueueParams((previousParams)=>({
        ...previousParams,
        search:'',
        page:1
      }))
    }else{
      setListDraftByAddressParams((previousParams)=>({
        ...previousParams,
        search:'',
        page:1
      }))
    }
  };

  const handlePageChange = (event, newPage) => {
    setQueueParams((previousParams)=>({
      ...previousParams,
      page:event + 1
    }))
  };

  const handlePageChangeInvoiceByAddress = (event, newPage) => {
    setListDraftByAddressParams((previousParams)=>({
      ...previousParams,
      page:event + 1
    }))
  };

  const totalPrice=(amount,price)=>{
    if(((amount==="")&&(price===""))||(price==="-")){
      formikItem.setFieldValue("total","");
      return;
    }
    if((amount==="")){amount=1};
    if(price===""){price=1};
    const totalPrice =  Math.round((parseFloat(price) * parseFloat(amount) + Number.EPSILON) * 20) / 20;
    formikItem.setFieldValue("total",totalPrice);
  }



  const handleAmountChange = (event) => {
    let value = event.target.value;
    if (/^(?!0\d)\d*\.?\d*$/.test(value)) {
      const amount = event.target.value.trim();
      const price = formikItem.values.price.trim();
      formikItem.setFieldValue('amount', amount);
      totalPrice(amount,price);
    }
  };

  const handlePriceChange = (event) => {
    let value = event.target.value;
    if (/^-?(?!0\d)\d*\.?\d*$/.test(value)) {
      const price = event.target.value.trim();
      const amount = formikItem.values.amount.trim();
      formikItem.setFieldValue('price', price);
      totalPrice(amount,price);
    }
  }

  const handleFreetext = (event) => {
    formikItem.setFieldValue('free_text_item', event.target.value);
  }

  const handleSearchKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if(invoiceTabValue === 'draft'){
        setQueueParams((previousParams)=>({
          ...previousParams,
          search:event.target.value,
          page:1
        }))
      }else{
        setListDraftByAddressParams((previousParams)=>({
          ...previousParams,
          search:event.target.value,
          page:1
        }))
      }
      
    }
  }

  useEffect(()=>{
    tempInvoiceId.current=[]
  },[queueParams]);
  
  const loadInvoiceList = useCallback(
    (isMounted) => {
      const d = new FormData();
      d.append("limit", queueParams.limit);
      d.append("page", queueParams.page);
      d.append("column", queueParams.column);
      d.append("order", queueParams.order);
      d.append("scheduler", queueParams.shedulerDraft);
      d.append("search", queueParams.search);
      //d.append("isAuthUser", '');

      let object = createObject(d);
      let encryptedToken = encryptForm(object);
      setTableLoader(true);
      if((tempInvoiceId.current.length===0)&&(!isCopy.current))
      {
        setInvoiceData([]);
      }
      itemDispatch({
        type:DataGridActionTypes.ASSIGN_DATA,
        payload:[]
      })
      setSelectedRowIds([]);
      setIsItem(false);
      let encrData = new FormData();
      encrData.append("encrToken", encryptedToken);
      getListdraftInvoices(encrData)
        .then((res) => {
          setTableLoader(false);
          if (isMounted) {
            if (res.status) {
              if (res.data.length > 0) {
                setInvoiceData(res.data);
                if(isCopy.current)
                {
                  selectedInvoiceId.current = [res.data[0].cid];
                }
                setInvoiceListCount(Number(res.filtered));
                if(tempInvoiceId.current.length>0)
                {
                  selectedInvoiceId.current = tempInvoiceId.current;
                }
                if(selectedInvoiceId.current.length>0)
                {
                  setSelectedRowIds(selectedInvoiceId.current);
                }
                tempInvoiceId.current =[];
              }
            }
            else {
              openAlertSnackBar('error',res.message,marginTopValue);
            }
          }
        })
        .catch((err) => {
          setTableLoader(false);
          openAlertSnackBar('error','common_alert',marginTopValue);
        });

    },[queueParams]);

  useEffect(()=>{
    if(isMove){
      if(selectedRowIdItem.length>0)
      {
        handleSelectionChangeItem([]);
      }else{
        handleSelectionChangeItem([]);
      }
    }
    else{
      handleSelectionChangeItem(selectedRowIdItem);
    }
    setItemOrFreetext('item');
    setIsItemOrFreetext(false);
  },[isMove])

  const handleSelectionChangeItem = (newSelection) => {
    setNewItem(true);
    if((newSelection.length===0)||(newSelection[0]===selectedRowIdItem[0])){
      setIsItemOrFreetext(false);
      setItemOrFreetext('item');
      return (formikItem.resetForm(),setSelectedRowIdItem([]),isSearchItem.current = true ,setSearchColumnItem(""))
    }
    setSelectedRowIdItem(newSelection);
    const filteredObject2 = invoiceItems.data.filter(
      (obj,index) => index === newSelection[0]
    );
    if(filteredObject2.length===0){return}
    const filteredObject = filteredObject2.map((item,index)=>{item.id = index;return item});
    setNewItem(false);
    const filteredItemObject = items.filter(
      (obj) => obj.cid === filteredObject[0].cid
    );
    // setItemData(items);
    itemOptionDispatch({
      type:DataGridActionTypes.ASSIGN_DATA,
      payload:items
    })
    isSearchItem.current = true ;
    setSearchColumnItem(filteredItemObject[0]?.title);

    let itemObj =initialValuesItem;
    itemObj={
      id:filteredItemObject[0]?.id?filteredItemObject[0]?.id:'',
      cid: filteredItemObject[0]?.cid?filteredItemObject[0]?.cid:'',
      itemid:filteredItemObject[0]?.itemid?filteredItemObject[0]?.itemid:'',
      item:filteredItemObject[0]?.itemid ? filteredItemObject[0]:'',
      description: filteredItemObject[0]?.title?filteredItemObject[0]?.title:'',
      amount: filteredObject[0]?.amount?filteredObject[0]?.amount:'',
      price:filteredObject[0]?.price?filteredObject[0]?.price:'',
      total: filteredObject[0]?.total,
      income_account: filteredObject[0]?.incomeaccountid?filteredObject[0]?.incomeaccountid:'',
      free_text_item: filteredObject[0]?.free_text_item?filteredObject[0]?.free_text_item:'',
      income_account_name: filteredObject[0]?.income_account_name?filteredObject[0]?.income_account_name:'',
      item_description: filteredObject[0]?.item_description?filteredObject[0]?.item_description:'',
      debit_account: filteredObject[0]?.debit_account?filteredObject[0]?.debit_account:'',
      booking_text: filteredObject[0]?.booking_text?filteredObject[0]?.booking_text:'',
      exchange_rate: filteredObject[0].exchange_rate?filteredObject[0].exchange_rate:'',
      item_or_freetext: filteredObject[0].item_or_freetext?filteredObject[0].item_or_freetext:'',
    }
    setIsItemOrFreetext(true);
    if(filteredObject[0].item_or_freetext)
    {
      setItemOrFreetext(filteredObject[0].item_or_freetext);
    }
    formikItem.setValues(itemObj);
  }

  const handleSelectionChangeScheduler = (newSelection) => {
    if((newSelection.length===0)||(newSelection[0]===selectedRowIdScheduler[0])){ 
      return (setSelectedRowIdScheduler([]),
              formikScheduler.resetForm(),
              setSelectItem(false),
              setSchedulerStartDate(null),
              setNewScheduler(true))
    };
    setSelectedRowIdScheduler(newSelection);
    const filteredObject = schedulerState.data.filter(
      (obj,index) => index === newSelection[newSelection.length - 1]
    );

    if(filteredObject.length===0){return}
    setNewScheduler(false);
    setSchedulerStartDate(dayjs(filteredObject[0]?.startdate))
    let schedulerObj =initialValuesSchedulerModal;
    schedulerObj = {
      name:filteredObject[0]?.name,
      startdate:dayjs(filteredObject[0]?.startdate),
      enddate:filteredObject[0]?.enddate ?dayjs(filteredObject[0]?.enddate):null,
      interval:filteredObject[0]?.interval,
      type:filteredObject[0]?.type,
    };
    setSchedulerCid(filteredObject[0]?.cid);
    formikScheduler.setValues(schedulerObj);
  }
  
  const resetCreateInvoiceForm =()=>{
    formik.resetForm();
  }

  useEffect(()=>{
    if(invoiceByAddressSelectedId.length>0){
      isSelectRowEditTemplate.current = false;
    }
  },[invoiceByAddressSelectedId])
  const handleSelectionChangeListInvoiceByAddress = async(newSelection) => {
    
    if(((newSelection.length===0)||(newSelection[0]===invoiceByAddressSelectedId[0]))){
      setInvoiceByAddressSelectedId([]);
      setIsItem(false);
      // handleClear();
      setValue("1");
      setFooterValues(defaultFooter);
      formik.resetForm();
      schedulerDispatch({
        type:DataGridActionTypes.ASSIGN_DATA,
        payload:[]
      });
      setItemList([]);
      setStoredInvoice({});
      return;
    }
    
    setInvoiceByAddressSelectedId(newSelection);
    let selectedInvoice = invoiceDataByAddress.filter((invoice)=>invoice.cid===newSelection[0]);
    if(selectedInvoice.length===0){
      return;
    };
  
      const selectedAddress = address.filter(
        (obj) => obj.addressid === selectedInvoice[0].addressid
      );
      // isSearchAddressBydraft.current = true ;
      // setSearchColumnAddressBydraft(selectedAddress[0].companyname);
      setCompanyName(selectedAddress[0].companyname);
  
      const selectedModuleObject = modules.filter(
        (obj) => obj.code === selectedInvoice[0].module
      );
      isSearchModule.current = true;
      if(selectedModuleObject.length>0){
        setSearchColumnModule(selectedModuleObject[0]?.code);
      }else{
        setSearchColumnModule("");
      }
  
      const filteredTaxidObject = allTaxCode.filter(
        (obj) => obj.code === selectedInvoice[0]?.taxcode
      );
      let tax= '';
      if(filteredTaxidObject.length>0)
      {
        setTaxValue(filteredTaxidObject[0].value);
        tax =filteredTaxidObject[0].code
      }
  
      const filteredcountryObject = country.filter(
        (obj) => obj.id === selectedInvoice[0]?.countryid
      );
  
      const filteredlangObject = lang.filter(
        (obj) => obj.code === selectedInvoice[0]?.languageid
      );
  
      const filteredCurrencyObject = currency.filter(
        (obj) => obj.code === selectedInvoice[0].currencycode
      );
  
      const filteredTemplateObject = templates.filter(
        (obj) => obj.cid === selectedInvoice[0].templateid
      );
      isSearchTemplate.current = true ;
      if(filteredTemplateObject.length>0){
        setSearchColumnTemplate(filteredTemplateObject[0]?.name);
      }else{
        setSearchColumnTemplate("");
      }
  
      const selectedBank = bank.filter(
        (obj) => obj.code === selectedInvoice[0]?.bank
      );
  
      const selectedQrEsrIban = qrEsrIban.filter(
        (obj) => obj.code === selectedInvoice[0]?.qr_esr_iban
      );
  
      const selectedCondition = condition.filter(
        (obj) => obj.code === selectedInvoice[0]?.condition
      );
  
      const filteredincomeaccountObject = incomeAccount.filter(
        (obj) => obj.code === selectedInvoice[0]?.incomeaccount
      );
      const filteredexpenseaccountObject = expenseAccount.filter(
        (obj) => obj.code === selectedInvoice[0]?.expenseaccount
      );

      let invoiceObj = initialValuesCreateInvoice ;
      invoiceObj= {
          address: selectedAddress.length> 0 ? selectedAddress[0]:'',
          addressid: selectedAddress.length> 0 ? selectedAddress[0]?.addressid:'',
          booking_text: '',
          customer_information: selectedInvoice[0]?.customerinformation?selectedInvoice[0]?.customerinformation:'',
          accounting_no_or_project_no: selectedInvoice[0]?.accountingnoorprojectno?selectedInvoice[0]?.accountingnoorprojectno:'',
          free_text_header:selectedInvoice[0]?.free_text_header?selectedInvoice[0]?.free_text_header:'',
          free_text_footer:selectedInvoice[0]?.freetextfooter?selectedInvoice[0]?.freetextfooter:'',
          our_reference:selectedInvoice[0]?.our_sign?selectedInvoice[0]?.our_sign:'',
          cust_reference:selectedInvoice[0]?.cust_sign?selectedInvoice[0]?.cust_sign:'',
          currency: filteredCurrencyObject.length>0 ? filteredCurrencyObject[0]?.code:'',
          template:filteredTemplateObject.length>0 ? filteredTemplateObject[0]:'',
          templateid:filteredTemplateObject.length>0 ? filteredTemplateObject[0]?.cid:'',
          invoice_date: selectedInvoice[0]?.invoicedate? dayjs(selectedInvoice[0]?.invoicedate):'',
          language:filteredlangObject.length>0 ?filteredlangObject[0]?.code:'',
          country:filteredcountryObject.length>0 ?filteredcountryObject[0]?.code:'',
          tax:tax ?tax:'',
          invoicename:selectedAddress.length> 0 ? selectedAddress[0]?.companyname:'',
          bank:selectedBank.length>0 ? selectedBank[0].code:'',
          qr_esr_iban:selectedQrEsrIban.length>0 ? selectedQrEsrIban[0].code:'',
          condition:selectedCondition.length>0 ? selectedCondition[0].code:'',
          incomeaccount:filteredexpenseaccountObject.length>0 ? filteredexpenseaccountObject[0].code:'',
          debitaccount:filteredincomeaccountObject.length>0 ? filteredincomeaccountObject[0].code:'',
          invoicetype:selectedInvoice[0]?.invoicetype?selectedInvoice[0]?.invoicetype:'',
          email:selectedInvoice[0]?.email?selectedInvoice[0]?.email:'',
          module: selectedModuleObject.length>0 ? selectedModuleObject[0]:'',
          moduleCode: selectedModuleObject.length>0 ? selectedModuleObject[0]?.code:''
        }
      setStoredInvoice(invoiceObj);
      formik.setValues(invoiceObj);
      const filteredObjectItem = selectedInvoice[0]['items'].map((item,index)=>{item.id = index;return item});
      itemDispatch({
        type:DataGridActionTypes.ASSIGN_DATA,
        payload:filteredObjectItem
      })
      setIsItem(true);
  }

  const handleSelectionModelChange = async(newSelection) => {
    formikItem.resetForm();
    setItemOrFreetext('item');
    setIsItemOrFreetext(false);
    itemDispatch({
      type:DataGridActionTypes.ASSIGN_DATA,
      payload:[]
    })
    setNewScheduler(true);
    setIsDelete(false);
    setStoredInvoice({});
    setTaxValue(0.081);
    setValue("1");
    resetCreateInvoiceForm();
    formikScheduler.resetForm();
    setSelectedRowIdItem([]);
    setSelectedRowIdScheduler([]);
    if(((newSelection.length===0)||(newSelection[0]===selectedRowIds[0]))){
       return (setSelectedRowIds([]),
       setCompanyName(""),
       tempInvoiceId.current=[],
       setSelectedRowIdItem([]),
       settaxCode(allTaxCode),
       setSearchColumnModule(""),
       setSearchColumnTemplate(""),
       setSelectedRowIdScheduler([]),
       isSearchAddress.current = true ,
       setSearchColumnAddress(""),
       setIsNew(true),resetCreateInvoiceForm(),
       setCid(null),setIsItem(false),
       setFooterValues(defaultFooter),
       setSelectItem(false), 
       schedulerDispatch({
          type:DataGridActionTypes.ASSIGN_DATA,
          payload:[]
        }))
    };
    
    setSelectedRowIds(newSelection);
    setIsNew(false);
    setIsItem(false);
    const filteredObject = invoiceData.filter(
      (obj) => obj.cid === newSelection[newSelection.length - 1]
    );
    if(filteredObject.length===0)return;
    setCid(filteredObject[0].cid);
    setSelectItem(true);
    setIsDelete(true);

    const filteredAddressObject = address.filter(
      (obj) => obj.addressid === filteredObject[0].addressid
    );

    isSearchAddress.current = true ;
    setSearchColumnAddress(filteredAddressObject[0].companyname);
    setCompanyName(filteredAddressObject[0].companyname);

    const filteredcountryObject = country.filter(
      (obj) => obj.code === filteredObject[0]?.countryid
    );
    const filteredlangObject = lang.filter(
      (obj) => obj.code === filteredObject[0]?.languageid
    );
    const filteredTaxidObject = allTaxCode.filter(
      (obj) => obj.code === filteredObject[0]?.taxcode
    );

    let tax= filteredTaxidObject.length>0 ? filteredTaxidObject[0].code : '';

    if(filteredTaxidObject.length>0)
    {
      setTaxValue(filteredTaxidObject[0].value)
    }

    const filteredCurrencyObject = currency.filter(
      (obj) => obj.code === filteredObject[0].currencycode
    );

    const filteredModuleObject = modules.filter(
      (obj) => obj.code === filteredObject[0].module
    );

    const filteredTemplateObject = templates.filter(
      (obj) => obj.cid === filteredObject[0].templateid
    );
    isSearchModule.current = true;
    if(filteredModuleObject.length>0){
      setSearchColumnModule(filteredModuleObject[0]?.code);
    }else{
      setSearchColumnModule("");
    }
    isSearchTemplate.current = true ;
    if(filteredTemplateObject.length>0){
      setSearchColumnTemplate(filteredTemplateObject[0]?.name);
    }else{
      setSearchColumnTemplate("");
    }

    const selectedBank = bank.filter(
      (obj) => obj.code === filteredObject[0]?.bank
    );

    const selectedQrEsrIban = qrEsrIban.filter(
      (obj) => obj.code === filteredObject[0]?.qr_esr_iban
    );

    const selectedCondition = condition.filter(
      (obj) => obj.code === filteredObject[0]?.condition
    );

    const filteredincomeaccountObject = incomeAccount.filter(
      (obj) => obj.code === filteredObject[0]?.incomeaccount
    );
    const filteredexpenseaccountObject = expenseAccount.filter(
      (obj) => obj.code === filteredObject[0]?.expenseaccount
    );

    let invoiceObj =initialValuesCreateInvoice;
    invoiceObj=
      {
        address: filteredAddressObject.length>0 ?filteredAddressObject[0]:'',
        addressid: filteredAddressObject.length>0 ?filteredAddressObject[0].addressid:'',
        booking_text: filteredObject[0].bookingtext ? filteredObject[0]?.bookingtext:'',
        customer_information: filteredObject[0].customerinformation ? filteredObject[0]?.customerinformation:'',
        accounting_no_or_project_no: filteredObject[0].accountingnoorprojectno ? filteredObject[0]?.accountingnoorprojectno:'',
        our_reference:filteredObject[0].our_sign ? filteredObject[0]?.our_sign:'',
        free_text_header:filteredObject[0].free_text_header ? filteredObject[0]?.free_text_header:'',
        free_text_footer:filteredObject[0].freetextfooter ? filteredObject[0]?.freetextfooter:'',
        cust_reference:filteredObject[0].cust_sign ? filteredObject[0]?.cust_sign:'',
        currency: filteredCurrencyObject.length>0 ? filteredCurrencyObject[0]?.code:'',
        template:filteredTemplateObject.length>0 ? filteredTemplateObject[0]:'',
        templateid:filteredTemplateObject.length>0 ? filteredTemplateObject[0]?.cid:'',
        invoice_date: filteredObject[0].invoicedate ? dayjs(filteredObject[0]?.invoicedate):'',
        language:filteredlangObject.length>0 ?filteredlangObject[0]?.code:'',
        country:filteredcountryObject.length>0 ?filteredcountryObject[0]?.code:'',
        tax:tax ?tax:'',
        invoicename:filteredObject[0]?.invoicename ? filteredObject[0]?.invoicename:'',
        bank:selectedBank.length>0 ? selectedBank[0].code:'',
        qr_esr_iban:selectedQrEsrIban.length>0 ? selectedQrEsrIban[0].code:'',
        condition:selectedCondition.length>0 ? selectedCondition[0].code:'',
        incomeaccount:filteredexpenseaccountObject.length>0 ? filteredexpenseaccountObject[0].code:'',
        debitaccount:filteredincomeaccountObject.length>0 ? filteredincomeaccountObject[0].code:'',
        invoicetype:filteredObject[0].invoicetype ? filteredObject[0]?.invoicetype:'',
        email:filteredObject[0].email ? filteredObject[0].email:'',
        module: filteredModuleObject.length>0 ? filteredModuleObject[0] : '',
        moduleCode: filteredModuleObject.length>0 ?filteredModuleObject[0].code :''
      }
    formik.setValues(invoiceObj);
    setStoredInvoice(invoiceObj);
    const filteredObjectItem = filteredObject[0]['items'].map((item,index)=>{item.id = index;return item});
    itemDispatch({
      type:DataGridActionTypes.ASSIGN_DATA,
      payload:filteredObjectItem
    })
    setIsItem(true);
    schedulerDispatch({
        type:DataGridActionTypes.ASSIGN_DATA,
        payload: filteredObject[0]['scheduler'] ? filteredObject[0]['scheduler'] :[]
      });
    
  }

  const localeText = {
    noRowsLabel: t('common_no_rows'),
    footerRowSelected: (count) => `${count} ${t('common_row_selected')}`,
  };

  const handlePageSizeChange = (event) => {
    setQueueParams((previousParams)=>({
      ...previousParams,
      limit:event,
      page:1
    }))
  };

  const handlePageSizeChangeInvoiceByAddress = (event) => {
    setListDraftByAddressParams((previousParams)=>({
      ...previousParams,
      limit:event,
      page:1
    }))
  };
  const resetSchduler=()=>{
    isSearchItem.current = true ;
    setSearchColumnItem("");
    formikScheduler.resetForm();
  }

  const handleItemSubmit =()=>{
    formikItem.submitForm();
  }
  useEffect(()=>{
    let userData = getEncryptedItem('userData');
      if(userData)
      {
          let userDataobj = JSON.parse(userData)
          setUserDetails(userDataobj);
      }
  },[])

  const initialValuesCreateInvoice = useMemo(()=>{
    let obj = {
      interval:'',
      reminder:null,
      addressid:'',
      templateid:'',
      invoicename:'',
      invoicetype:'',
      template:'',
      address: '',
      address_name: null,
      booking_text: '',
      customer_information: '',
      free_text_header: '',
      free_text_footer: '',
      accounting_no_or_project_no: '',
      debitaccount: '',
      incomeaccount: '',
      condition:'',
      qr_esr_iban:'',
      bank:'',
      our_reference: '',
      cust_reference: '',
      currency: '',
      invoice_date: dayjs(),
      language: '',
      module: '',
      moduleCode: '',
      country: '',
      tax: 'SSVR81',
      email: ''
    }
    return obj;
  },[]);

  const initialValuesItem = {
    id:'',
    item_or_freetext:'item',
    freetext:'',
    cid:'',
    itemid:'',
    description:'',
    amount:'',
    price:'',
    total:'',
    income_account_name:'',
    item_description:'',
    income_account:'',
    debit_account:'',
    booking_text:'',
    exchange_rate:'',
    free_text_item:''
  };

  const initialValuesSchedulerModal = {
    name:'',
    startdate:dayjs(),
    enddate:null,
    interval:1,
    type:''
  };

  const sentInvoices =()=>{
      const createInvoiceForm = new FormData();
      createInvoiceForm.append("cid",cid);
      //createInvoiceForm.append("isAuthUser",'');
      let object = createObject(createInvoiceForm);
      let encrptedToken = encryptForm(object);
      let encrData = new FormData();
      encrData.append("encrToken",encrptedToken);
      pushToQueue(encrData)
        .then(res=>{
          if(res.status)
          {
            loadInvoiceList(true);
            openAlertSnackBar('success',res.message,marginTopValue);
            handleClear();
          }
          else{
            openAlertSnackBar('error','common_alert',marginTopValue);
          }
        })
        .catch(()=>{
          openAlertSnackBar('error','common_alert',marginTopValue);
        })
  }

  const handlePreviewInvoice=()=>{
    if(invoiceTabValue ==="sent")
    {
      generateAndShowPdf();
    }else{
      handlePreviewDraftInvoice();
    }
  }

  const handlePreviewDraftInvoice=()=>{
    if(!cid){return;}
    let viewInvoiceForm =new FormData();
    viewInvoiceForm.append("cid",cid);
    //viewInvoiceForm.append("isAuthUser",'');
    let previewObject = createObject(viewInvoiceForm);
    let encryptedToken= encryptForm(previewObject);
    let encrData = new FormData();
    encrData.append("encrToken",encryptedToken);
    setIsLoaderOverlay(true);
    previewInvoice(encrData)
     .then((res)=>{
      setIsLoaderOverlay(false);
      if(res.status)
      {
        setPDFData(res.data);
        setPDFModalOpen(true);
     }
     },
     ).catch((error)=>{
      setIsLoaderOverlay(false);
      openAlertSnackBar('error','common_alert',marginTopValue);
     })
  }

  const handleDelete = ()=>{
    if(selectedRowIds.length > 0){ 
      setOpenDeleteModal(true);
    }
  }

  const handleDeleteInvoice=()=>{
    let deleteInvoiceForm =new FormData();
    deleteInvoiceForm.append("invoiceId",cid);
    //deleteInvoiceForm.append("isAuthUser",'');
    deleteInvoiceForm.append("userName",userDetails.username?userDetails.username:'');
    let deleteObject = createObject(deleteInvoiceForm);
    let encryptedToken= encryptForm(deleteObject);
    let encrData = new FormData();
    encrData.append("encrToken",encryptedToken);
    deleteInvoice(encrData)
     .then((res)=>{
      if(res.status)
      {
        loadInvoiceList(true);
        setCid(null);
        handleCloseDeleteModal();
        openAlertSnackBar('success',res.message,marginTopValue);
     }else{
       openAlertSnackBar('error',res.message,marginTopValue);
      }
     },
     ).catch((error)=>{
      openAlertSnackBar('error','common_alert',marginTopValue);
     })
  }

  const copyInvoice =()=>{
    const d = new FormData();
    d.append("cid", cid);
    d.append("userName",userDetails.username?userDetails.username:'');
    //d.append("isAuthUser", "");
    let object = createObject(d);
    let encryptedToken = encryptForm(object);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
    copyInvoiceSheduler(encrData)
     .then((res) => {
        if (res.status) {
          if(queueParams.page=== 1){
            loadInvoiceList(true);
          }else{
            setQueueParams((previousParams)=>({
              ...previousParams,
              column:"",
              order:"",
              page:1
            }))
          }
            
          setCid(null)
        }
        else {
          openAlertSnackBar('error',res.message,marginTopValue);
        }
        })
           .catch((err) => {
             setTableLoader(false);
             openAlertSnackBar('error','common_alert',marginTopValue);
           });
  }

  const handleCopyInvoiceData = ()=>{
    if(invoiceTabValue ==="draft"){
      handleCopyInvoiceDataDraft();
    }else{
      if(invoiceByAddressSelectedId.length > 0){
        let isItemExists = invoiceItems.data.some(item => item.item_or_freetext === 'item');
        if(isItemExists){
          formik.submitForm();
          setValue("1");
        }
      }
    }
  }

  const handleCopyInvoiceDataDraft = ()=>{
    setIsNew(true);
    const invoiceName = formik.values.invoicename;
    formik.setFieldValue("invoicename",invoiceName+"_copy");
    isCopy.current = true;
    tempInvoiceId.current=[];
    copyInvoice();
  }

  const handleCancelInvoiceDate = ()=>{
    if((selectedRowIds.length===0)&&(invoiceByAddressSelectedId.length === 0)){ formik.setValues(initialValuesCreateInvoice) ; return;}
    resetCreateInvoiceForm();
    formik.setValues(storedInvoice);
    if(invoiceItems.data.length===0){return setIsItem(false) }
  }

  //     formik.setFieldValue("language", '');

  const validationSchema = Yup.object().shape({
    templateid: Yup.number().required(t('common_validate_template')),
    addressid: Yup.number().required(t('address_companyname_required')),
    booking_text: Yup.string().required(t('common_validate_booking_text')),
    our_reference: Yup.string().required(t('common_validate_our_reference')),
    cust_reference: Yup.string().required(t('common_validate_custom_reference')),
    currency: Yup.string().required(t('address_currencycode_required')),
    country: Yup.string().required(t('common_validate_countrycode')),
    language: Yup.string().required(t('common_validate_language')),
    moduleCode: Yup.string().required(t('common_validate_module')),
    condition: Yup.string().required(t("common_validate_condition")),
    incomeaccount: Yup.string().required(t("common_validate_expense_account")),
    debitaccount: Yup.string().required(t("common_validate_income_account")),
    qr_esr_iban: Yup.string().required(t("common_validate_qr_esr_iban")),
    bank: Yup.string().test('check-bank-validation', t("common_validate_bank"), function(value) {
      const QrEsr = this.parent.qr_esr_iban; 
      if ((QrEsr === 'METHOD_LESS')||(value)) {
        return true; 
      }
      return false; 
    }),
    invoice_date: Yup.date().required(t('common_validate_invoice_date')),
    tax: Yup.string().required(t('common_validate_tax')),
    email: Yup.string().test(
      'isMultipleEmails',
      t('invalid_email'),
      value => {
        if (!value) return false; 
        const emails = value.split(',').map(email => email.trim());
        const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i;
        return emails.every(email => emailRegex.test(email));
      }
    )
  });

  const handleChangeItemOrFreetext = (event)=>{
    setItemOrFreetext(event.target.value);
    formikItem.resetForm();
    formikItem.setFieldValue('item_or_freetext',event.target.value);
  }

  const validationSchemaItem = Yup.object().shape({
    description:Yup.string().required(t('item_required')),
    amount:Yup.number().typeError(t('common_validation_input_number')).required(t('common_validate_amount')),
    price:Yup.number().typeError(t('common_validation_input_number')).required(t('common_validate_price')),
    total:Yup.number().typeError(t('common_validation_input_number')).required(t('common_validate_total_price')),
    income_account_name:Yup.string().required(t('common_validate_income_account'))
  });

  const validationSchemaFreetext = Yup.object().shape({
    free_text_item:Yup.string().required(t('common_validate_freetext'))
  });

  const validationSchemaScheduler = Yup.object().shape({
    name:Yup.string().required(t('address_companyname_required')),
    enddate:Yup.date().nullable().test('is-after-startdate', t('common_validate_enddate_precedes_startdate'), function(value) {
      if(!value)return true
      const startDate = new Date(this.parent.startdate); 
      return startDate && value && value > startDate; 
    }),
    interval:Yup.number().typeError(t('common_validation_input_number')).required(t('common_validate_interval')),
    type:Yup.string().required(t('common_validate_type')),
  });


  const handleSortModelChange = (newModel) => {
    if (newModel.length > 0) {
      setQueueParams((previousParams)=>({
        ...previousParams,
        column:newModel[0].field,
        order:newModel[0].sort,
        page:1
      }))
    }else{
      setQueueParams((previousParams)=>({
        ...previousParams,
        column:"",
        order:"",
        page:1
      }))
    }
  };

  const handleSortModelChangeInvoiceByAddress = (newModel) => {
    if (newModel.length > 0) {
      setListDraftByAddressParams((previousParams)=>({
        ...previousParams,
        column:newModel[0].field,
        order:newModel[0].sort,
        page:1
      }))
    }else{
      setListDraftByAddressParams((previousParams)=>({
        ...previousParams,
        column:"",
        order:"",
        page:1
      }))
    }
  };

  const companyListRef = useRef(null);

  const handleScrollAddress = (event) => {
    const { target } = event;
    const lastEntryIndex = addressData.length - 10; 
    const count = Math.ceil((addressData.length)/100); 

    if (
      target?.scrollTop + target?.clientHeight >=
      target?.scrollHeight - target?.children[lastEntryIndex]?.clientHeight
    ) {
      isSearchAddress.current = false;
      debouncedFilterScroll(count+1);
      
    }
  };

  const handleScrollAddressAddressByDraft = (event) => {
    const { target } = event;
    const lastEntryIndex = addressData.length - 10; 
    const count = Math.ceil((addressData.length)/100); 

    if (
      target?.scrollTop + target?.clientHeight >=
      target?.scrollHeight - target?.children[lastEntryIndex]?.clientHeight
    ) {
      isSearchAddressBydraft.current = false;
      debouncedFilterScrollAddressByDraft(count+1);
      
    }
  };

  const handleScrollItem = (event) => {
    const { target } = event;
    const lastEntryIndex = itemData[0].length - 10; 
    const count = Math.ceil((itemData[0].length)/100); 
    if (
      target?.scrollTop + target?.clientHeight >=
      target?.scrollHeight - target.children[lastEntryIndex]?.clientHeight
    ) {
      isSearchItem.current = false;
      debouncedFilterScrollItem(count+1);
    }
  };

  const debouncedFilterScrollModule = debounce((query) =>
    setModuleOptionParams({page:query})
  , 300)

  const debouncedFilterScrollTemplate = debounce((query) =>
    setTemplateOptionParams({page:query})
  , 300)

  const handleScrollTemplate = (event) => {
    const { target } = event;
    const lastEntryIndex = templateData[0].length - 10; 
    const count = Math.ceil((templateData[0].length)/100); 
    if (
      target?.scrollTop + target?.clientHeight >=
      target?.scrollHeight - target.children[lastEntryIndex]?.clientHeight
    ) {
      isSearchTemplate.current = false;
      debouncedFilterScrollTemplate(count+1);
    }
  };

  const handleScrollModule = (event) => {
    const { target } = event;
    const lastEntryIndex = moduleData[0].length - 10;
    const count = Math.ceil((moduleData[0].length)/100); 
    if (
      target?.scrollTop + target?.clientHeight >=
      target?.scrollHeight - target.children[lastEntryIndex]?.clientHeight
    ) {
      isSearchModule.current = false;
      debouncedFilterScrollModule(count+1);
    }
  };
  
  const formik = useFormik({
    initialValues: initialValuesCreateInvoice,
    validationSchema,   

    onSubmit: (values) => {
      let itemLists = itemList.map((item,index)=>{item.id = index;return item});
      
      if (isNew)
      {
        tempInvoiceId.current = [];
        let invoiceDateVar = null;
        if (values.invoice_date !== null) {
          invoiceDateVar = DateConverter(values.invoice_date);
        }
  
        const filteredCurrencyObject = currency.filter(
          (obj) => obj.code === values.currency
        );
  
        const invoiceObject={};
        invoiceObject["invoicedate"]=invoiceDateVar;
        invoiceObject["addressid"]=parseFloat(values.addressid);
        invoiceObject["currencycode"]=values.currency;
        invoiceObject["currencyid"]=filteredCurrencyObject[0]?.id.toString();
        invoiceObject["items"]=itemLists;
        invoiceObject["scheduler"]=schedulerState.data;
        invoiceObject["countryid"]=values.country;
        invoiceObject["languageid"]=values.language;
        invoiceObject["email"]=values.email;
        invoiceObject["module"]=values.moduleCode;
        invoiceObject["templateid"]=values.template.cid;
        invoiceObject["bookingtext"]=values.booking_text;
        invoiceObject["customerinformation"]=values.customer_information;
        invoiceObject["free_text_header"]=values.free_text_header;
        invoiceObject["freetextfooter"]=values.free_text_footer;
        invoiceObject["accountingnoorprojectno"]=values.accounting_no_or_project_no;
        invoiceObject["invoicename"]=values.invoicename;
        invoiceObject["invoicetype"]=values.invoicetype;
        invoiceObject["taxcode"]=values.tax;
        invoiceObject["our_sign"]=values.our_reference;
        invoiceObject["expenseaccount"]=values.incomeaccount;
        invoiceObject["incomeaccount"]=values.debitaccount;
        invoiceObject["bank"]= values.qr_esr_iban === 'METHOD_LESS' ? '' :values.bank;
        invoiceObject["qr_esr_iban"]=values.qr_esr_iban;
        invoiceObject["condition"]=values.condition;
        invoiceObject["cust_sign"]=values.cust_reference;
        const createInvoiceForm = new FormData();
        createInvoiceForm.append("invoicedata",JSON.stringify(invoiceObject));
        createInvoiceForm.append("userName",userDetails.username?userDetails.username:'');
        createInvoiceForm.append("items",JSON.stringify(itemLists));
        createInvoiceForm.append("scheduler",JSON.stringify(schedulerState.data));
        //createInvoiceForm.append("isAuthUser",'');
        let object = createObject(createInvoiceForm);
        let encrptedToken = encryptForm(object);
        let encrData = new FormData();
        encrData.append("encrToken",encrptedToken);
  
        saveInvoice(encrData)
          .then(res=>{
            if(res.status)
            {
              if(queueParams.page === 1)
              {
                loadInvoiceList(true)
              }else{
                setQueueParams((previousParams)=>({
                  ...previousParams,
                  page:1
                }))
              }
              handleClear();
              openAlertSnackBar('success',res.message,marginTopValue);
              setInvoiceTabValue('draft');
            }else{
              openAlertSnackBar('error',res.message,marginTopValue);
            }
          })
          .catch(()=>{
            openAlertSnackBar('error','common_alert',marginTopValue);
          })
      }else
      {
        if(invoiceItems.data.length===0)
        {
          openAlertSnackBar('error','item_cannot_be_empty',marginTopValue);
          return
        }
        let invoiceDateVar = null;
        if (values.invoice_date !== null) {
          invoiceDateVar = DateConverter(values.invoice_date);
        }
  
        const filteredCurrencyObject = currency.filter(
          (obj) => obj.code === values.currency
        );

        const invoiceObject={};
        invoiceObject["invoicedate"]=invoiceDateVar;
        invoiceObject["addressid"]=parseFloat(values.addressid);
        invoiceObject["currencycode"]=values.currency;
        invoiceObject["countryid"]=values.country;
        invoiceObject["languageid"]=values.language;
        invoiceObject["taxcode"]=values.tax;
        invoiceObject["module"]=values.moduleCode;
        invoiceObject["templateid"]=values.template.cid;
        invoiceObject["template"]=values.template;
        invoiceObject["currencyid"]=filteredCurrencyObject[0].id.toString();
        invoiceObject["items"]=itemLists;
        invoiceObject["bookingtext"]=values.booking_text;
        invoiceObject["customerinformation"]=values.customer_information;
        invoiceObject["free_text_header"]=values.free_text_header;
        invoiceObject["freetextfooter"]=values.free_text_footer;
        invoiceObject["accountingnoorprojectno"]=values.accounting_no_or_project_no;
        invoiceObject["invoicename"]=values.invoicename;
        invoiceObject["invoicetype"]=values.invoicetype;
        invoiceObject["email"]=values.email;
        invoiceObject["our_sign"]=values.our_reference;
        invoiceObject["expenseaccount"]=values.incomeaccount;
        invoiceObject["incomeaccount"]=values.debitaccount ;
        invoiceObject["bank"]= values.qr_esr_iban === 'METHOD_LESS' ? '' :values.bank;
        invoiceObject["qr_esr_iban"]=values.qr_esr_iban;
        invoiceObject["condition"]=values.condition;
        invoiceObject["cust_sign"]=values.cust_reference;
  
        const updateInvoiceForm = new FormData();
        updateInvoiceForm.append("invoicedata",JSON.stringify(invoiceObject));
        updateInvoiceForm.append("items",JSON.stringify(itemLists));
        updateInvoiceForm.append("userName",userDetails.username?userDetails.username:'');
        updateInvoiceForm.append("cid",cid);
        let object = createObject(updateInvoiceForm);
        let encrptedToken = encryptForm(object);
        let encrData = new FormData();
        encrData.append("encrToken",encrptedToken);
  
        updateInvoice(encrData)
          .then(res=>{
            if(res.status)
            {
              handleClear();
              tempInvoiceId.current = selectedRowIds;
              // tempUpdateStatus.current = 'updated';
              loadInvoiceList(true);
              openAlertSnackBar('success',res.message,marginTopValue);
            }else{
              openAlertSnackBar('error',res.message,marginTopValue);
            }
          })
          .catch(()=>{
            openAlertSnackBar('error','common_alert',marginTopValue);
          })
      }
    }
  });

  useEffect(()=>{
    if(selectedRowIds.length >0){
      isSelectRowEditTemplate.current = false;
      let selectedInvoice = invoiceData.filter((invoice)=>invoice.cid === selectedRowIds[0]);
      if(selectedInvoice.length  === 0)return;
      if(selectedInvoice[0]?.status.toLowerCase() === 'scheduler draft'){
        setIsSchedulerShow(false)
        // console.log('selectedInvoice',selectedInvoice);
      }else{
        setIsSchedulerShow(true);
      }
    }else{
      setIsSchedulerShow(true);
      // isSchedulerShow
    }
  },[selectedRowIds])

  useEffect(()=>{

    if((formik.values.templateid)){
      let isNew = false ;
      if((!formik.values?.moduleCode)||(!formik.values?.condition)||(!formik.values?.qr_esr_iban)||(!formik.values?.language)){
        isNew = true ;
      }
      if((selectedRowIds.length !== 0 || invoiceByAddressSelectedId.length !==0 )){
        if(!isSelectRowEditTemplate.current)return
      }

      const fieldValue = formik.values.templateid;
      const templateArray = templates.filter(item=>item.cid ===fieldValue);
      if(templateArray.length===0){return}
      const templateObj = templateArray[0];

      const templateTax = taxCode.filter((obj) => (obj.code === templateObj?.tax_field))[0] 
      const templateBank = bank.filter((obj) => (obj.code === templateObj?.banktype))[0] ;
      const currency = templateBank?.bankConfig?.currency || '';
      const templateLanguage = lang.filter((obj) => obj.id === templateObj?.languageid);
      const templateIncomeAccount = incomeAccount.filter((obj) => obj.code === templateObj?.income_account);
      const templateExpenseAccount = expenseAccount.filter((obj) => obj.code === templateObj?.expense_account);
      const templateQrIban = qrEsrIban.filter((obj) => obj.code === templateObj?.qr_esr_iban);
      const templateCondtion = condition.filter((obj) => obj.id === templateObj?.conditionid);
      const templateModule = modules.filter((obj) => obj.id === templateObj?.moduleid);

      let templateUpdateArray = [];

      function insertTemplateUpdateArray (name,oldValue,newValue){
        const createTemplateObj = (name,oldValue,newValue)=>{
          return {name,oldValue,newValue}
        }
        templateUpdateArray.push(createTemplateObj(name,oldValue,newValue));
      }

      if((templateLanguage.length > 0)&& (formik.values.language !== templateLanguage[0].code)){
        const previousLanguage = lang.filter((obj) => obj.code === formik.values?.language);
        insertTemplateUpdateArray (t("common_language"),previousLanguage.length > 0 ? t(previousLanguage[0].label) :'',t(templateLanguage[0]?.label) )
      }
      if((templateModule.length > 0) && (formik.values.moduleCode !== templateModule[0]?.code)){
        const previousModule = modules.filter((obj) => obj.code === formik.values?.moduleCode);
        insertTemplateUpdateArray (t("common_module"),previousModule.length > 0 ? previousModule[0].label :'',templateModule[0]?.label )
      }
      if( (formik.values.currency !== currency)){
        insertTemplateUpdateArray (t("common_currency_code"),formik.values.currency,currency )
      }
      if( (formik.values.our_reference !== templateObj?.our_reference)){
        insertTemplateUpdateArray (t("common_our_reference"),formik.values.our_reference,templateObj?.our_reference )
      }
      if((templateCondtion.length > 0) && (formik.values.condition !== templateCondtion[0]?.code)){
        const previousCondition = condition.filter((obj) => obj.code === formik.values?.condition);
        insertTemplateUpdateArray (t("condition"),previousCondition.length > 0 ? previousCondition[0].label :'',templateCondtion[0]?.label )
      }
      if( (formik.values.tax !== templateTax?.code)){
        const previousTax = taxCode.filter((obj) => (obj.code === formik.values.tax))[0] 
        insertTemplateUpdateArray (t("tax_field"),previousTax.label,templateTax?.label )
      }
      if( (templateQrIban.length > 0) && (formik.values.qr_esr_iban !== templateQrIban[0]?.code)){
        const previousQrEsrIban = qrEsrIban.filter((obj) => obj.code === formik.values?.qr_esr_iban);
        insertTemplateUpdateArray (t("common_qr_esr_iban"),previousQrEsrIban.length > 0 ? t(previousQrEsrIban[0].label) :'', t(templateQrIban[0]?.label) )
      }
      if (formik.values.bank !== templateBank?.code){
        const previousBank = bank.filter((obj) => (obj.code === formik.values?.bank)) ;
        insertTemplateUpdateArray (t("Bank"),previousBank.length > 0 ? previousBank[0]?.bankConfig?.bankName +' - '+ previousBank[0]?.bankConfig?.currency +'\n( '+qrAccount +' )':'',
        templateBank?.bankConfig?.bankName ?templateBank?.bankConfig?.bankName +' - '+templateBank?.bankConfig?.currency +'\n( ' +templateBank?.bankConfig?.bankAccount +' )' :'')
      }
      if( (templateExpenseAccount.length > 0)  && (formik.values.incomeaccount !== templateExpenseAccount[0]?.code )){
        const previousIncomeAccount = expenseAccount.filter((obj) => obj.code === formik.values?.incomeaccount);
        insertTemplateUpdateArray (t("SollKonto"),previousIncomeAccount.length > 0 ? previousIncomeAccount[0].label :'', templateExpenseAccount[0]?.label )
      }
      if( (templateIncomeAccount.length > 0)  && (formik.values.debitaccount !== templateIncomeAccount[0]?.code )){
        const previousExpenseAccount = incomeAccount.filter((obj) => obj.code === formik.values?.debitaccount);
        insertTemplateUpdateArray (t("ertragskonto"),previousExpenseAccount.length > 0 ? previousExpenseAccount[0].label :'', templateIncomeAccount[0]?.label )
      }
      if(formik.values.free_text_header !== templateObj?.header){
        insertTemplateUpdateArray (t("free_text_header"),formik.values.free_text_header,templateObj?.header)
      }
      if(formik.values.free_text_footer !== templateObj.footer){
        insertTemplateUpdateArray (t("free_text_footer"),formik.values.free_text_footer,templateObj.footer)
      }

      if((!isChangeTemplate)&&(!isNew)){
        if(templateUpdateArray.length > 0 ){
          setOpenTemplateConfirmModal(true);
          setTemplateDetails(templateUpdateArray);
        }else{
          setOpenTemplateConfirmModal(false);
        }
      }else{
        if(templateModule.length > 0 ){
          isSearchModule.current = true;
          setSearchColumnModule(templateModule[0]?.code);
          formik.setFieldValue("moduleCode", templateModule[0]?.code);
          formik.setFieldValue("module", templateModule[0]);
        }
        formik.setFieldValue("condition", templateCondtion.length > 0 ? templateCondtion[0]?.code : '');
        formik.setFieldValue("bank", templateBank?.code);
        formik.setFieldValue("qr_esr_iban", templateQrIban.length > 0 ? templateQrIban[0]?.code  : '');
        if(templateTax){
          formik.setFieldValue("tax", templateTax.code);
          setTaxValue(Number(templateTax.value));
        }

        formik.setFieldValue("currency", currency ? currency :'');
        formik.setFieldValue("language",  templateLanguage.length > 0 ? templateLanguage[0]?.code :'' );
        formik.setFieldValue("debitaccount", templateIncomeAccount.length > 0 ? templateIncomeAccount[0]?.code :'');
        formik.setFieldValue("incomeaccount",templateExpenseAccount.length > 0 ?  templateExpenseAccount[0]?.code :'');
        formik.setFieldValue("our_reference", templateObj.our_reference ? templateObj.our_reference :'');
        formik.setFieldValue("free_text_footer", templateObj.footer ? templateObj.footer :'');
        formik.setFieldValue("free_text_header", templateObj.header ? templateObj.header :'');
      // setFooterValues((footer)=>({
      //   ...footer,
      //   totalVat:0.081
      // }))
      }
      
    }
  },[formik.values.templateid,isChangeTemplate])

  useEffect(()=>{
    if(formik.values.qr_esr_iban){
      let qrEsrBank = bank.filter((details)=>details.code.includes(formik.values.qr_esr_iban));

      if(qrEsrBank.length === 0){
        formik.setFieldValue("bank", "");
      }
      setQrEsrBank(qrEsrBank);
    }else{
      setQrEsrBank(bank);
    }
  },[formik.values.qr_esr_iban,bank]);

  useEffect(()=>{
    if(formik.values.bank)
    {
      const bankDetails = qrEsrBank.filter((item)=>item.code===formik.values.bank)
      const bankAccount = bankDetails.length > 0 ? bankDetails[0]['bankConfig']['bankAccount'] : null ;
      if(bankAccount){
        setQrAccount(bankAccount);
      }else{
        setQrAccount(null);
      }
    }else{
      setQrAccount(null);
    }
  },[formik.values.bank,qrEsrBank])

  useEffect(()=>{
    if(formik.values.address ){
      let email = formik.values.address.email;
      if(email){
        if(selectedRowIds.length === 0){
          formik.setFieldValue("email",email);
        }
      }
    }
  },[formik.values.address])

  useEffect(()=>{
    if((formik.values.address || formik.values.invoicetype )){
      if(selectedRowIds.length !== 0)return;
      let invoice_type;
      let bookingValue = '';
      let address = formik.values.address.companyname;
      
      if(formik.values.invoicetype){
        invoice_type = invoiceType.filter((type)=>type.code === formik.values.invoicetype)[0].name;
      }
      if(invoice_type && address){
        bookingValue = address +', [INVOICEID], '+invoice_type;
      }else if( address ){
        bookingValue = address +', [INVOICEID]';
      }
      else if( invoice_type){
        bookingValue = '[INVOICEID], '+invoice_type;
      }

      if(!formik.values.booking_text){
        formik.setFieldValue("booking_text",bookingValue);
      }else{
        if(selectedRowIds.length === 0){
          formik.setFieldValue("booking_text",bookingValue);
        }
      }
    }else{
      formik.setFieldValue("booking_text",'')
    }
  },[formik.values.address,formik.values.invoicetype]);

  useEffect(()=>{
    if((selectedInvoiceId.current.length>0)&&(invoiceData.length>0))
    {
      let selection = selectedInvoiceId.current;
      setSelectedRowIds(selection);
      tempInvoiceId.current = selection;
      setIsNew(false);
      setIsItem(false);
      const filteredObject = invoiceData.filter(
        (obj) => obj.cid === selection[0]
      );
      if(filteredObject.length===0)return;
      setCid(filteredObject[0].cid);
      setSelectItem(true);
      setIsDelete(true);
  
      const filteredAddressObject = address.filter(
        (obj) => obj.addressid === filteredObject[0].addressid
      );
  
      addressOptionDispatch({
        type:DataGridActionTypes.ASSIGN_DATA,
        payload:address
      })
      setSearchColumnAddress(filteredAddressObject[0].companyname);
      setCompanyName(filteredAddressObject[0].companyname);

      templateOptionDispatch({
        type:DataGridActionTypes.ASSIGN_DATA,
        payload:templates
      })
        const filteredcountryObject = country.filter(
          (obj) => obj.code === filteredObject[0]?.countryid
        );
        const filteredlangObject = lang.filter(
          (obj) => obj.code === filteredObject[0]?.languageid
        );
        const filteredTaxidObject = taxCode.filter(
          (obj) => obj.code === filteredObject[0]?.taxcode
        );
  
        let tax= filteredTaxidObject.length>0 ? filteredTaxidObject[0].code : '';
        if(filteredTaxidObject.length>0)
        {
          setTaxValue(filteredTaxidObject[0].value);
        }
  
      const filteredCurrencyObject = currency.filter(
        (obj) => obj.code === filteredObject[0].currencycode
      );
      const filteredModuleObject = modules.filter(
        (obj) => obj.code === filteredObject[0].module
      );
      const filteredTemplateObject = templates.filter(
        (obj) => obj.cid === filteredObject[0].templateid
      );

      if(filteredModuleObject.length>0){
        setSearchColumnModule(filteredModuleObject[0]?.code);
      }else{
        setSearchColumnModule("");
      }
      if(filteredTemplateObject.length>0){
        setSearchColumnTemplate(filteredTemplateObject[0]?.name);
      }else{
        setSearchColumnTemplate("");
      }

      const selectedBank = bank.filter(
        (obj) => obj.code === filteredObject[0]?.bank
      );
  
      const selectedQrEsrIban = qrEsrIban.filter(
        (obj) => obj.code === filteredObject[0]?.qr_esr_iban
      );
  
      const selectedCondition = condition.filter(
        (obj) => obj.code === filteredObject[0]?.condition
      );
  
      const filteredincomeaccountObject = incomeAccount.filter(
        (obj) => obj.code === filteredObject[0]?.incomeaccount
      );
      const filteredexpenseaccountObject = expenseAccount.filter(
        (obj) => obj.code === filteredObject[0]?.expenseaccount
      );

      let invoiceObj = initialValuesCreateInvoice ;

      invoiceObj=
        {
          address: filteredAddressObject.length>0 ?filteredAddressObject[0]:'',
          addressid: filteredAddressObject.length>0 ?filteredAddressObject[0].addressid:'',
          booking_text: filteredObject[0].bookingtext ? filteredObject[0]?.bookingtext:'',
          customer_information: filteredObject[0].customerinformation ? filteredObject[0]?.customerinformation:'',
          accounting_no_or_project_no: filteredObject[0].accountingnoorprojectno ? filteredObject[0]?.accountingnoorprojectno:'',
          free_text_header:filteredObject[0].free_text_header ? filteredObject[0]?.free_text_header:'',
          free_text_footer:filteredObject[0].freetextfooter ? filteredObject[0]?.freetextfooter:'',
          our_reference:filteredObject[0].our_sign ? filteredObject[0]?.our_sign:'',
          cust_reference:filteredObject[0].cust_sign ? filteredObject[0]?.cust_sign:'',
          currency: filteredCurrencyObject.length>0 ? filteredCurrencyObject[0]?.code:'',
          template: filteredTemplateObject.length>0 ? filteredTemplateObject[0]:'',
          templateid: filteredTemplateObject.length>0 ? filteredTemplateObject[0]?.cid:'',
          invoice_date: filteredObject[0].invoicedate ? dayjs(filteredObject[0]?.invoicedate):'',
          language:filteredlangObject.length>0 ?filteredlangObject[0]?.code:'',
          country:filteredcountryObject.length>0 ?filteredcountryObject[0]?.code:'',
          tax:tax ?tax:'',
          invoicename:filteredObject[0].invoicename ? filteredObject[0]?.invoicename:'',
          bank:selectedBank.length>0 ? selectedBank[0].code:'',
          qr_esr_iban:selectedQrEsrIban.length>0 ? selectedQrEsrIban[0].code:'',
          condition:selectedCondition.length>0 ? selectedCondition[0].code:'',
          incomeaccount:filteredexpenseaccountObject.length>0 ? filteredexpenseaccountObject[0].code:'',
          debitaccount:filteredincomeaccountObject.length>0 ? filteredincomeaccountObject[0].code:'',
          invoicetype:filteredObject[0].invoicetype ? filteredObject[0]?.invoicetype:'',
          email:filteredObject[0].email ? filteredObject[0].email:'',
          module: filteredModuleObject.length>0 ? filteredModuleObject[0] : '',
          moduleCode: filteredModuleObject.length>0 ?filteredModuleObject[0].code :''
        }
      formik.setValues(invoiceObj);
      setStoredInvoice(invoiceObj);
    
      itemDispatch({
        type:DataGridActionTypes.ASSIGN_DATA,
        payload:filteredObject[0]['items']
      })
      setIsItem(true);
      schedulerDispatch({
          type:DataGridActionTypes.ASSIGN_DATA,
          payload: filteredObject[0]['scheduler'] ? filteredObject[0]['scheduler'] :[]
        });
      
      // if(isCopy.current || tempUpdateStatus.current === 'updated')
      if(isCopy.current )
      {
        setValue("1");
        isCopy.current = false;
      }else if(isScheduler.current )
      {
        setValue("3");
        isScheduler.current = false;
      }
      
      selectedInvoiceId.current =[];
    }
    
  },[lang,taxCode,address,country,currency,invoiceData,formik,modules,templates])

  const handleFieldChangeItem = (event) => {
    const amount =formikItem.values.amount;
    if(!event){
      formikItem.setFieldValue("itemid", '');
      formikItem.setFieldValue("cid", '');
      formikItem.setFieldValue("description", '');
      formikItem.setFieldValue("price", '');
      formikItem.setFieldValue("income_account", '');
      formikItem.setFieldValue("debit_account", '');
      formikItem.setFieldValue("income_account_name", '');
      formikItem.setFieldValue("item_description", '');
      formikItem.setFieldValue("item", '');
      isSearchItem.current = true ;
      setSearchColumnItem("");
      totalPrice(amount,"");
      return;
    }
      const selectedItem = event;
      formikItem.setFieldValue("cid", selectedItem.cid);
        formikItem.setFieldValue("description", selectedItem.title);
        formikItem.setFieldValue("price", selectedItem.sellprice);
        formikItem.setFieldValue("income_account", selectedItem.earningaccount);
        formikItem.setFieldValue("debit_account", selectedItem.expenseaccount+" | "+selectedItem.debitaccount);
        formikItem.setFieldValue("income_account_name", selectedItem.earningaccount +" | "+selectedItem.incomeaccount);
        formikItem.setFieldValue("item_description", selectedItem.description);
        formikItem.setFieldValue("itemid", selectedItem.itemid);
        formikItem.setFieldValue("item", selectedItem);
        isSearchItem.current = true ;
        setSearchColumnItem( selectedItem.title);
        totalPrice(amount,selectedItem.sellprice);
  };
  
  const DateConverter = (dateForm) => {
    let formattedDate = ''
    if (dateForm) {
      const date = dateForm.$d;
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      formattedDate = `${year}-${month}-${day}`;
    }
    return formattedDate;
  }

  useMemo(() => {
    if(invoiceItems.data.length > 0){
    const totalFilterArray =invoiceItems.data.filter((item)=>item?.total);
    const totalArray =totalFilterArray.map((item)=>item?.total);
    const sumTotal= Math.round(((totalArray.map(Number).reduce((accumulator,currnetValue)=>accumulator+currnetValue,0)) + Number.EPSILON) * 100) / 100;
    const roundTotalVat = Math.round(((parseFloat(sumTotal)*taxValue) + Number.EPSILON) * 20) / 20;
    const totalPrice = Math.round(((parseFloat(sumTotal)+parseFloat(roundTotalVat)) + Number.EPSILON) * 100) / 100;;
    setFooterValues({
      total_amountExclvat:formattedAmount(parseFloat(sumTotal)) ,
      totalVat:formattedAmount(parseFloat(roundTotalVat)),
      totalAmntInclVat:formattedAmount(parseFloat(totalPrice))
    })
    }else{
      setFooterValues((footer)=>({
        ...footer,
        totalVat:Number(taxValue)
      }))
    }
  }, [invoiceItems,taxValue])

  const formateD =(data) => {
      return new Date(data).toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    }

  const changeDateformat=(date)=>{
    const dateFormat =date.split('/');
    return `${dateFormat[2]}-${dateFormat[0]}-${dateFormat[1]}`
  }

  const schduleValues = {
    startdate:'',
    enddate:null,
    interval:'',
    type:''
  };

  const formikScheduler = useFormik({
    initialValues: initialValuesSchedulerModal,
    validationSchema:validationSchemaScheduler,
    onSubmit: (values) => {
      for (const key in values) {
        if (values.hasOwnProperty(key)) {
          schduleValues[key] = values[key];
        }
      }

      const enddate=values.enddate ? formateD(values.enddate) :null;
      const startdate=values.startdate ? formateD(values.startdate):'';
      schduleValues.enddate=enddate ?changeDateformat(enddate):'';
      schduleValues.startdate = startdate ? changeDateformat(startdate):'';
      setSelectedRowIdScheduler([]);
      if(!schedulerCid)
      {
        schedulerDispatch({
          type:DataGridActionTypes.ADD_DATA,
          payload: schduleValues
        });
      }
      if((!newScheduler)&&(schedulerCid)) 
      {
        (schduleValues.cid = schedulerCid)
      };
      isScheduler.current = true;
        if(((!isNew)&&(!newScheduler)&&(schedulerCid))){
          let updateSchedulerForm = new FormData();
          updateSchedulerForm.append("schedulerdata",JSON.stringify(schduleValues));
          updateSchedulerForm.append("userName",userDetails.username?userDetails.username:'');
          if (acceptNoReminder) {
            updateSchedulerForm.append('acceptNoReminder', 'true');
          }
          updateSchedulerForm.append("cid",schedulerCid);
          let object = createObject(updateSchedulerForm);
          let encrptedToken = encryptForm(object);
          let encrData = new FormData();
          encrData.append("encrToken",encrptedToken);
    
          updateInvoiceSheduler(encrData)
            .then(res=>{
              if(res.status)
              {
                loadInvoiceList(true);
                setSchedulerCid([]);
                setNewScheduler(true);
                schedulerDispatch({
                  type: newScheduler ? DataGridActionTypes.ADD_DATA : DataGridActionTypes.UPDATE_DATA,
                  payload:schduleValues
                });
                setAcceptNoReminder(false);
                formikScheduler.resetForm();
                setOpenErrorModal(false);
              }else{
                if(res.no_reminder)
                {
                  setAcceptNoReminder(true);
                  setErrorMessage(t(res.message));
                  setOpenErrorModal(true);
                }
              }
            })
            .catch(()=>{
            })
        }else{
          let filteredValue = invoiceData.filter(
            (obj) => obj.cid === selectedRowIds[0]
          );
          if((filteredValue.length===0)|| (!filteredValue[0].invoiceid)){return(formikScheduler.resetForm())};

          let createSchedulerForm = new FormData();
         
          createSchedulerForm.append("userName",userDetails.username?userDetails.username:'');
          createSchedulerForm.append("invoiceid",filteredValue[0]?.invoiceid);
          createSchedulerForm.append("schedulerdata",JSON.stringify(schduleValues));
          if (acceptNoReminder) {
            createSchedulerForm.append('acceptNoReminder', 'true');
          }
          let object = createObject(createSchedulerForm);
          let encrptedToken = encryptForm(object);
          let encrData = new FormData();
          encrData.append("encrToken",encrptedToken);
          createInvoiceSheduler(encrData)
            .then(res=>{
              if(res.status)
              {
                loadInvoiceList(true);
                setSchedulerCid([]);
                schedulerDispatch({
                  type: newScheduler ? DataGridActionTypes.ADD_DATA : DataGridActionTypes.UPDATE_DATA,
                  payload:schduleValues
                });
                setAcceptNoReminder(false);
                formikScheduler.resetForm();
                setOpenErrorModal(false);
              }
             
              else{
               if(res.no_reminder)
                {
                  setAcceptNoReminder(true);
                  setErrorMessage(t(res.message));
                  setOpenErrorModal(true);
                }
              }
            })
            .catch(()=>{
            })
       }
       selectedInvoiceId.current = selectedRowIds;
    }
  });

  
  useEffect(()=>{
    if(invoiceItems.data.length>0){
      setItemList(invoiceItems.data);
      let isItemExists = invoiceItems.data.some(item => item.item_or_freetext === 'item');
      if(isItemExists ){
        setIsItem(true);
      }else{
        setIsItem(false);
      }
    }else{
      setItemList([]);
      setIsItem(false);
    }
  },[invoiceItems.data])

  const gethighestId=()=>{
    let highestId = 0;
    itemList.forEach(obj => {
      if (obj.id > highestId) {
          highestId = obj.id;
      }
    });
    return highestId+1;
  }

  // const isGreaterThanZero= (number) =>{
  //   const regex = /^(?!0(\.0+)?$)(\d+(\.\d+)?)$/;
  //   return regex.test(number.toString());
  // }

  const formikItem = useFormik({
    initialValues: initialValuesItem,
    validationSchema: itemOrFreetext !=='freetext' ? validationSchemaItem : validationSchemaFreetext,
    onSubmit: (values) => {
      if(formikItem.dirty)
      {
        if(values.free_text_item)
        {
          values.free_text_item=values.free_text_item.trim();
          values.description=values.free_text_item.trim();
          if(!values.description){
            return;
          }
        }

        // let isQuantityGreaterZero =  isGreaterThanZero(values.amount);
        // if(isQuantityGreaterZero){
          if (newItem){
            let _id = 0
            if(itemList.length>0){
              _id = gethighestId();
            }
            values.id = _id;
            
            itemDispatch({
              type:DataGridActionTypes.ADD_DATA,
              payload:values
            })
          }else{
            if(selectedRowIdItem.length>0){
              itemDispatch({
                type:DataGridActionTypes.UPDATE_DATA_NO_CID,
                payload:{cid:selectedRowIdItem[0],value:values}
              })
            }else{
              itemDispatch({
                type:DataGridActionTypes.ADD_DATA,
                payload:values
              })
            }
          }
        // }
      }
      (formikItem.dirty && !values.free_text_item)? setIsItem(true): setIsItem(false);
      setItemOrFreetext('item');
      setIsItemOrFreetext(false);
      resetItemForm();
    },
  });

  useEffect(()=>{
    if(isDeleteItem)
    {
      setSelectedRowIdItem([]);
      formikItem.resetForm();
      setIsDeleteItem(false);
    }
   },[isDeleteItem,formikItem])
   
  function CustomLoadingOverlay() {
    return (<Loader />);
  }

  const generateAndShowPdf = () => {
    if(invoiceByAddressSelectedId.length ===0){return;}
    setIsLoaderOverlay(true);
    const _formData = new FormData()
    _formData.append("id", JSON.stringify(invoiceByAddressSelectedId[0]));
    let object = createObject(_formData);
    let encryptedToken = encryptForm(object);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
    loadPdf(encrData)
      .then(res => {
        setIsLoaderOverlay(false);
        if (res.status) {
          setPDFData(res.data);
          setPDFModalOpen(true);
        }
      }).catch((error)=>{
        setIsLoaderOverlay(false);
        openAlertSnackBar('error','common_alert',marginTopValue);
      });
  }

  const loadInvoiceItems = useCallback((isMounted)=>{
    const controller4 = new AbortController();
    const signal4 = controller4.signal;
    const d = new FormData()
    let object = createObject(d);
    let encryptedToken = encryptForm(object);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
    getInvoiceAllItems(encrData, signal4)
      .then(res => {
        if(isMounted){
          if (res.status) {
            setItems(res.data);
          } 
      }
      }).catch(
        (err) => {
        }
      );
     return () => {
      controller4.abort();
     }
  },[])

  useEffect(()=>{
    let isMounted = true ;
    loadInvoiceItems(isMounted);
    return()=>{
      isMounted = false ;
    }
  },[loadInvoiceItems])

  const handleClear = () => {
    resetCreateInvoiceForm();
    setValue("1");
    setSelectedRowIds([]);
    setInvoiceTabValue('draft');
    setCompanyName("");
    setFooterValues(defaultFooter);
    setSelectItem(false);
    isSearchAddress.current = true ;
    isSearchAddressBydraft.current = true ;
    setSearchColumnAddress("");
    setSearchColumnAddressBydraft("");
    resetItemForm();
    isSearchModule.current = true ;
    setSearchColumnModule("");
    isSearchTemplate.current = true ;
    setSearchColumnTemplate("");
    isSearchItem.current = true ;
    setSearchColumnItem("");
    setNewScheduler(true);
    itemDispatch({
      type:DataGridActionTypes.ASSIGN_DATA,
      payload:[]
    })
    setStoredInvoice({});
    setCid(null);
    setIsNew(true);
    setIsItem(false);
    resetSchduler();
    setTaxValue(0.081);
    schedulerDispatch({
      type:DataGridActionTypes.ASSIGN_DATA,
      payload:[]
    });
  };
 
  const submitCreateInvoice = ()=>{
    setValue("1");
    formik.submitForm();
  }

  const handleClosePDFModal = () => {
    setPDFData(null);
    setPDFModalOpen(false);
  };




  const loadAllAddressData = useCallback(() => {
    const controllerLoadAddress = new AbortController();
    const signalLoadAddress = controllerLoadAddress.signal;
    const data = new FormData();
    setAllAddressData([]);
    let objectAddress = createObject(data);
    let encryptedTokenAddress = encryptForm(objectAddress);
    let encrDataAddress = new FormData();
    encrDataAddress.append("encrToken", encryptedTokenAddress);
    loadAllAddress(encrDataAddress, signalLoadAddress)
        .then((res) => {
            if (res.status) {
              setAllAddressData(res.data);
            } 
        })
        .catch((err) => {
        })
        .finally(() => {
          controllerLoadAddress.abort();
        });
    
    return () => {
      controllerLoadAddress.abort();
      loadAllAddress.Cancel();
      setIsMounted(false);
    };
  }, []);

  const handleNumber = (event)=>{
      const newValue = event.target.value;
      if (/^-?[0-9]*$/.test(newValue)) {
        formikScheduler.handleChange();
      }
    };

    useEffect(() => {
      const controllerCurrency = new AbortController();
      const signalCurrency = controllerCurrency.signal;
      const controllerBank = new AbortController();
      const signalBank = controllerBank.signal;
      const controllerQrEsr = new AbortController();
      const signalQrEsr = controllerQrEsr.signal;
      const controllerCondition = new AbortController();
      const signalCondition = controllerCondition.signal;
      const controllerLang = new AbortController();
      const signalLang = controllerLang.signal;
      const controllerCountry = new AbortController();
      const signalCountry = controllerCountry.signal;
      const controllerTax = new AbortController();
      const signalTax = controllerTax.signal;
      const controllerModules = new AbortController();
      const signalModules = controllerModules.signal;
      const controllerTemplate = new AbortController();
      const signalTemplate = controllerTemplate.signal;
      const controllerInvoiceType = new AbortController();
      const signalInvoiceType = controllerInvoiceType.signal;
      const controllerExpenseAccount = new AbortController();
      const signalExpenseAccount = controllerExpenseAccount.signal;
      const controllerIncomeAccount = new AbortController();
      const signalIncomeAccount = controllerIncomeAccount.signal;

      let incForm = new FormData();
      incForm.append("language", currentLanguage);
      let objectinc = createObject(incForm);
      let encryptedTokeninc = encryptForm(objectinc);
      let encrDatainc = new FormData();
      encrDatainc.append("encrToken", encryptedTokeninc);
      getIncomeAccounts(encrDatainc, signalIncomeAccount)
        .then((data) => {
          if (isMounted && data && data.status && data.data && data.data.length > 0) {
            setincomeAccount(data.data);
          }
        })
        .catch((err) => {})
        .finally(() => {
          controllerIncomeAccount.abort();
        });

      let expForm = new FormData();
      expForm.append("language", currentLanguage);
      let object = createObject(expForm);
      let encryptedToken = encryptForm(object);
      let encrData = new FormData();
      encrData.append("encrToken", encryptedToken);
      getExpenseAccounts(encrData, signalExpenseAccount)
        .then((data) => {
          if ( isMounted && data && data.status && data.data && data.data.length > 0) {
            setexpenseAccount(data.data);
          }
        })
        .catch((err) => {})
        .finally(() => {
          controllerExpenseAccount.abort();
        });

      getModules(signalModules)
        .then((data) => {
          if ( data && data.status && data.data && data.data.length > 0) {
            setModules(data.data);
          }
        })
        .catch((err) => {})
        .finally(() => {
          controllerModules.abort();
        });
  
      getTemplates(signalTemplate)
        .then((data) => {
          if ( data && data.status && data.data && data.data.length > 0) {
            setTemplates(data.data);
          }
        })
        .catch((err) => {})
        .finally(() => {
          controllerTemplate.abort();
        });
  
      getCurrency(signalCurrency)
        .then(currencyData => {
          if ( currencyData && currencyData.status && currencyData.data && currencyData.data.length > 0) {
          setCurrency(currencyData.data);
          }
        });
      getBank(signalBank)
        .then(bankData => {
          if ( bankData && bankData.status && bankData.data && bankData.data.length > 0) {
            const filterBank = bankData.data.filter(item => item.code !== null)
            setBank(filterBank);
          }
        });
      getQrEsr(signalQrEsr)
        .then((data) => {
          if (isMounted && data && data.status && data.data && data.data.length > 0) {
            setqrEsrIban(data.data);
          }
        })
        .catch((err) => {})
      getConditions(signalCondition)
        .then((data) => {
          if (isMounted && data && data.status && data.data && data.data.length > 0) {
            setConditions(data.data);
          }
        })
        .catch((err) => {})

      getTaxcode(signalTax)
        .then((data) => {
          if ( data && data.status && data.data && data.data.length > 0) {
            setAllTaxCode(data.data);
            settaxCode(data.data);
          }
        })
        .catch((err) => {})
        .finally(() => {
          controllerTax.abort();
        });
  
      getCountry(signalCountry)
        .then((data) => {
          if ( data && data.status && data.data && data.data.length > 0) {
            setCountry(data.data);
          }
        })
        .catch((err) => {})
        .finally(() => {
          controllerCountry.abort();
        });
  
      getLanguages(signalLang)
        .then((data) => {
          if ( data && data.status && data.data && data.data.length > 0) {
            setLanguage(data.data);
          }
        })
        .catch((err) => {})
        .finally(() => {
          controllerLang.abort();
        });
  
      getInvoiceType(signalInvoiceType)
        .then((data) => {
          if ( data && data.status && data.data && data.data.length > 0) {
            setInvoiceType(data.data);
          }
        })
        .catch((err) => {})
        .finally(() => {
          controllerInvoiceType.abort();
        });
        
  
      return () => {
        controllerCurrency.abort();
        controllerBank.abort();
        controllerLang.abort();
        controllerCountry.abort();
        controllerTax.abort();
        controllerModules.abort();
        controllerTemplate.abort();
        controllerInvoiceType.abort();
        controllerIncomeAccount.abort();
        controllerExpenseAccount.abort();
        controllerCondition.abort();
        controllerQrEsr.abort();
      };
    }, []);
    
    useEffect(() => {
      loadAllAddressData();
      return () => {
        setIsMounted(false);
        };
    }, [loadAllAddressData]);
  
    useEffect(() => {
      let isMounted = true;
      loadInvoiceList(isMounted);
      return () => {
        isMounted = false;
      };
    }, [loadInvoiceList]);
  return (
    <>
    <div >
      <CssBaseline />
      <Box sx={{ flexGrow: 1, margin: "10px 0 0 0" }} >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Grid item>
              <span className="commonCreateLabel">
                {t("common_create_invoice")}
              </span>
            </Grid>
            <Grid item xs={12}>
              <Item>
                <div className="actionSectionCreateInvoice">
                  <div className="commonActionItemCreateInvoice">
                    <IconButton onClick={handleClear}>
                      <AddOutlinedIcon  sx={{ fontSize: 30, color: "#8B9635" }} />
                    </IconButton>
                    <span>{t("common_new")}</span>
                  </div>
                  {invoiceTabValue === 'draft' ? (<div className="commonActionItemCreateInvoice" title={!isItem? t('item_required'):undefined}>
                    <IconButton onClick={submitCreateInvoice} disabled={!isItem} >
                      {isNew?
                        (isItem ?( <SaveSharpIcon sx={{ fontSize: 30, color: "#8B9635" }} />): <SaveSharpIcon sx={{ fontSize: 30, color: "#8F8C8C" }} />)
                        :( isItem ?(<EditIcon sx={{ fontSize: 30, color: '#8B9635' }} />):(<EditIcon sx={{ fontSize: 30, color: '#8F8C8C' }} />))}
                    </IconButton>
                    { isNew?(<span>{t("common_save")}</span>):(<span>{t("common_update")}</span>)}
                  </div>):null}
                  <div className="commonActionItemCreateInvoice">
                    <IconButton onClick={handleCancelInvoiceDate}>
                      <CloseSharpIcon sx={{ fontSize: 30, color: "#ff0000" }} />
                    </IconButton>
                    <span>{t("common_cancel")}</span>
                  </div>
                  <span  style={{border:'1px solid',borderColor:'#8B9635'}}></span>
                  <div className="commonActionItemCreateInvoice">
                    <IconButton onClick={handlePreviewInvoice}>
                      <RemoveRedEyeSharpIcon
                        sx={{ fontSize: 30, color: "#8B9635" }}
                      />
                    </IconButton>
                    <span>{t("common_preview")}</span>
                  </div>
                  {(selectItem)?
                    (<div className="commonActionItemCreateInvoice">
                      <IconButton onClick={sentInvoices}>
                        <NoteAddIcon sx={{ fontSize: 30, color: "#8B9635" }} />
                      </IconButton>
                      <span>{t("common_push_to_queue")}</span>
                    </div>):''
                  }
                  <Box
                      className="commonActionItemEnd"
                      display="flex"
                      justifyContent="flex-end"
                    >
                      {isLoaderOverlay && <CustomLoader />}
                  </Box>
                </div>
              </Item>
            </Grid>
              <CustomBoxLeft>
                <Grid item container xs={12}>
                    <Box sx={{ width: '100%' }}>
                      <TabContext value={value}>
                        <Grid item xs={12}>
                          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <CustomTabs onChange={handleChangeTabs} aria-label="lab API tabs example" 
                              TabIndicatorProps={{
                                style:{
                                  backgroundColor: '#8B9635',
                                }
                              }}
                              >
                              <Tab className='commoncreateinvoiceTab' icon={<ListIcon />} iconPosition="start" label={t('header')+'*'} title={t('header')} value="1" />
                              <Tab className='commoncreateinvoiceTab' icon={<FileCopyIcon />} iconPosition="start" label={t('items')+'*'} title={t('items')}value="2" />
                              <Tab className='commoncreateinvoiceTab' icon={<TodayRoundedIcon />} disabled={!isSchedulerShow} iconPosition="start" label={t('common_scheduler')} title={t('common_scheduler')} value="3" />
                              {(isNew)?
                                (<Box style={{ display: 'flex', marginLeft:'auto',marginRight:'10px' ,alignSelf:'center'}}>
                                    <span style={{ color: '#8B9635' }}>
                                      {t('template_new_mode')}
                                    </span>
                                  </Box>
                                ):(<Box style={{ display: 'flex',marginLeft:'auto',marginRight:'10px' ,alignSelf:'center'}}>
                                    <span style={{ color: '#8B9635' }}>
                                        {t('template_edit_mode')}
                                    </span>
                                  </Box>
                                )} 
                            </CustomTabs>
                          </Box>
                        </Grid>
                        <TabPanel value="1" >
                          <Grid item container xs={12} spacing={1}>
                            <Grid item container xs={12} spacing={1}>
                              <Grid item xs={12} sm={6}>
                                <Autocomplete
                                  disablePortal
                                  id="combo-box-demo"
                                  size="small"
                                  fullWidth
                                  value={formik.values.address||null}
                                  ListboxProps={{
                                    ref: companyListRef,
                                    onScroll: handleScrollAddress
                                  }}
                                  onChange={(event, value) => {
                                    handleAddressFieldChange(value);
                                  }}
                                  options={addressData}
                                  getOptionLabel={(option) => option?.companyname}
                                  // isOptionEqualToValue={(option, value) => option.companyname === value}
                                  renderOption={(props, option) => (
                                    <Box component="li" {...props} key={option?.cid}>
                                      { option?.companyname}
                                    </Box>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={t("common_company_name") + "*"}
                                      onChange={(event, newInputValue) => {
                                        isSearchAddress.current =true;
                                        setSearchColumnAddress(event.target.value);
                                      }}
                                      value={ searchColumnAddress}
                                      error={
                                        formik.touched.addressid &&
                                        Boolean(formik.errors.addressid)
                                      }
                                      helperText={
                                        formik.touched.addressid &&
                                        formik.errors.addressid
                                      }
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                  <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        size="small"
                                        fullWidth
                                        value={formik.values.template ||null}
                                        ListboxProps={{
                                          ref: companyListRef,
                                          onScroll: handleScrollTemplate
                                        }}
                                        onChange={(event, value) => {
                                          handleTemplateFieldChange(value);
                                        }}
                                        options={templateData[0]}
                                        renderOption={(props, option) => (
                                          <Box component="li" {...props} key={option?.cid}>
                                            {option?.name}
                                          </Box>
                                        )}
                                        getOptionLabel={(option) => option?.name}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label={t("common_template") + "*"}
                                            onChange={handleKeyDownTemplate}
                                            value={ searchColumnTemplate}
                                            onBlur={handleClearFieldTemplate}
                                            error={
                                              formik.touched.templateid &&
                                              Boolean(formik.errors.templateid)
                                            }
                                            helperText={
                                              formik.touched.templateid &&
                                              formik.errors.templateid
                                            }
                                        />
                                  )}
                                />
                              </Grid>
                            </Grid>
                            <Grid item container xs={12} spacing={1}>
                              <Grid item xs={12} sm={6}>
                                  <TextField
                                      id="invoicename"
                                      label={t("invoicename")}
                                      variant="outlined"
                                      fullWidth
                                      size="small"
                                      {...formik.getFieldProps("invoicename")}
                                    />
                              </Grid> 
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                      id="invoicetype"
                                      label={t("Belegart")}
                                      variant="outlined"
                                      fullWidth
                                      select
                                      size="small"
                                      {...formik.getFieldProps("invoicetype")}
                                    >
                                      {invoiceType?.map((option) => (
                                        <MenuItem key={option.id} value={option.code}>
                                          {option.name}
                                        </MenuItem>
                                    ))}
                                  </TextField>
                                </Grid>
                              </Grid>
                              <Grid item container xs={12} spacing={1}>
                              <Grid item  xs={6} >
                              <TextField
                                id="condition"
                                label={t('condition')+'*'}
                                variant="outlined"
                                select
                                fullWidth
                                size="small"
                                {...formik.getFieldProps("condition")}
                                error={
                                  formik.touched.condition &&
                                  Boolean(formik.errors.condition)
                                }
                                helperText={
                                  formik.touched.condition &&
                                  formik.errors.condition
                                }
                              >
                                {condition?.map((option) => (
                                  <MenuItem key={option.id} value={option.code}>
                                    {t(option.label)}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                              <Grid item xs={12} sm={6}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} size='small'>
                                  <DatePicker
                                    id="invoice_date"
                                    name="invoice_date"
                                    label={t('invoice_date')+'*'}
                                    format={currentLanguage === "de" ? "DD.MM.YYYY" : "MM/DD/YYYY"}
                                    variant="small"
                                    inputVariant="outlined"
                                    size="small"
                                    onChange={(value) => formik.setFieldValue("invoice_date", value, true)}
                                    value={formik.values.invoice_date}
                                    sx={{ width: "100%" }}
                                        slotProps={{
                                        textField: {
                                            size: 'small' ,
                                            variant: "outlined",
                                            error: formik.touched.invoice_date && Boolean(formik.errors.invoice_date),
                                            helperText: formik.touched.invoice_date && formik.errors.invoice_date
                                              }
                                          }}
                                  />
                              </LocalizationProvider>
                          </Grid>
                        </Grid>
                        <Grid item container xs={12} spacing={1}>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              id="our_reference"
                              label={t('common_our_reference')+'*'}
                              variant="outlined"
                              fullWidth
                              size="small"
                              {...formik.getFieldProps("our_reference")}
                              error={
                                formik.touched.our_reference &&
                                Boolean(formik.errors.our_reference)
                              }
                              helperText={
                                formik.touched.our_reference && formik.errors.our_reference
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              id="cust_reference"
                              label={t('common_custom_reference')+'*'}
                              variant="outlined"
                              fullWidth
                              size="small"
                              {...formik.getFieldProps("cust_reference")}
                              error={
                                formik.touched.cust_reference &&
                                Boolean(formik.errors.cust_reference)
                              }
                              helperText={
                                formik.touched.cust_reference &&
                                formik.errors.cust_reference
                              }
                            />
                          </Grid>
                        </Grid>
                        <Grid item container xs={12} spacing={1} >
                          <Grid item  xs={6} >
                            <TextField
                                    id="language"
                                    label={t("common_language")+'*'}
                                    variant="outlined"
                                    fullWidth
                                    select
                                    size="small"
                                    {...formik.getFieldProps("language")}
                                    error={
                                      formik.touched.language &&
                                      Boolean(formik.errors.language)
                                    }
                                    helperText={
                                      formik.touched.language && formik.errors.language
                                    }
                                  >
                                    {lang?.map((option) => (
                                      <MenuItem key={option.id} value={option.code}>
                                        {t(option.label)}
                                      </MenuItem>
                                    ))}
                            </TextField>
                          </Grid>
                          <Grid item  xs={6} >
                            <TextField
                                  id="accounting_no_or_project_no"
                                  label={t('accounting_no_or_project_no')}
                                  variant="outlined"
                                  fullWidth
                                  size="small"
                                  {...formik.getFieldProps("accounting_no_or_project_no")}
                              />
                          </Grid>
                        </Grid>
                        {/* <Grid item container xs={12} spacing={1} >
                          
                        </Grid> */}
                        <Grid item container xs={12} spacing={1} >
                          <Grid item  xs={6} >
                            <TextField
                                    id="qr_esr_iban"
                                    label={t("common_qr_esr_iban")+'*'}
                                    variant="outlined"
                                    fullWidth
                                    select
                                    size="small"
                                    {...formik.getFieldProps("qr_esr_iban")}
                                    error={
                                      formik.touched.qr_esr_iban &&
                                      Boolean(formik.errors.qr_esr_iban)
                                    }
                                    helperText={
                                      formik.touched.qr_esr_iban &&
                                      formik.errors.qr_esr_iban
                                    }
                                  >
                                    {qrEsrIban?.map((option) => (
                                      <MenuItem key={option.id} value={option.code}>
                                        {t(option.label)}
                                      </MenuItem>
                                    ))}
                            </TextField>
                          </Grid>
                          <Grid item  xs={6} >
                          <TextField
                                    id="bank"
                                    label={t("bank")+'*'}
                                    variant="outlined"
                                    fullWidth
                                    select
                                    size="small"
                                    {...formik.getFieldProps("bank")}
                                    error={
                                      formik.touched.bank &&
                                      Boolean(formik.errors.bank)
                                    }
                                    helperText={
                                      formik.touched.bank &&
                                      formik.errors.bank
                                    }
                                  >
                                    {qrEsrBank?.map((option) => (
                                      <MenuItem key={option.id} value={option.code}>
                                         {option?.bankConfig.bankName +' - '+option?.bankConfig.currency}
                                      </MenuItem>
                                    ))}
                            </TextField>
                            <p style={{color:"#8B9635",margin:'1px 0px 0px 13px'}}>{qrAccount}</p>
                          </Grid>
                        </Grid>
                        <Grid item container xs={12} spacing={1}>
                          <Grid item xs={12} sm={6}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                size="small"
                                value={formik.values.module||null}
                                ListboxProps={{
                                  ref: companyListRef,
                                  onScroll: handleScrollModule
                                }}
                                onChange={(event, value) => {
                                  handleModuleFieldChange(value);
                                }}
                                options={moduleData[0]}
                                renderOption={(props, option) => (
                                  <Box component="li" {...props} key={option?.id}>
                                    {option?.label}
                                  </Box>
                                )}
                                getOptionLabel={(option) => option?.label}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={t("common_module") + "*"}
                                    onChange={handleKeyDownModule}
                                    fullWidth
                                    value={ searchColumnModule}
                                    onBlur={handleClearFieldModule}
                                    error={
                                      formik.touched.moduleCode &&
                                      Boolean(formik.errors.moduleCode)
                                    }
                                    helperText={
                                      formik.touched.moduleCode &&
                                      formik.errors.moduleCode
                                    }
                                  />
                                )}
                              />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                                  id="currency"
                                  label={t('currency')+'*'}
                                  variant="outlined"
                                  fullWidth
                                  select
                                  size="small"
                                  {...formik.getFieldProps("currency")}
                                  error={
                                    formik.touched.currency &&
                                    Boolean(formik.errors.currency)
                                  }
                                  helperText={
                                    formik.touched.currency && formik.errors.currency
                                  }
                                >
                                  {currency?.map((option) => (
                                    <MenuItem key={option.id} value={option.code}>
                                      {option.code}
                                    </MenuItem>
                                  ))}
                            </TextField>
                          </Grid>
                        </Grid>
                            <Grid item container xs={12} spacing={1}>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                id="country"
                                label={t("common_country_code")+"*"}
                                variant="outlined"
                                className="countrycode-virtualScroller"
                                fullWidth
                                select
                                size="small"
                                {...formik.getFieldProps('country')}
                                error={
                                  formik.touched.country &&
                                  Boolean(formik.errors.country)
                                }
                                helperText={
                                  formik.touched.country && formik.errors.country
                                }
                                SelectProps={{
                                  MenuProps: {
                                    PaperProps: {
                                      style: {
                                        maxHeight: "300px",
                                      },
                                    },
                                  },
                                }}
                              >
                                {country?.map((option) => (
                                  <MenuItem key={option.id} value={option.code}>
                                    {option.label}
                                  </MenuItem>
                                ))}
                                </TextField>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  id="tax"
                                  label={t('tax_field')+'*'}
                                  variant="outlined"
                                  fullWidth
                                  name="tax"
                                  size="small"
                                  select
                                  value={formik.values.tax}
                                  onChange={(event, value) => {
                                    handleTemplateFieldChangeTax(event,value);
                                  }}
                                  error={
                                    formik.touched.tax &&
                                    Boolean(formik.errors.tax)
                                  }
                                  helperText={
                                    formik.touched.tax && formik.errors.tax
                                  }
                                >
                                  {allTaxCode.length >0 ? (allTaxCode?.map((option) => (
                                    <MenuItem key={option.id} value={option.code}>
                                      {option.label}
                                    </MenuItem>
                                  ))):(<MenuItem value="SSVR81"> Mwst. 8.1 exkl. </MenuItem>)}
                                </TextField>
                                </Grid>
                              </Grid>
                              <Grid item container xs={12} spacing={1}>
                              <Grid item xs={12} sm={6}>
                              <TextField
                                id="email"
                                label={t('email')+'*'}
                                variant="outlined"
                                fullWidth
                                size="small"
                                {...formik.getFieldProps("email")}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                              />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                    id="booking_text"
                                    label={t("booking_text")+'*'}
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    {...formik.getFieldProps("booking_text")}
                                    error={
                                      formik.touched.booking_text && Boolean(formik.errors.booking_text)
                                    }
                                    helperText={
                                      formik.touched.booking_text && formik.errors.booking_text
                                    }
                                  />
                             </Grid>
                             </Grid>
                             <Grid item container xs={12} spacing={1} sx={{alignItems:'center'}}>
                                <Grid item xs={12} xl={2.75} lg={4} sm={12}>
                                  <span>
                                    {t("verbuchung_buHa")}
                                  </span>
                                </Grid>
                                <Grid item xs={12} xl={4.50} lg={4}  sm={6}>
                                  <TextField
                                    id="debitaccount"
                                    label={t("common_debit_account")+'*'}
                                    variant="outlined"
                                    fullWidth
                                    select
                                    size="small"
                                    {...formik.getFieldProps("debitaccount")}
                                    error={
                                      formik.touched.debitaccount &&
                                      Boolean(formik.errors.debitaccount)
                                    }
                                    helperText={
                                      formik.touched.debitaccount &&
                                      formik.errors.debitaccount
                                    }
                                  >
                                    {incomeAccount.length > 0 ? incomeAccount?.map((option) => (
                                      <MenuItem key={option.id} value={option.code}>
                                        {option.accountid +" | " + option.label}
                                      </MenuItem>
                                    )): (<MenuItem value=""> </MenuItem>)}
                                  </TextField>
                                </Grid>
                                <Grid item xs={12} xl={4.75} lg={4}  sm={6}>
                                  <TextField
                                      id="incomeaccount"
                                      label={t("common_income_account")+'*'}
                                      variant="outlined"
                                      fullWidth
                                      select
                                      size="small"
                                      {...formik.getFieldProps("incomeaccount")}
                                      error={
                                        formik.touched.incomeaccount &&
                                        Boolean(formik.errors.incomeaccount)
                                      }
                                      helperText={
                                        formik.touched.incomeaccount &&
                                        formik.errors.incomeaccount
                                      }
                                    >
                                      {expenseAccount?.map((option) => (
                                        <MenuItem key={option.id} value={option.code}>
                                          {option.accountid +" | " + option.label}
                                        </MenuItem>
                                      ))}
                                  </TextField>
                                </Grid>
                              </Grid>
                             <Grid item container xs={12} spacing={1}>
                              <Grid item xs={12} sm={4}>
                                <TextField
                                  id="total_amountExclvat"
                                  label={t("total_amount_excl_vat")}
                                  variant="outlined"
                                  fullWidth
                                  disabled
                                  size="small"
                                  value={footer.total_amountExclvat}
                                />
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <TextField
                                  id="totalVat"
                                  label={t('total_vat')}
                                  variant="outlined"
                                  fullWidth
                                  disabled
                                  size="small"
                                  value={footer.totalVat}
                                />
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <TextField
                                  id="totalAmntInclVat"
                                  label={t('total_amount_incl_vat')}
                                  variant="outlined"
                                  fullWidth
                                  disabled
                                  size="small"
                                  value={footer.totalAmntInclVat}
                                />
                              </Grid>
                              </Grid>
                              <Grid item xs={12} >
                                <TextField
                                  id="header"
                                  label={t("free_text_header")}
                                  variant="outlined"
                                  fullWidth
                                  multiline
                                  rows={3}
                                  size="small"
                                  {...formik.getFieldProps("free_text_header")}
                                />
                              </Grid>
                              <Grid item xs={12} >
                                <TextField
                                  id="customerinformation"
                                  label={t("customer_information")}
                                  variant="outlined"
                                  fullWidth
                                  multiline
                                  rows={3}
                                  size="small"
                                  {...formik.getFieldProps("customer_information")}
                                />
                              </Grid>
                              <Grid item xs={12} >
                                <TextField
                                  id="footer"
                                  label={t("free_text_footer")}
                                  variant="outlined"
                                  fullWidth
                                  multiline
                                  rows={2}
                                  size="small"
                                  {...formik.getFieldProps("free_text_footer")}
                                />
                              </Grid>
                          </Grid>
                        </TabPanel>
                        <TabPanel value="2">
                          <Grid item container xs={12} spacing={1}>
                            <Grid item xs={12} >
                              <TextField
                                id="item_or_freetext"
                                label={t("item_or_freetext")}
                                variant="outlined"
                                fullWidth
                                size="small"
                                disabled={isitemOrFreetext}
                                select
                                value={itemOrFreetext}
                                onChange={handleChangeItemOrFreetext}
                              >
                                {(itemOrFreetextArray?.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                      {t(option.label)}
                                    </MenuItem>
                                  )))}
                              </TextField>
                            </Grid>
                            {itemOrFreetext !=='freetext'?
                            <>
                            <Grid item container xs={12} spacing={1}>
                              <Grid item xs={12} md={6}>
                                <Autocomplete
                                  disablePortal
                                  id="itemname"
                                  size="small"
                                  fullWidth
                                  value={ formikItem.values.item || null}
                                  ListboxProps={{
                                    ref: companyListRef,
                                    onScroll: handleScrollItem
                                  }}
                                  onChange={(event, value) => {
                                    handleFieldChangeItem (value)
                                  }}
                                  options={itemData[0]}
                                  renderOption={(props, option) => (
                                    <Box component="li" {...props} key={option?.cid}>
                                      {option?.title}
                                    </Box>
                                  )}
                                  getOptionLabel={(option) => option?.title}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={t("item_name") + "*"}
                                      onChange={handleKeyDownItem}
                                      value={ searchColumnItem}
                                      onBlur={handleClearFieldItem}
                                      error={
                                        formikItem.touched.description &&
                                        Boolean(formikItem.errors.description)
                                      }
                                      helperText={
                                        formikItem.touched.description &&
                                        formikItem.errors.description
                                      }
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                    id="income_account"
                                    label={t("ertragskonto")+'*'}
                                    variant="outlined"
                                    fullWidth
                                    disabled
                                    size="small"
                                    value={formikItem.values.income_account_name}
                                    onChange={formikItem.handleChange}
                                    {...formikItem.getFieldProps('income_account_name')}
                                    error={formikItem.touched.income_account_name && Boolean(formikItem.errors.income_account_name)}
                                    helperText={formikItem.touched.income_account_name && formikItem.errors.income_account_name}
                                  />
                            </Grid>
                            </Grid>
                            <Grid item container xs={12} spacing={1}>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  id="amount"
                                  label={t("common_amount")+'*'}
                                  variant="outlined"
                                  fullWidth
                                  size="small"
                                  value={
                                    formikItem.values.amount
                                  }
                                  onChange={handleAmountChange}
                                  error={formikItem.touched.amount && Boolean(formikItem.errors.amount)}
                                  helperText={formikItem.touched.amount && formikItem.errors.amount}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  id="price"
                                  label={t("common_price")+'*'}
                                  variant="outlined"
                                  fullWidth
                                  size="small"
                                  value={formikItem.values.price}
                                  onChange={handlePriceChange}
                                  error={formikItem.touched.price && Boolean(formikItem.errors.price)}
                                  helperText={formikItem.touched.price && formikItem.errors.price}
                                />
                              </Grid>
                            </Grid>
                            <Grid item container xs={12} spacing={1}>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  id="exchange_rate"
                                  label={t("Kurs")}
                                  variant="outlined"
                                  fullWidth
                                  size="small"
                                  disabled
                                  value={formikItem.values.exchange_rate}
                                  onChange={formikItem.handleChange}
                                  {...formikItem.getFieldProps('exchange_rate')}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                               <TextField
                                  id="total"
                                  label={t("common_total")+'*'}
                                  variant="outlined"
                                  fullWidth
                                  size="small"
                                  disabled
                                  value={formikItem.values.total}
                                  onChange={formikItem.handleChange}
                                  {...formikItem.getFieldProps('total')}
                                  error={formikItem.touched.total && Boolean(formikItem.errors.total)}
                                  helperText={formikItem.touched.total && formikItem.errors.total}
                                />
                              </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                               <TextField
                                  id="total"
                                  label={t("common_description")+'*'}
                                  variant="outlined"
                                  fullWidth
                                  size="small"
                                  value={formikItem.values.item_description}
                                  onChange={formikItem.handleChange}
                                  {...formikItem.getFieldProps('item_description')}
                                />
                              </Grid>
                            </>
                            :
                            <Grid item xs={12} >
                              <TextField
                                id="freetext"
                                label={t("freetext")}
                                variant="outlined"
                                fullWidth
                                size="small"
                                multiline
                                rows={5}
                                value={formikItem.values.free_text_item}
                                onChange={handleFreetext}
                                error={formikItem.touched.freetext && Boolean(formikItem.errors.freetext)}
                                helperText={formikItem.touched.freetext && formikItem.errors.freetext}
                              />
                          </Grid>
                          }
                            <Grid item container xs={12} spacing={1} justifyContent='flex-end'>
                              <Grid item container xs={9} spacing={1} justifyContent='flex-end'>
                                <Grid item xs={12} sm={2}>
                                  <CustomBtn onClick={resetItemForm} sx={{width:'100%'}}>
                                    {t('common_cancel')}
                                  </CustomBtn>
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                  <CustomBtn onClick={handleItemSubmit} sx={{width:'100%'}}>
                                    {(selectedRowIdItem&& selectedRowIdItem?.length > 0) ? t('common_save'):t('common_add')}
                                  </CustomBtn>
                                </Grid>
                              </Grid>
                            </Grid>
                              <Grid item xs={12}>
                                <div style={{ height: "469px", marginTop: "10px" }}>
                                  <CustomDataGrid
                                    rows={itemList}
                                    getRowId={(row) => itemList.indexOf(row)}
                                    columns={columnsItems}
                                    rowHeight={35}
                                    headerHeight={40}
                                    pagination
                                    rowsPerPageOptions={[10]}
                                    selectionModel={selectedRowIdItem}
                                    onSelectionModelChange={ handleSelectionChangeItem }
                                    paginationMode="server"
                                    paginationComponent={Pagination}
                                    rowCount={itemList.length}
                                    disableColumnMenu
                                    onCellClick={handleOnCellClick}
                                    components={{
                                      row: {
                                        'data-rowindex': (_, index) => index 
                                    },
                                      FilterPanel: CustomGridPanel,
                                      LoadingOverlay: CustomLoadingOverlay,
                                      NoRowsOverlay: CustomNoRowsOverlay,
                                    }}
                                  />
                                </div>
                            </Grid>
                          </Grid>
                        </TabPanel>
                        <TabPanel value='3'>
                          <Grid item container xs={12} spacing={1}>
                            <Grid item xs={12}>
                              <span style={{ color: "red",fontSize: '14px'}}>{'*'+t("scheduler_duedate_warning")}</span>
                            </Grid>
                            <Grid item container xs={12} spacing={1}>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  id="schdulername"
                                  label={t("common_name")+'*'}
                                  variant="outlined"
                                  fullWidth
                                  size="small"
                                  value={formikScheduler.values.name}
                                  onChange={formikScheduler.handleChange}
                                  {...formikScheduler.getFieldProps('name')}
                                  error={formikScheduler.touched.name && Boolean(formikScheduler.errors.name)}
                                  helperText={formikScheduler.touched.name && formikScheduler.errors.name}
                                  />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} size='small'>
                                  <DatePicker
                                    id="startDate"
                                    name="startDate"
                                    label={t('common_duedate')+'*'}
                                    format={currentLanguage === "de" ? "DD.MM.YYYY" : "MM/DD/YYYY"}
                                    variant="small"
                                    inputVariant="outlined"
                                    size="small"
                                    // minDate={ (newScheduler) ? dayjs() : schedulerStartDate}
                                    minDate={ dayjs() }
                                    value={formikScheduler.values.startdate}
                                    onChange=
                                    {
                                      (newValue) => {
                                        formikScheduler.setFieldValue('startdate', newValue);
                                        formikScheduler.touched.enddate = true;
                                      }
                                    }
                                    slotProps={{
                                      textField: { 
                                          size: 'small' ,
                                          variant: "outlined",
                                          error: (newScheduler) && formikScheduler.touched.startdate && Boolean(formikScheduler.errors.startdate),
                                          helperText:  formikScheduler.touched.startdate && formikScheduler.errors.startdate
                                          }
                                    }}
                                    onBlur={formikScheduler.handleBlur}
                                    renderInput={(params) => <TextField {...params} />}
                                    sx={{ width: "100%" }}
                                    />
                                </LocalizationProvider>
                              </Grid>
                            </Grid>
                            <Grid item container xs={12} sm={12} spacing={1}>
                              <Grid item container xs={12} sm={6} spacing={1}>
                                <Grid item xs={4} sm={3}>
                                  <TextField
                                    id="interval"
                                    label={t("common_interval")+'*'}
                                    variant="outlined"
                                    fullWidth
                                    className={classes.customNumberField}
                                    type="number"
                                    size="small"
                                    value={formikScheduler.values.interval}
                                    onChange={handleNumber}
                                    {...formikScheduler.getFieldProps('interval')}
                                    error={formikScheduler.touched.interval && Boolean(formikScheduler.errors.interval)}
                                    helperText={formikScheduler.touched.interval && formikScheduler.errors.interval}
                                  />
                                </Grid>
                                <Grid item xs={8} sm={9}>
                                  <TextField
                                    id="type"
                                    label={t("type")+'*'}
                                    variant="outlined"
                                    fullWidth
                                    select
                                    size="small"
                                    value={formikScheduler.values.type}
                                    onChange={formikScheduler.handleChange}
                                    {...formikScheduler.getFieldProps('type')}
                                    error={formikScheduler.touched.type && Boolean(formikScheduler.errors.type)}
                                    helperText={formikScheduler.touched.type && formikScheduler.errors.type}
                                  >
                                  {intervalTypes?.map((option) => (
                                    <MenuItem key={option.id} value={option.code} >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                  </TextField>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} size='small'>
                                  <DatePicker
                                    id="enddate"
                                    name="enddate"
                                    label={t('end_date')}
                                    format={currentLanguage === "de" ? "DD.MM.YYYY" : "MM/DD/YYYY"}
                                    variant="small"
                                    inputVariant="outlined"
                                    size="small"
                                    // minDate={(formikScheduler.values.startdate !== '')?
                                    //   formikScheduler.values.startdate.add(1, 'day'):
                                    //   dayjs().add(1, 'day')}
                                    minDate={dayjs().add(1, 'day')}
                                    value={formikScheduler.values.enddate}
                                    slotProps={{
                                      textField: {
                                          size: 'small' ,
                                          variant: "outlined",
                                          error: formikScheduler.touched.enddate && Boolean(formikScheduler.errors.enddate),
                                          helperText: formikScheduler.touched.enddate && formikScheduler.errors.enddate
                                          }
                                    }}
                                    onChange={(value) => formikScheduler.setFieldValue("enddate", value, true)}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                      <TextField {...params}
                                        error={formikScheduler.touched.enddate && Boolean(formikScheduler.errors.enddate)}
                                        helperText={formikScheduler.touched.enddate && formikScheduler.errors.enddate}
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              </Grid>
                          </Grid>
                          <Grid item container xs={12} justifyContent="flex-end" spacing={1}>
                            <Grid item xs={12} sm={2}>
                              <CustomBtn onClick={resetSchduler}  sx={{width:"100%"}}>
                                {t('common_cancel')}
                              </CustomBtn>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                              <CustomBtn onClick={formikScheduler.submitForm}  sx={{width:"100%"}}>
                                {newScheduler?t('common_add'):t('common_save')}
                              </CustomBtn>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <div style={{ height: "581px", marginTop: "10px" }}>
                              <CustomDataGrid
                                rows={schedulerState.data}
                                getRowId={(row) => schedulerState.data.indexOf(row)}
                                columns={columnsScheduler}
                                rowHeight={33}
                                headerHeight={40}
                                pagination
                                selectionModel={selectedRowIdScheduler}
                                onSelectionModelChange={handleSelectionChangeScheduler}
                                rowsPerPageOptions={[10]}
                                paginationMode="server"
                                paginationComponent={Pagination}
                                rowCount={schedulerState.data.length}
                                components={{
                                  NoRowsOverlay: CustomNoRowsOverlay,
                                }}
                              />
                            </div>
                          </Grid>
                          </Grid>
                        </TabPanel>
                      </TabContext>
                    </Box>
              </Grid>
            </CustomBoxLeft>
          </Grid>
          <Grid item xs={12} md={6}>
            <div style={{display:"flex",justifyContent:'space-between'}}>
              <span className="commonCreateLabel" >
                {t("common_all_invoice")}
              </span>
              <FormGroup>
                <FormControlLabel  control={<CustomCheckbox onChange={handleCheckBoxChange}/>} label={t("scheduler_draft")} sx={{ m: '0px 0 0px 0',p:'0px'}}/>
              </FormGroup>
            </div>
            <Item>
              <div className="actionSectionRight">
                <div className="commonactionRight">
                  <div className="commonActionItemCreateInvoice">
                    <IconButton onClick={handleCopyInvoiceData} disabled={(isNew && invoiceTabValue ==="draft") || (!isItem && invoiceTabValue ==="sent" && invoiceByAddressSelectedId.length === 0)}>
                      {(isNew && invoiceTabValue ==="draft") || (!isItem && invoiceTabValue ==="sent" && invoiceByAddressSelectedId.length === 0) ?(<ContentCopyIcon
                        sx={{ fontSize: 30, color: "#8F8C8C" }}
                      />):(<ContentCopyIcon
                        sx={{ fontSize: 30, color: "#8B9635" }}
                      />)}
                    </IconButton>
                    <span>{t("common_copy")}</span>
                  </div>
                  {invoiceTabValue === 'draft' ? <div className="commonActionItemCreateInvoice setMarginLeft">
                    <IconButton onClick={handleDelete}>
                      <DeleteIcon sx={{ fontSize: 30, color: "#8B9635" }} />
                    </IconButton>
                    <span>{t("common_delete")}</span>
                  </div>:null}
                </div>
                <div className="searchInvoiceDiv">
                  <TextField
                    label={t("common_search")}
                    id="searchInvoices"
                    variant="outlined"
                    margin="normal"
                    size="small"
                    onKeyDown={handleSearchKeyDown}
                    fullWidth
                    sx={{ m: 1, width: "100%" }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
            </Item>
            <CustomBoxLeft>
            <TabContext value={invoiceTabValue}>
              <Grid item xs={12}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <CustomTabs onChange={handleChangeInvoicesTabs} aria-label="lab API tabs example" 
                    TabIndicatorProps={{
                      style:{
                        backgroundColor: '#8B9635',
                      }
                    }}
                    >
                    <Tab className='commoncreateinvoiceTab' icon={<InsertDriveFileIcon />} iconPosition="start" label={t('common_draft_invoice')} title={t('common_draft_invoice')} value="draft" />
                    <Tab className='commoncreateinvoiceTab' icon={<FileCopyIcon />} iconPosition="start" label={t('common_sent_invoices')} title={t('common_sent_invoices')}value="sent" />
                  </CustomTabs>
                </Box>
              </Grid>
                        <TabPanel value="draft" >
                          <div style={{ height: '755px' }}>
                            <CustomDataGrid
                              rows={invoiceData}
                              getRowId={(row) => row.cid}
                              columns={columns}
                              pageSize={queueParams.limit}
                              onSortModelChange={handleSortModelChange}
                              selectionModel={selectedRowIds}
                              onPageChange={handlePageChange}
                              page={queueParams.page-1}
                              onSelectionModelChange={handleSelectionModelChange}
                              onPageSizeChange={handlePageSizeChange}
                              loading={tableLoader}
                              rowHeight={33}
                              headerHeight={40}
                              disableColumnMenu
                              rowsPerPageOptions={[10, 20]}
                              pagination
                              paginationMode="server"
                              paginationComponent={Pagination}
                              rowCount={invoiceListCount}
                              scrollbarSize={0}
                              localeText={localeText}
                              componentsProps={{
                                pagination: {
                                  labelRowsPerPage: t('common_rows_per_page'),
                                  }
                              }}
                              components={{
                                FilterPanel: CustomGridPanel,
                                LoadingOverlay: CustomLoadingOverlay,
                                NoRowsOverlay: CustomNoRowsOverlay,
                                
                              }}
                            />
                          </div>
                        </TabPanel>
                        <TabPanel value="sent" >
                        <Grid item container xs={12} spacing={1}>
                            <Grid item xs={12} sm={6}>
                              <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                size="small"
                                fullWidth
                                value={addressBydraftValue || null}
                                ListboxProps={{
                                  ref: companyListRef,
                                  onScroll: handleScrollAddressAddressByDraft
                                }}
                                onChange={(event, value) => {
                                  handleAddressByDraftFieldChange(value);
                                }}
                                options={addressDataByDraft}
                                getOptionLabel={(option) => option?.companyname}
                                // isOptionEqualToValue={(option, value) => option.companyname === value}
                                renderOption={(props, option) => (
                                  <Box component="li" {...props} key={option?.cid}>
                                    { option?.companyname}
                                  </Box>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={t("common_company_name")}
                                    onChange={(event, newInputValue) => {
                                      isSearchAddressBydraft.current =true;
                                      let value = event.target.value.trim();
                                      setSearchColumnAddressBydraft(value);
                                    }}
                                    onKeyDown={ (event, newInputValue) =>{
                                      if (event.key === "Enter") {
                                        if(!event.target.value){
                                          event.preventDefault();
                                          setListDraftByAddressParams((previousParams)=>({
                                            ...previousParams,
                                            search:'',
                                            page:1
                                          }))
                                        }
                                      }
                                     }
                                    }
                                    value={ searchColumnAddressBydraft}
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                          <div style={{ height: '755px', marginTop: "10px" }}>
                          <CustomDataGrid
                            rows={invoiceDataByAddress}
                            getRowId={(row) => row.cid}
                            columns={columnsDataByAddress}
                            pageSize={listDraftByAddressParams.limit}
                            onSortModelChange={handleSortModelChangeInvoiceByAddress}
                            selectionModel={invoiceByAddressSelectedId}
                            onPageChange={handlePageChangeInvoiceByAddress}
                            page={listDraftByAddressParams.page-1}
                            onSelectionModelChange={handleSelectionChangeListInvoiceByAddress}
                            onPageSizeChange={handlePageSizeChangeInvoiceByAddress}
                            loading={invoiceListByAddressTableLoader}
                            rowHeight={33}
                            headerHeight={40}
                            disableColumnMenu
                            rowsPerPageOptions={[10, 20]}
                            pagination
                            paginationMode="server"
                            paginationComponent={Pagination}
                            rowCount={invoiceListByAddressCount}
                            scrollbarSize={0}
                            localeText={localeText}
                            componentsProps={{
                              pagination: {
                                labelRowsPerPage: t('common_rows_per_page'),
                                }
                            }}
                            components={{
                              FilterPanel: CustomGridPanel,
                              LoadingOverlay: CustomLoadingOverlay,
                              NoRowsOverlay: CustomNoRowsOverlay,
                              
                            }}
                          />
                          </div>
                        </TabPanel>
            </TabContext>
            </CustomBoxLeft>
          </Grid>
        </Grid>
      </Box>
    </div>
      
      <BootstrapDialog
        onClose={handleClosePDFModal}
        aria-labelledby="customized-dialog-title"
        open={pdfModalOpen}
        maxWidth="lg"
        fullWidth={true}
        scroll="paper"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClosePDFModal}
        >
          {t("common_preview")}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <iframe
            title="PDF Viewer"
            src={`data:application/pdf;base64,${pdfData}`}
            width="100%"
            height="750px"
          ></iframe>
        </DialogContent>
      </BootstrapDialog>
      <CustomModal
        openModal={openDeleteModal}
        onclose={handleCloseDeleteModal}
        header={t("common_delete")}
        content={DeleteModalContent}
      />


        <CustomModal
          openModal={openErrorModal}
          onclose={updatehandleClose}
          header={'Invoice'}
          content={ErrorModalContent}
        
        />
        
        <Modal
            open={openTemplateConfirmModal}
            onClose={handleCloseTemplateConfirmModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
             <CustomBox>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <IconButton onClick={handleRejectChangeTemplate}>
                  <CloseIcon sx={{ color: "#8B9635" }} />
                </IconButton>
              </div>
              <div style={{ textAlign: "start", padding: "0px 25px 20px 25px" }}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  {t('common_changes_in_template')} 
                </Typography>
                 <Grid container sx={{mt:2}}>
                    <Grid item xs={12}>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell align="left">{t("oldvalue")}</TableCell>
                            <TableCell align="left">{t("newvalue")}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {templateDetails.map((row) => (
                            <>
                            <CutomTableRow
                              key={row.name}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell component="th" scope="row" sx={{ 'fontWeight':600 ,minWidth : '140px'}}>
                                {row.name}
                              </TableCell>
                              <TableCell align="left" width="45%" >{row.oldValue}</TableCell>
                              <TableCell align="left" width="45%" >{row.newValue}</TableCell>
                            </CutomTableRow>
                          </>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    </Grid>
                    <Grid item container xs={12} justifyContent="flex-end" spacing={1}>
                      <Grid item xs={3}>
                        <CustomBtn onClick={handleRejectChangeTemplate} sx={{ mt: 2 }}>
                          {t('common_cancel')}
                        </CustomBtn>
                      </Grid>
                      <Grid item xs={3}>
                        <CustomBtn  onClick={handleAcceptChangeTemplate} sx={{ mt: 2 }}>
                            {t('common_confirm')}
                        </CustomBtn> 
                      </Grid>
                    </Grid>
                 </Grid>
              </div>
            </CustomBox>
          </Modal>
    </>
  );
}

export default Createinvoice;
