import React, { useCallback, useReducer, useRef } from "react";
import "../dashboard/dashboard.scss";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { Autocomplete, Grid, Pagination } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tab from "@mui/material/Tab";
import debounce from 'lodash.debounce'
import PropTypes from "prop-types";
// import MuiAlert from '@mui/material/Alert';
import { Tabs } from "@mui/material";
// import Snackbar from '@mui/material/Snackbar';
import { openAlertSnackBar,closeAlertSnackBar } from "../../shared/common/utilites/notification/snackbar";
import Button from "@mui/material/Button";
import IconButton from '@mui/material/IconButton';
// import Slide from '@mui/material/Slide';
import CachedIcon from '@mui/icons-material/Cached';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";
import { useFormik } from "formik";
import { GridFilterPanel } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { gate2BUI, listInvoices, loadAddress, loadPdf } from "../../services/Services";
import { useTranslation } from "react-i18next";
import Loader from "../../shared/common/utilites/PageLoader/PageLoader";
import { createObject, encryptForm } from '../../services/commonService';
import { useTheme } from "@emotion/react";
import { DataGridActionTypes, DataGridReducer } from "../../reducer/datagridReducer";

const CustomDataGrid = styled(DataGrid)(({ theme }) => ({
  "& ::-webkit-scrollbar": {
    width: "6px",
    height:"6px"
  },
  "& ::-webkit-scrollbar-track": {
    // backgroundColor: "#f5f5f5"
    backgroundColor: "#0000"
  },
  "& ::-webkit-scrollbar-thumb": {
    borderRadius: "10px",
    boxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
    backgroundColor: "#f5f5f5"
  },
  border:
    theme.palette.mode === "light"
      ? "1px solis #ccc"
      : "1px solid rgba(255, 255, 255, 0.12)",
  "& .css-1bf0mkr-MuiButtonBase-root-MuiCheckbox-root.Mui-checked": {
    color: "#8B9635",
  },
  "& .MuiDataGrid-cell:focus": {
    outline: "unset",
  },
  "& .MuiDataGrid-cell:focus-within": {
    outline: "unset",
  },
  "& .MuiDataGrid-row.Mui-selected": {
    backgroundColor: "#8B9635",
    color: theme.palette.mode === "light" ? "#fff" : "#000",
    "& .css-1bf0mkr-MuiButtonBase-root-MuiCheckbox-root.Mui-checked": {
      color: "#fff",
    },
    "&:hover": {
      backgroundColor: "#8B9635",
    },
  },
  "& .MuiDataGrid-footerContainer": {
    borderTop: "unset",
  },
  "& .MuiDataGrid-columnHeader:focus": {
    outline: "none",
    border: 'none',
  },
  "& .MuiSvgIcon-root .MuiSvgIcon-fontSizeSmall": {
    display: 'none',
  },
}));

// const CustomSnackbar = styled(Snackbar)(({ theme }) => ({
//   width: '227px',
//   '&.MuiSnackbar-root': {
//     top: '134px',
//   },
// }));


const CustomDiv = styled("div")(({ theme }) => ({
  display: "",
  flex: "0 0 100%",
  borderRadius: "10px",

  border: theme.palette.mode === "light" ? "1px solid #ccc" : "1px solid #333",
}));

const CustomTabs = styled(Tabs)(({ theme }) => ({
  "& .commonqueueTab": {
    textTransform: "none",
    minHeight: '36px',
  },
  '&.MuiTabs-root': {
    minHeight: '0px',
  },
  "& .css-azyy17": {
    textTransform: "none",
  },
  "& .MuiButtonBase-root.Mui-selected": {
    color: theme.palette.mode === "light" ? "#000" : "#ffff",
  },
}));

const CustomGridPanel = styled(GridFilterPanel)(({ theme }) => ({
  "& .MuiFormLabel-root.Mui-focused": {
    color: theme.palette.mode === "light" ? "#000" : "#fff",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: theme.palette.mode === "light" ? "#000" : "#fff",
  },
}));

const CustomBtn = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '20px',
  background: 'unset',
  border: '2px solid #8B9635',
  // padding: '0px 10px',
  // margin: '10px 0 10px 0px',
  width:'100%',
  padding: "0px 16px 0px 16px",
  color: theme.palette.mode === 'light' ? '#000' : '#fff',
  "&:hover": {
    backgroundColor: "#8B9635",
    color: theme.palette.mode === "light" ? "#fff" : "#000",
  },
  // '& .css-151k6oy-MuiButtonBase-root-MuiButton-root': {
  //   margin: '0px 10px 0px 0px',
  // },
  "&.MuiLoadingButton-root.Mui-disabled": {
    background: theme.palette.mode === "light" ? "#fff" : "#000",
    padding: "0px 0px",
    span: {
      color: "#8B9635",
    },
  },
}));


const initialValues = {
  invoice_date: null,
  invoice_number: "",
  address_id:"",
  address: "",
  due_date: null,
};

function CustomLoadingOverlay() {
  return <Loader />;
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ height: '100%' }}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <span>{children}</span>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const defaultDashboardParams = { invoice_number: '', invoice_date: '', address: '', due_date: '', page: 1, sort: '', column: '', limit: 20 }
const marginTopValue ='131px';
const defaultAddressParams = { 
  page: 1, 
};

const schedulerInitialState ={
  data:[],
}

function Dashboard() {
  const [isLoading, setIsLoading] = useState(true);
  // const [selectedDate, setSelectedDate] = useState(null);
  const [setDashboardItem, setDashboardData] = useState(([]));
  const { t, i18n } = useTranslation(["language", "common"]);
  // const [page, setPage] = useState(1);
  const [value, setValue] = useState(0);
  // const [pageSize, setPageSize] = useState(20);
  const [selectedRow, setSelectedRow] = useState([]);
  // const [pushAlert, setAlert] = useState('info');
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [cid, setCid] = useState(null);
  // const [isPushed, setPushValue] = useState('');
  const [pdfLoader, setPdfLoader] = useState(false);
  const [dashboardParams, setDashboardParams] = useState(defaultDashboardParams);
  // const [open, setOpen] = useState(false);
  // const [transition, setTransition] = useState(undefined);
  const [pdfData, setPDFData] = useState(null);
  // const [csort, setCsort] = useState("");
  const [tableLoader, setTableLoader] = useState(false);
  const [isLastLoading, setIsLastLoading] = useState(false);
  // const [columnValue, setColumn] = useState("");
  // const [isApiCalled, setIsApiCalled] = useState(false);
  const [queueCount, setQueueCount] = useState(0);
  // const [currentLanguage, setCurrentLanguage] = useState("de");
  const [lastUpdatedTime, setlastUpdatedTime] = useState("");
  // const [sortModel, setSortModel] = useState(defaultSortModel);
  const [addressData, setAddressData] = useState([]);
  const [searchColumnAddress, setSearchColumnAddress] = useState("");
  const companyListRef = useRef(null);
  const [addressOptionParams, setAddressOptionParams] = useState(defaultAddressParams);
  const [addressOption, addressOptionDispatch] = useReducer(DataGridReducer, schedulerInitialState);
 
  const isSearchAddress = useRef(true);

  useEffect(()=>{
    const flattenedArray = [].concat(...addressOption.data);
    setAddressData([flattenedArray]);
  },[addressOption]);

  const handleScrollAddress = (event) => {
    const { target } = event;
    const lastEntryIndex = addressData[0].length - 10; 
    const count = Math.ceil((addressData[0].length)/100); 

    if (
      target.scrollTop + target.clientHeight >=
      target.scrollHeight - target.children[lastEntryIndex].clientHeight
    ) {
      isSearchAddress.current = false;
      debouncedFilterScroll(count+1);
    }
  };

  const debouncedFilterScroll = debounce((query) =>
    setAddressOptionParams({page:query})
  , 300);

  const loadAddressData = useCallback(() => {
    const controller4 = new AbortController();
    const signal4 = controller4.signal;
    const d = new FormData();
    d.append("limit", 100);
    d.append("page", isSearchAddress.current ? 1 : addressOptionParams.page);
    d.append("column", "companyname");
    d.append("order", "asc");
    d.append("search", searchColumnAddress);
    d.append("iscompanyname", true);
  //d.append("isAuthUser", '');
    // setAddressData([]);
    let object = createObject(d);
    let encryptedToken = encryptForm(object);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
    // const getTemplateData = setTimeout(() => {
       loadAddress(encrData, signal4)
        .then((res) => {
            if (res.status) {
              // setAddressData(res.data);
              if(isSearchAddress.current)
                {
                  addressOptionDispatch({
                    type:DataGridActionTypes.ASSIGN_DATA,
                    payload:res.data
                  })
                }else{
                  addressOptionDispatch({
                    type:DataGridActionTypes.APPEND_DATA,
                    payload:res.data
                  })
                }
            } else {
              openAlertSnackBar('error',res.message,'127px');
            }
        })
        .catch((err) => {
          openAlertSnackBar('error','common_alert','127px')
        })
    // }, 1000);

    return () => {
      controller4.abort();
      // clearTimeout(getTemplateData);
      // setIsMounted(false);
    };
  }, [searchColumnAddress,addressOptionParams]);

  useEffect(() => {
    loadAddressData();
  }, [loadAddressData]);

  const handleAddressChange = (event)=>{
    isSearchAddress.current = true ;
    if(!event)
    {
      setSearchColumnAddress("");
      formik.setFieldValue("address", '');
      formik.setFieldValue("address_id", '');
      return;
    }
    const addressItem =addressData[0].filter(item=>item.cid ===event.cid)
    formik.setFieldValue("address", addressItem.length>0 ?addressItem[0].companyname:'');
    setSearchColumnAddress(addressItem[0].companyname);
    formik.setFieldValue("address_id",event);
  }

  const currentLanguage = i18n.language;
  const theme = useTheme();
  const handleSelectionModelChange = (newSelection) => {
    setValue(0);
    setSelectedRowIds(newSelection);
    const filteredObject = setDashboardItem.filter(
      (obj) => obj.BuchungNr === newSelection[newSelection.length - 1]
    )
    setPDFData(null);
    if (filteredObject.length > 0) {
      setSelectedRow(filteredObject[0]);
      const cidValue = filteredObject[0].cid ? filteredObject[0].cid : '';
      setCid(cidValue);
    } else {
      setSelectedRow([]);
    }
  };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  // useEffect(() => {
  //   setCurrentLanguage(i18n.language);
  // }, [i18n.language]);

  // const Alert = React.forwardRef(function Alert(props, ref) {
  //   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  // });

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      let invoiceDateVar = null;
      let dueDateVar = null;
      if (values.invoice_date !== null) {
        invoiceDateVar = DateConverter(values.invoice_date);
      }
      if (values.due_date !== null) {
        dueDateVar = DateConverter(values.due_date);
      }
      setDashboardParams((previousSearch) =>
      ({
        ...previousSearch,
        invoice_number: values.invoice_number,
        invoice_date: invoiceDateVar,
        address: values.address,
        due_date: dueDateVar,
        page: 1
      }));
    },
  });

  const formattedAmount = (amount) => {
    return amount.toLocaleString('de-CH', {
      useGrouping: true,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  }

  const DateConverter = (dateForm) => {
    let formattedDate = ''
    if (dateForm !== null) {
      const date = dateForm.$d;
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      formattedDate = `${year}-${month}-${day}`;
    }
    return formattedDate;
  }

  const handleSortModelChange = (newModel) => {
    // setSortModel(newModel);
    if (newModel.length > 0) {
      // setColumn(newModel[0]?.field);
      // setCsort(newModel[0]?.sort);
      setDashboardParams((previousSearch) => ({
        ...previousSearch,
        sort: newModel[0].sort,
        column: newModel[0].field,
        page: 1
      }))
    } else {
      setDashboardParams((previousSearch) => ({
        ...previousSearch,
        sort: "",
        column: "",
        page: 1
      }))
    }
  };

  const getPdfData = useCallback(() => {
    setPdfLoader(true);
    setPDFData(null);
    const _formData = new FormData();
    _formData.append("id", JSON.stringify(cid));
    // _formData.append("isAuthUser", '');
    let object = createObject(_formData);
    let encryptedToken = encryptForm(object);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
    loadPdf(encrData)
      .then(res => {
        setPdfLoader(false);
        if (res.status) {
          setPDFData(res.data);
        }
      });
  }, [cid])

  const localeText = {
    noRowsLabel: t('common_no_rows'),
    footerRowSelected: (count) => `${count} ${t('common_row_selected')}`,
  };

  function CustomNoRowsOverlay() {
    const { t } = useTranslation(["language", "common"]);
    return (
      <GridOverlay>
        <Typography>{t("common_no_data_available")}</Typography>
      </GridOverlay>
    );
  }

  const lastSelectedRowIndex =
    selectedRowIds.length > 0
      ? selectedRowIds[selectedRowIds.length - 1]
      : null;

  const getRowClassName = useCallback(
    (params) => {
      if (lastSelectedRowIndex !== null && params.id === lastSelectedRowIndex) {
        return "commonSelected";
      }
      if (lastSelectedRowIndex === null) {
        return "commonSelected";
      }
      return "";
    },
    [lastSelectedRowIndex]
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
    (newValue === 1) ? (cid && getPdfData()) : setPDFData(null);
  };

  const cancelSearch = () => {
    formik.resetForm();
    setDashboardParams(defaultDashboardParams);
  };

  const handlePageChange = (event, newPage) => {
    // setSortModel(defaultSortModel);
    // setPage(event + 1);
    setDashboardParams((previousSearch) => ({ ...previousSearch, page: event + 1 }))
  };

  const handlePageSizeChange = (event) => {
    // setPageSize(event);
    setDashboardParams((previousSearch) => ({
      ...previousSearch,
      limit: event,
      page: 1
    }));
  };

  // function TransitionUp(props) {
  //   return <Slide {...props} direction="left" />;
  // }

  const formateD = useCallback((data) => {
    if (currentLanguage === "en") {
      return new Date(data).toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    } else {
      return new Date(data).toLocaleDateString("de-DE", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    }
  }, [currentLanguage]);

  const columns = [
    { field: "Belegnummer", headerName: t("Belegnummer"), flex: 1 },
    {
      field: "Belegdatum",
      headerName: t("Belegdatum"),
      flex: 1,
      valueFormatter: (params) => formateD(params.value)
    },
    {
      field: "Kunde", headerName: t("customer"),
      flex: 2
    },
    {
      field: "WaehrungNr",
      headerName: t("WaehrungNr"),
      flex: 1,
      sortable: false,
    },
    {
      field: "BetragFW",
      headerName: t("BetragFW"),
      flex: 1,
      valueFormatter: (params) => formattedAmount(parseFloat(params.value)),
      sortable: false,
    },
    {
      field: "Verfalldatum",
      headerName: t("Verfalldatum"),
      flex: 1,
      valueFormatter: (params) => formateD(params.value)
      // currentLanguage === "en"
      //   ? (params) =>
      //     params.value !== '' ? new Date(params.value).toLocaleDateString("en-US", {
      //       year: "numeric",
      //       month: "2-digit",
      //       day: "2-digit",
      //     })
      //       : params.value

      //   : (params) =>
      //     params.value !== '' ? new Date(params.value).toLocaleDateString("de-DE", {
      //       year: "numeric",
      //       month: "2-digit",
      //       day: "2-digit",
      //     })
      //       : params.value
    },
    {
      field: "module",
      headerName: t("common_module"),
      flex: 1,
      sortable: false,
    },
    {
      field: "sentvia",
      headerName: t("Send Via"),
      flex: 1,
      sortable: false,
    }
  ];

  // const getAlert = useCallback((type, message) => {
  //   setTransition(() => TransitionUp);
  //   setOpen(true);
  //   setAlert(type);
  //   if ((message === '') || (message === undefined)) return setPushValue('common_alert');
  //   setPushValue(message);
  // }, [])

  const formatDate = useCallback((data) => {
    if (currentLanguage === "en") {
      return new Date(data).toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      })
    } else {
      return new Date(data).toLocaleDateString('de-DE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      })
    }
  }, [currentLanguage])

  const loadInvoiceList = useCallback(
    (isMounted) => {
      let obj = {
        invoice_number: dashboardParams?.invoice_number,
        companyname: dashboardParams?.address,
        invoice_date: dashboardParams?.invoice_date,
        due_date: dashboardParams?.due_date,
        column: dashboardParams?.column,
        page: dashboardParams?.page,
        sorting: dashboardParams?.sort,
        limit: dashboardParams.limit,
      };
      setValue(0);
      const d = new FormData();
      d.append("message", JSON.stringify(obj));
      // d.append("isAuthUser", '');
      let object = createObject(d);
      let encryptedToken = encryptForm(object);
      // setCurrentLanguage(i18n.language);
      setTableLoader(true);
      setIsLoading(false);
      setDashboardData([]);
      let encrData = new FormData();
      const lastUpdated = localStorage.getItem("LastUpdated");
      if (lastUpdated) {
        setlastUpdatedTime(lastUpdated);
        setIsLastLoading(true);
      }
      encrData.append("encrToken", encryptedToken);
      listInvoices(encrData)
        .then((data) => {
          if (isMounted) {
            setTableLoader(false);
            if (data.status) {
              if (data.data.length > 0) {
                setDashboardData(data.data);
                setSelectedRow(data.data[0]);
                setSelectedRowIds([data.data[0].BuchungNr]);
                setQueueCount(Number(data.total));
                setCid(data.data[0].cid);
              } else {
                setQueueCount(0);
              }
            }
            else {
              openAlertSnackBar('error',data.message,marginTopValue);
            }
          }
        })
        .catch((err) => {
          setTableLoader(false);
          openAlertSnackBar('error','common_alert',marginTopValue);
        });
    },
    [dashboardParams]
  );

  const loadGate2bUI = useCallback(() => {
    const controllerLoadGate2b = new AbortController();
    const signalLoadGate2b = controllerLoadGate2b.signal;
    setTableLoader(true);
    setIsLoading(false);
    setDashboardData([]);
    const d = new FormData();
    // d.append("isAuthUser", '');
    let object = createObject(d);
    let encryptedToken = encryptForm(object);
    let encrData = new FormData();
    setIsLastLoading(false);
    encrData.append("encrToken", encryptedToken);
    gate2BUI(encrData, signalLoadGate2b)
      .then(res => {
        setTableLoader(false);
        if (res.status) {
          loadInvoiceList(true);
          const now = new Date();
          setlastUpdatedTime(now);
          localStorage.setItem("LastUpdated", now);
          setIsLastLoading(true);
        } else {
          setTableLoader(false);
          openAlertSnackBar('error',res.message,marginTopValue);
        }
      }).catch(
        (err) => {
          setIsLastLoading(false);
          setTableLoader(false);
          openAlertSnackBar('error','common_alert',marginTopValue);
        }
      );

    return () => {
      controllerLoadGate2b.abort();
    }
  }, [loadInvoiceList]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        formik.submitForm();
      }
    };
    document.addEventListener('keypress', handleKeyPress);
    return () => {
      document.removeEventListener('keypress', handleKeyPress);
    };
  }, []);
  // const handleChangeSearchField =(event)=>{
  //   if (event.key === "Enter") {
  //     formik.submitForm();
  //   }
  // }

  useEffect(() => {
    let isMounted = true;
    loadInvoiceList(isMounted);
    return () => {
      isMounted = false;
      closeAlertSnackBar();
    };
  }, [loadInvoiceList]);

  // useEffect(() => {
  //   setCurrentLanguage(i18n.language);
  // }, [i18n.language]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Grid container sx={{ margin: "10px 0 10px 0"}} spacing={1}>
          {/* <Grid
            sx={{
              display:'flex',
              flex: "0 0 100%",
              flexDirection:  'row',
              [theme.breakpoints.up('md')]: {
                flexDirection:'row',
                justifyContent: 'space-between'
              },
              marginBottom: "0px",
              margin: "10px 0 10px 0"
            }}
          > */}
            <Grid container item xs={12} spacing={1}>
              <Grid item xs={12} md={12} lg={4}>
                <Grid 
                  container 
                  sx={{
                    alignItems:'center',
                    [theme.breakpoints.up('sm')]: {
                        flexDirection:'row'
                    }
                    }}>
                     <Grid item >
                      <Typography
                          variant="h6"
                          noWrap
                          component="span"
                          sx={{ display: "flex", alignItems: "center", flex: "0 0 35%" }}
                        >
                          {t('liste_offene_debitoren')}
                          <IconButton sx={{ fontSize: '1em', margin: '0px 0px 0px 10px' }} onClick={loadGate2bUI}><CachedIcon sx={{ color: '#8B9635' }} /></IconButton>
                        </Typography>
                     </Grid>
                     <Grid item>
                       {isLastLoading && 
                          (<span className="lastUpdatedTimeLbl">{t('queue_last_updatedTime')}:{formatDate(lastUpdatedTime)}</span>)}
                     </Grid>
                   </Grid>
              </Grid>
              <Grid item xs={12}  md={12}  lg={8}>
                <Grid container spacing={1} justifyContent='flex-end' alignItems='center'>
                  <Grid item xs={12} sm={3} md={2.4}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      size="small"
                      fullWidth
                      sx={{  width: "100%" }}
                      value={formik.values.address_id||null}
                      isOptionEqualToValue={(option, value) => option.cid === value.cid}
                      ListboxProps={{
                        ref: companyListRef,
                        onScroll: handleScrollAddress,
                        className: "autocompleteList",
                        style:{maxHeight: '225px'}
                      }}
                      onChange={(event, value) => {
                        handleAddressChange(value);
                      }}
                      inputValue={searchColumnAddress}
                      onInputChange={(event, newInputValue) => {
                        isSearchAddress.current =true;
                        setSearchColumnAddress(newInputValue);
                      }}
                      options={addressData[0]}
                      getOptionLabel={(option) => option?.companyname}
                      renderOption={(props, option) => (
                        <Box component="li" {...props} key={option?.cid}>
                          {option?.companyname}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("common_company_name") }
                        />
                      )}
                    />
                    {/* <TextField
                      id="address"
                      label={t('company_name')}
                      name="address"
                      variant="outlined"
                      margin="normal"
                      size="small"
                      value={formik.values.address}
                      onChange={formik.handleChange}
                      fullWidth
                      sx={{ width: "100%" ,margin: '0px'}}
                    /> */}
                  </Grid>
                  <Grid item xs={12} sm={3} md={2.4}>
                    <TextField
                      id="invoice_number"
                      label={t('invoice_number')}
                      name="invoice_number"
                      variant="outlined"
                      margin="normal"
                      size="small"
                      value={formik.values.invoice_number}
                      onChange={formik.handleChange}
                      fullWidth
                      sx={{  width: "100%" ,margin: '0px'}}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={2.4}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} size='small'>
                      <DatePicker
                        id="invoice_date"
                        name="invoice_date"
                        label={t('invoice_date')}
                        format={currentLanguage === "de" ? "DD.MM.YYYY" : "MM/DD/YYYY"}
                        variant="small"
                        inputVariant="outlined"
                        size="small"
                        error={false}
                        slotProps={{ textField: { size: 'small' } }}
                        // onKeyDown={handleChangeSearchField}
                        // onKeyDown={(event) => {
                        //   if (event.keyCode === 8) {
                        //     // setSelectedDate(null);
                        //     formik.setFieldValue('invoice_date', null);
                        //   }
                        // }
                        // }
                        value={formik.values.invoice_date}
                        onChange=
                        {
                          (newValue) => {
                            formik.setFieldValue('invoice_date', newValue);
                            // setSelectedDate(newValue);
                          }
                        }
                        sx={{ width: "100%" }}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={3} md={2.4}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        id="due_date"
                        name="due_date"
                        label={t('Verfalldatum')}
                        size="small"
                        value={formik.values.due_date}
                        error={false}
                        slotProps={{ textField: { size: 'small' } }}
                        format={currentLanguage === "de" ? "DD.MM.YYYY" : "MM/DD/YYYY"}
                        onChange=
                        {
                          (newValue) => {
                            formik.setFieldValue('due_date', newValue)
                          }
                        }
                        sx={{ width: "100%" }}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item container xs={12} md={2.4} spacing={1} justifyContent="flex-end">
                    <Grid item xs={12} sm={2} md={7}>
                      <CustomBtn
                        type="reset"
                        variant="contained"
                        onClick={cancelSearch}
                        size="small"
                        severity="sucess"
                      >
                        {t("common_cancel")}
                      </CustomBtn>
                    </Grid>
                    <Grid item xs={12}sm={2} md={5} >
                      <CustomBtn
                        onClick={formik.handleSubmit}
                        variant="contained"
                        size="small"
                        severity="sucess"
                        sx={{ alignSelf: "center" }}
                      >
                        {t("common_search")}
                      </CustomBtn>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
        
            </Grid>
            
          {/* </Grid> */}
          <Grid
            item 
            container
            spacing={2}
            xs={12}
          >
            <Grid item xs={12} md={6}>
              <div color="secondary" style={{ height: "755px", flex: "0 0 100%" }}>
                <CustomDataGrid
                  rows={setDashboardItem}
                  getRowId={(row) => row.BuchungNr}
                  columns={columns}
                  pageSize={dashboardParams.limit}
                  // onRowClick={handleRowClick}
                  onSelectionModelChange={handleSelectionModelChange}
                  selectionModel={selectedRowIds}
                  page={dashboardParams.page - 1}
                  // sortModel={sortModel}
                  onSortModelChange={handleSortModelChange}
                  getRowClassName={getRowClassName}
                  onPageChange={handlePageChange}
                  onPageSizeChange={handlePageSizeChange}
                  rowHeight={33}
                  headerHeight={40}
                  pagination
                  disableColumnMenu
                  loading={tableLoader}
                  rowsPerPageOptions={[10, 20, 50]}
                  scrollbarSize={0}
                  paginationMode="server"
                  paginationComponent={Pagination}
                  rowCount={queueCount}
                  localeText={localeText}
                  components={{
                    FilterPanel: CustomGridPanel,
                    LoadingOverlay: CustomLoadingOverlay,
                    NoRowsOverlay: CustomNoRowsOverlay,
                  }}
                  componentsProps={{
                    pagination: {
                      labelRowsPerPage: t('common_rows_per_page'),
                    }
                  }}
                />
              </div>
            </Grid>
            <Grid item  xs={12} md={6} >
              <CustomDiv sx={{ width: "100%",height: "755px" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <CustomTabs
                    value={value}
                    onChange={handleChange}
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: "#8B9635",
                      },
                    }}
                    aria-label="basic tabs example"
                  >
                    <Tab
                      className="commonqueueTab"
                      label={t("details")}
                      {...a11yProps(0)}
                    />
                    <Tab
                      className="commonqueueTab"
                      label={t("common_pdf")}
                      {...a11yProps(1)}
                    />
                  </CustomTabs>
                </Box>
                <TabPanel value={value} index={0}>
                  {Object.keys(selectedRow).length !== 0 ? (
                    Object.keys(selectedRow).map((item, index) =>
                      ((item === "HabenKonto" ||
                        item === "SollKonto" ||
                        item === "Adresse" ||
                        item === "BelegartNr" ||
                        item === "AdressNr" ||
                        item === "Kurs" ||
                        item === "Buchungstext" ||
                        item === "SteuercodeNr" ||
                        item === "BetragSW" ||
                        item === "BuchungNr") &&
                        selectedRow[item]) ? (
                        <span style={{ display: "flex" }} key={index}>
                          <span
                            style={{
                              display: "Flex",
                              flex: "0 0 30%",
                              padding: "10px 0px 0px 10px",
                            }}
                          >
                            {t(item)}:
                          </span>
                          <span style={{ padding: "10px 0px 0px 0px" }}>
                            {item === "Belegdatum"
                              ? formateD(selectedRow[item])
                              : (item === "BetragSW" ? formattedAmount(parseFloat(selectedRow[item])) : selectedRow[item])}
                          </span>
                        </span>
                      ) : (
                        ""
                      )
                    )
                  ) : (
                    <p>{t('common_no_rows_selected')}</p>
                  )}
                </TabPanel>
                <TabPanel className='pdfsection' value={value} index={1} sx={{ height: '100%' }}>
                  <div style={{ position: 'relative', height: '100%' }}>
                    {cid ?
                      (pdfData !== null ? ((pdfLoader) ? (<Loader />) : <iframe
                        title="PDF Viewer"
                        src={`data:application/pdf;base64,${pdfData}`}
                        width="100%"
                        height="100%"
                      ></iframe>) : (<p>{t('no_pdf_found')}</p>)) :
                      // !pdfLoader ?
                      //  (
                      //   <CustomCreateBtn variant="contained" size="small" severity="sucess" sx={{ position: "absolute", marginTop: '30%', marginLeft: "45%" }} onClick={generateAndShowPdf}>
                      //     {t('create_pdf')}
                      //   </CustomCreateBtn>
                      // ) 
                      // : (<Loader />)): 
                      (<p>{t('no_pdf_found')}</p>)
                    }
                  </div>
                </TabPanel>
              </CustomDiv>
            </Grid>
          </Grid>
          {/* <CustomSnackbar
            open={open}
            onClose={handleClose}
            TransitionComponent={transition}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            key={transition ? transition.name : ''}
          >
            <Alert onClose={handleClose} severity={pushAlert} sx={{ width: '100%' }}>
              {isPushed !== '' ? t(isPushed) : ''}
            </Alert>
          </CustomSnackbar> */}
        </Grid>
      )}
    </>
  );
}

export default Dashboard;
