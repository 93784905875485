import React, { useCallback, useRef,useReducer } from 'react'
import { DataGrid, GridOverlay } from '@mui/x-data-grid';
import { useState, useEffect } from 'react';
import { Pagination,Autocomplete } from "@mui/material";
import Box from '@mui/material/Box';
import DoneIcon from '@mui/icons-material/Done';
import Typography from '@mui/material/Typography';
import { TextField } from '@mui/material';
import { changeQueueEmail, createOtInvoiceSendArchive, getHistory, listDraftByAddress, loadAddress } from '../../services/Services';
import {  loadPdf, generatePdf } from '../../services/Services';
import '../archive/archive.scss';
import { openAlertSnackBar,closeAlertSnackBar } from "../../shared/common/utilites/notification/snackbar";
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from "@mui/icons-material/Search";
import { styled } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import Tab from '@mui/material/Tab';
import { useFormik } from 'formik';  
import * as Yup from "yup";
import PropTypes from 'prop-types';
import { Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GridFilterPanel } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import Loader from '../../shared/common/utilites/PageLoader/PageLoader';
import { createObject, encryptForm } from '../../services/commonService';
import ListIcon from '@mui/icons-material/List';
import HistoryIcon from '@mui/icons-material/History';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import { getEncryptedItem, setEncryptedItem, removeEncryptedItem } from '../../services/localStorageUtil';
import { useTheme } from '@emotion/react';
import { CustomModal } from '../../shared/common/utilites/customModal';
import { DataGridActionTypes, DataGridReducer } from "../../reducer/datagridReducer";
import debounce from 'lodash.debounce'

const CustomDataGrid = styled(DataGrid)(({ theme }) =>
({
  "& ::-webkit-scrollbar": {
    width: "6px",
    height:"6px"
  },
  "& ::-webkit-scrollbar-track": {
    backgroundColor: "#0000"
  },
  "& ::-webkit-scrollbar-thumb": {
    borderRadius: "10px",
    boxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
    backgroundColor: "#f5f5f5"
  },
  border: theme.palette.mode === 'light' ? '1px solid #ccc' : '1px solid rgba(255, 255, 255, 0.12)',
  '& .css-1bf0mkr-MuiButtonBase-root-MuiCheckbox-root.Mui-checked': {
    color: '#8B9635',
  },
  '&.MuiButtonBase-root .MuiIconButton-root .MuiIconButton-sizeSmall .MuiDataGrid-menuIconButton .css-xfvph6': {
    display: 'none'
  },
  '& .css-cipkih-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate': {
    color: '#8B9635',
  },
  '& .MuiDataGrid-cell:focus': {
    outline: 'unset'
  },
  '& .MuiDataGrid-cell:focus-within': {
    outline: 'unset'
  },
 '& .MuiDataGrid-row.Mui-selected.commonSelected': {
    backgroundColor: '#8B9635',
    WebkitTransition: '-webkit-transform .5s,opacity 1.5s,background .5s',
    transition: '-webkit-transform .5s,opacity .5s,background .5s',
    color: theme.palette.mode === "light" ? "#fff" : "#000",
    '&.MuiSvgIcon-fontSizeMedium':{
      color:'#000'
    },
    '& .Mui-checked': {
      color: theme.palette.mode === 'light' ? '#000' : '#fff',
      cursor: 'pointer'
    },
    '&:hover': {
      cursor: 'pointer'
    }
  },
  '& .MuiDataGrid-row': {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  '& .MuiDataGrid-footerContainer': {
    borderTop: 'unset'
  },
  "& .MuiDataGrid-columnHeader:focus": {
    outline: "none",
  },
}));

const CustomDataGridItem = styled(DataGrid)(({ theme }) =>
({
  border: theme.palette.mode === 'light' ? '1px solid #ccc' : '1px solid rgba(255, 255, 255, 0.12)',
  '& .css-1bf0mkr-MuiButtonBase-root-MuiCheckbox-root.Mui-checked': {
    color: '#8B9635',
  },
  '& .css-cipkih-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate': {
    color: '#8B9635',
  },
  '& .MuiDataGrid-cell:focus': {
    outline: 'unset'
  },
  '& .MuiDataGrid-cell:focus-within': {
    outline: 'unset'
  },
  '& .MuiDataGrid-row.Mui-selected': {
    '& .Mui-checked': {
      color: theme.palette.mode === 'light' ? '#8B9635' : '#fff',
      cursor: 'pointer'
    },
  },
  '& .MuiDataGrid-row.Mui-selected.commonSelected': {
    backgroundColor: '#8B9635',
    WebkitTransition: '-webkit-transform .5s,opacity 1.5s,background .5s',
    transition: '-webkit-transform .5s,opacity .5s,background .5s',
    color: theme.palette.mode === "light" ? "#fff" : "#000",
    '& .Mui-checked': {
      color: theme.palette.mode === 'light' ? '#000' : '#fff',
      cursor: 'pointer'
    },
    '&:hover': {
      cursor: 'pointer'
    }
  },
  '& .MuiDataGrid-row': {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  '& .MuiDataGrid-footerContainer': {
    borderTop: 'unset',
    display: 'none'
  },
  "& .MuiDataGrid-columnHeader:focus": {
    outline: "none",
  },
}));

const CustomGridPanel = styled(GridFilterPanel)(({ theme }) => ({
  '& .MuiFormLabel-root.Mui-focused': {
    color: theme.palette.mode === 'light' ? '#000' : '#fff',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: theme.palette.mode === 'light' ? '#000' : '#fff',
  }
}));

function CustomLoadingOverlay() {
  return (<Loader />);
}

const CustomBtn = styled(LoadingButton)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '20px',
  background: 'unset',
  border: '2px solid #8B9635',
  padding: "0px 16px 0px 16px",
  width:'100%',
  display:"flex",
  alignItems:"center",
  color: theme.palette.mode === 'light' ? '#000' : '#fff',
  '&:hover': {
    backgroundColor: '#8B9635',
    color: theme.palette.mode === "light" ? "#fff" : "#000"
  },
  '&.MuiLoadingButton-root.Mui-disabled': {
    background: theme.palette.mode === 'light' ? '#fff' : '#000',
    padding: '0px 0px',
    span: {
      color: '#8B9635'
    }
  },
}));


const CustomCreateBtn = styled(LoadingButton)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '20px',
  background: 'unset',
  border: '2px solid #8B9635',
  padding: '4px 10px',
  margin: '7px 0 7px 0px',
  color: theme.palette.mode === 'light' ? '#000' : '#fff',
  '&:hover': {
    backgroundColor: '#8B9635',
    color: theme.palette.mode === "light" ? "#fff" : "#000"
  },
  '&.MuiLoadingButton-root.Mui-disabled': {
    background: theme.palette.mode === 'light' ? '#fff' : '#000',
    padding: '0px 0px',
    span: {
      color: '#8B9635'
    }
  },
}));


const CustomTabs = styled(Tabs)(({ theme }) => ({
  "& .commonqueueTab": {
    textTransform: "none",
    minHeight: '36px',
  },
  '&.MuiTabs-root': {
    minHeight: '0px',
  },
  '& .MuiButtonBase-root.Mui-selected': {
    color: theme.palette.mode === 'light' ? '#000' : '#ffff',
  },
}));


const CustomDiv = styled('div')(({ theme }) => ({
  display: "",
  flex: '0 0 33%',
  borderRadius: '10px',
  border: theme.palette.mode === 'light' ? '1px solid #ccc' : '1px solid #333',
}))


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ height: '100%' }}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <span>{children}</span>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function CustomNoRowsOverlay() {
  const { t } = useTranslation(["language", "common"]);
  return (
    <GridOverlay>
      <Typography>{t('common_no_data_available')}</Typography>
    </GridOverlay>
  );
}

const defaultQueueParams = { 
  limit: 20, 
  page: 1, 
  column: '', 
  order: '' ,
  addressId:'',
  search:''
};

const marginTopValue = '131px';

const defaultAddressParams = { 
  page: 1, 
};

const schedulerInitialState ={
  data:[],
}

function Archive() {
  const [serviceData, setServiceData] = useState([]);
  const { t, i18n } = useTranslation(["language", "common"]);
  const [tableLoader, setTableLoader] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [isPdf, setIsPdf] = useState(true);
  const [isHistory, setIsHistory] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [value, setValue] = useState(0);
  const [email, setEmail] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [pdfData, setPDFData] = useState(null);
  const [pdfLoader, setPdfLoader] = useState(false);
  const [queueCount, setQueueCount] = useState(0);
  const [lastCid, setLastSelectedCid] = useState(null);
  const [openSendModal, setOpenSendModal] = useState(false);
 
  const [historyData, sethistory] = useState([]);
  const [historyLoader, sethistoryLoader] = useState(false);
  const [languageChange, setlanguageChange] = useState(false);
  const [queueParams, setQueueParams] = useState(defaultQueueParams);
  
  const theme = useTheme();
  const selectedInvoiceId = useRef([]);
  const currentLanguage = i18n.language;
  
  const [addressData, setAddressData] = useState([]);
  const [addressId, setAddressId] = useState('');
  const [searchColumnAddress, setSearchColumnAddress] = useState("");
  const companyListRef = useRef(null);
  const [addressOptionParams, setAddressOptionParams] = useState(defaultAddressParams);
  const [addressOption, addressOptionDispatch] = useReducer(DataGridReducer, schedulerInitialState);
 
  const isSearchAddress = useRef(true);

  useEffect(()=>{
    const flattenedArray = [].concat(...addressOption.data);
    setAddressData([flattenedArray]);
  },[addressOption]);

  const handleScrollAddress = (event) => {
    const { target } = event;
    const lastEntryIndex = addressData[0].length - 10; 
    const count = Math.ceil((addressData[0].length)/100); 

    if (
      target.scrollTop + target.clientHeight >=
      target.scrollHeight - target.children[lastEntryIndex].clientHeight
    ) {
      isSearchAddress.current = false;
      debouncedFilterScroll(count+1);
    }
  };

  const debouncedFilterScroll = debounce((query) =>
    setAddressOptionParams({page:query})
  , 300);

  const loadAddressData = useCallback(() => {
    const controller4 = new AbortController();
    const signal4 = controller4.signal;
    const d = new FormData();
    d.append("limit", 100);
    d.append("page", isSearchAddress.current ? 1 : addressOptionParams.page);
    d.append("column", "companyname");
    d.append("order", "asc");
    d.append("search", searchColumnAddress);
    d.append("iscompanyname", true);
  //d.append("isAuthUser", '');
    // setAddressData([]);
    let object = createObject(d);
    let encryptedToken = encryptForm(object);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
    // const getTemplateData = setTimeout(() => {
       loadAddress(encrData, signal4)
        .then((res) => {
            if (res.status) {
              // setAddressData(res.data);
              if(isSearchAddress.current)
                {
                  addressOptionDispatch({
                    type:DataGridActionTypes.ASSIGN_DATA,
                    payload:res.data
                  })
                }else{
                  addressOptionDispatch({
                    type:DataGridActionTypes.APPEND_DATA,
                    payload:res.data
                  })
                }
            } else {
              openAlertSnackBar('error',res.message,'127px');
            }
        })
        .catch((err) => {
          openAlertSnackBar('error','common_alert','127px')
        })
    // }, 1000);

    return () => {
      controller4.abort();
      // clearTimeout(getTemplateData);
      // setIsMounted(false);
    };
  }, [searchColumnAddress,addressOptionParams]);

  useEffect(() => {
    loadAddressData();
  }, [loadAddressData]);

  const handleAddressChange = (event)=>{
    isSearchAddress.current = true ;
    if(!event)
    {
      setSearchColumnAddress("");
      setAddressId("");
      setQueueParams((previousParams)=>({
        ...previousParams,
        addressId:'',
        page:1
      }))
      return;
    }
    const addressItem =addressData[0].filter(item=>item.cid ===event.cid)
   if(addressItem.length>0){
      setQueueParams((previousParams)=>({
        ...previousParams,
        addressId:addressItem[0].cid,
        page:1
      }))
    };
    setSearchColumnAddress(addressItem[0].companyname);
    setAddressId(event);
  }

  // const handleOpenSendModal = () => {
  //   if(selectedRowIds.length===0){ 
  //     return false
  //   };
  //   let cutomerEmail = null;
  //   const reminderValue = serviceData.filter((item)=>item.cid === selectedRowIds[0])[0]
  //   if(reminderValue)
  //   {
  //     cutomerEmail=reminderValue.email;
  //   }
  //   setEmail(cutomerEmail)
  //   setOpenSendModal(true)
  // };
  const handleCloseSendModal = () => {
    setOpenSendModal(false);
  }

  const initialValuesEmail = {
    email_value: ''
  }
  const validationSchemaEmail = Yup.object().shape({
    email_value: Yup.string().test(
      'isMultipleEmails',
      t('invalid_email'),
      value => {
        if (!value) return false; 
        const emails = value.split(',').map(email => email.trim());
        const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i;
        return emails.every(email => emailRegex.test(email));
      }
    )
  });
  const formikEmail = useFormik({
    initialValues: initialValuesEmail,
    validationSchema:validationSchemaEmail,
    onSubmit: (values) => {
      updateEmail();
    }
  })

  const itemsColumn = [
    { field: 'lineitemid', headerName: t('item_id'), flex: 0.8, },
    { field: 'description', headerName: t('item_name'), flex: 1.5, },
    { field: 'price', headerName: t('sell_price'), sortable: false, flex: 0.8, },
    { field: 'taxname', headerName: t('tax_code'), sortable: false, flex: 1.8, },
  ];

  const columns = [
    { field: 'invoiceid', headerName: t('invoice_number'), flex: 1,minWidth:100 },
    { field: 'customer', headerName: t('customer'), flex: 1.5,minWidth:100 },
    {
      field: 'invoice_date', headerName: t('invoice_date'), flex: 0.8,minWidth:100,
      valueFormatter:
        currentLanguage === "en"
          ? (params) =>
            params.value !== '' ? new Date(params.value).toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
              : params.value
          : (params) =>
            params.value !== '' ? new Date(params.value).toLocaleDateString("de-DE", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
              : params.value
    },
    { field: 'total_price', headerName: t('total_price'),minWidth:80, flex: 0.8, sortable: false,valueFormatter:(params) =>params.value ? formattedAmount(parseFloat(params.value)) : params.value},
    {
      field: "guthaben",
      headerName: t('queue_credit'),
      sortable: false,minWidth:100,
      flex: 1.1,
      renderCell: (params) => {
        const {value}=params;
        if(value){
        }
        const isClicked = params.id === lastCid;
        const iconColor = isClicked ? '#000' : '#8B9635';
        return (
          (value)?(<><DoneIcon sx={{ fontSize: 30 ,color:iconColor}} /> <p>({formattedAmount(parseFloat(params.row.creditused))})</p></>) :''
        );
      },
    },
    { field: 'status', headerName: t('status'),minWidth:100, flex: 1.2 ,
      renderCell: (params) => `${params.row.status} ${(params.row.senton)&&(params.row.status.toLowerCase()==='sent')?formateD(params.row.senton):''}
      ${(params.row.iswithoutpushtoproffix)&&(params.row.status.toLowerCase()==='done') ? '(without Proffix)':''}`,
    }
  ];

  const localeText = {
    noRowsLabel: t('common_no_rows'),
    footerRowSelected: (count) => `${count} ${t('common_row_selected')}`,
  };

  const formateD = useCallback((date) => {
    if (currentLanguage === "en") {
      return new Date(date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
    } else {
      return new Date(date).toLocaleDateString('de-DE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
    }
  }, [currentLanguage])

  const formatDate = useCallback((data) => {
    if (currentLanguage === "en") {
      return new Date(data).toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      })
    } else {
      return new Date(data).toLocaleDateString('de-DE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      })
    }
  }, [currentLanguage])

  const handlePageChange = (event, newPage) => {
    setQueueParams((previousParams)=>({
      ...previousParams,
      page:event + 1
    }))
    removeEncryptedItem("selectedRowIds");
    selectedInvoiceId.current =[];
  };

  const handleRowClick = (params) => {
    setSelection(params);
  };
  const formattedAmount = (amount)=>{
    return amount.toLocaleString('de-CH', { 
    useGrouping: true,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2 
  });
 }
  const handleSortModelChange = (newModel) => {
    if (newModel.length > 0) {
      setQueueParams((previousParams)=>({
        ...previousParams,
        column:newModel[0].field,
        order:newModel[0].sort,
        page:1
      }))
    }else{
      setQueueParams((previousParams)=>({
        ...previousParams,
        column:"",
        order:"",
        page:1
      }))
    }
  };
  
  const handleSelectionModelChange = (newSelection) => {
    if (newSelection.length !== 0) {
      setEncryptedItem('selectedRowIds', JSON.stringify(newSelection));
    } else {
      !languageChange && removeEncryptedItem('selectedRowIds');
    }
    setPDFData(null);
    setLastSelectedCid(null);
    setSelectedRowIds(newSelection);
    setIsPdf(false);
    setValue(0);
    if (newSelection.length === 0) return (setSelectedRowIds([]), setSelectedRow([]), setSelectedItem([]), setIsHistory(false));
    const filteredObject = serviceData.filter(
      (obj) => obj.cid === newSelection[newSelection.length - 1]
    );
    selectedInvoiceId.current =[];
    if (filteredObject.length === 0) return (setSelectedRow([]), setSelectedItem([]), setIsHistory(false));
    setIsPdf(true);
    setLastSelectedCid(filteredObject[0].cid);
    setIsHistory(true);
    setSelectedItem(filteredObject[0]['items']);
    filteredObject.forEach((it) => {
      const objectWithoutExcludedKey = Object.keys(it).reduce(
        (result, key) => {
          if (key === "cid") {
            const { [key]: value, ...rest } = it;
            result = { ...result, ...rest };
          }
          return result;
        },
        {}
      );
      setSelectedRow(objectWithoutExcludedKey);
    });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 3) {
      getPdfData();
    } else {
      setPDFData(null);
    }
    if (newValue === 2) {
      loadHistory()
    }
  };

  const handlePageSizeChange = (event) => {
    setQueueParams((previousParams)=>({
      ...previousParams,
      limit:event,
      page:1
    }))
  };

  const sendModalContent = () => {
    return (
      <>
        <p id="child-modal-description">
          {email ? t('confirm_send_reminder')+' '+ email +'?': t('confirm_send')}
        </p>
        <Grid item container xs={12} justifyContent="flex-end" spacing={1}>
          <Grid item xs={6}>
            <CustomBtn onClick={handleCloseSendModal} sx={{ mt: 2 }}>
              {t('common_cancel')}
            </CustomBtn>
          </Grid>
          <Grid item xs={6}>
            <CustomBtn onClick={handleResend} sx={{ mt: 2 }}>
              {t('common_resend')}
            </CustomBtn>
          </Grid>
        </Grid>
      </>
    )
  }

  const handleResend = () => {
    if (selectedRowIds.length===0) { return }
    const cid = selectedRowIds[0];
    handleCloseSendModal();
    const currentLanguage = i18n.language;
    const _formData = new FormData()
    _formData.append("id", JSON.stringify(cid));
    _formData.append("sendType", 'reminder');
    _formData.append("language", currentLanguage);
  //  _formData.append("isAuthUser", '');
    _formData.append("userName", userDetails?.username);
    let object = createObject(_formData);
    let encryptedToken = encryptForm(object);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
    setSendLoading(true);
    createOtInvoiceSendArchive(encrData)
      .then(res => {
        setSendLoading(false);
        if (res.status) {
          openAlertSnackBar('success',res.message,marginTopValue);
          loadInvoiceDraftByAddressData(true);
        } else {
          openAlertSnackBar('error',res.message,marginTopValue);
        }
      }).catch((err) => {
        setSendLoading(false);
        openAlertSnackBar('error','common_alert',marginTopValue)
      });
  }

  const setSelection = useCallback((params = '') => {
    if (params !== '') {
      serviceData.forEach((it, index) => {
        if (it.cid === params.id) {
          const objectWithoutExcludedKey = Object.keys(it).reduce((result, key) => {
            if (key === "cid") {
              const { [key]: value, ...rest } = it;
              result = { ...result, ...rest };
            }
            return result;
          }, {});
          setSelectedRow(objectWithoutExcludedKey);
          setSelectedItem(it['items']);
        }
      });
    }
  }, [serviceData]);

  const loadInvoiceDraftByAddressData = useCallback((isMounted) => {
    const controller = new AbortController();
    const signal =controller.signal;
    const params = new FormData();
    params.append("limit",queueParams.limit);
    params.append("page",queueParams.page);
    params.append("column",queueParams.column);
    params.append("order",queueParams.order);
    params.append("search",queueParams.search);
    params.append("addressId",queueParams.addressId);
    setServiceData([]);
    let object = createObject(params);
    let encryptedToken = encryptForm(object);
    let encrData = new FormData();
    setTableLoader(true);
    setIsLoading(false);
    encrData.append("encrToken", encryptedToken);
    listDraftByAddress(encrData,signal)
      .then((res) => {
        if (isMounted) {
          let editArray = res.data.map((item) => {
            if ((item.senton) && (!isNaN(new Date(item.senton))) && (item.status === 'Sent')) {
              return {
                ...item
              }
            }
            return item;
          })
          setServiceData(editArray);
          setTableLoader(false);
          if (res.status) {
            setQueueCount(Number(res.filtered));
          } else {
            setQueueCount(0);
          }
          setValue(0)
        }
      }).catch((err) => {

      })

  },[queueParams])

  useEffect(() => {
    let userData = getEncryptedItem('userData');
      if(userData)
      {
          let userDataobj = JSON.parse(userData)
          setUserDetails(userDataobj);
      }
    let isMounted = true;
    loadInvoiceDraftByAddressData(isMounted);
    return () => {
      isMounted = false;
      setServiceData([]);
      closeAlertSnackBar();
    };
  }, [loadInvoiceDraftByAddressData]);

  

  useEffect(() => {
    const handleBeforeUnload = () => {
      removeEncryptedItem("selectedRowIds");
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      removeEncryptedItem('selectedRowIds');
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    setlanguageChange(true);
  }, [currentLanguage]);


  const lastSelectedRowIndex = selectedRowIds.length > 0 ? selectedRowIds[selectedRowIds.length - 1] : null;
  const getRowClassName = useCallback((params) => {
    if (lastSelectedRowIndex !== null && params.id === lastSelectedRowIndex) {
      return 'commonSelected';
    }
    if (lastSelectedRowIndex === null) {
      return 'commonSelected';
    }
    return '';

  }, [lastSelectedRowIndex]);

  
  const updateEmail = () => {
    const _formData = new FormData();
    selectedInvoiceId.current = selectedRowIds ;
    _formData.append("cid", JSON.stringify(selectedRowIds[0]));
    _formData.append("email", formikEmail.values.email_value);
    _formData.append("userName", userDetails?.username);
    let object = createObject(_formData);
    let encryptedToken = encryptForm(object);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
    setSendLoading(true);
    changeQueueEmail(encrData)
    .then(res => {
      setSendLoading(false);
      if (res.status) {
        loadInvoiceDraftByAddressData(true);
        formikEmail.resetForm();
      } else {
        openAlertSnackBar('error',res.message,'131px');
      }
    }).catch((err) => {
      setSendLoading(false);
     openAlertSnackBar('error','common_alert','131px')
    });
  }

  const getPdfData = useCallback(() => {
    setPdfLoader(true);
    setPDFData(null);
    const _formData = new FormData();
    _formData.append("id", JSON.stringify(lastCid));
    let object = createObject(_formData);
    let encryptedToken = encryptForm(object);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
    loadPdf(encrData)
      .then(res => {
        setPdfLoader(false);
        if (res.status) {
          setPDFData(res.data);
        }
      });
  }, [lastCid])

  const generateAndShowPdf = useCallback(() => {
    setPdfLoader(true);
    const _formData = new FormData()
    _formData.append("id", JSON.stringify(lastCid));
    let object = createObject(_formData);
    let encryptedToken = encryptForm(object);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
    generatePdf(encrData)
      .then(res => {
        setPdfLoader(false);
        if (res.status) {
          setPDFData(res.data);
        }
      });
  }, [lastCid])

  const loadHistory = () => {
    sethistoryLoader(true);
    let historyForm = new FormData();
    historyForm.append('id', selectedRow.invoiceid);
    let object = createObject(historyForm);
    let encryptedToken = encryptForm(object);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
    getHistory(encrData)
      .then(response => {
        if (response.status) {
          sethistory(response.data);
          sethistoryLoader(false);
        } else {
          sethistoryLoader(false);
          openAlertSnackBar('error',response.message,'131px');
        }
      }).catch((err) => {
        sethistoryLoader(false);
        openAlertSnackBar('error','common_alert','131px')
      })
  }

  const handleSearchKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      setQueueParams((previousParams)=>({
        ...previousParams,
        search:event.target.value,
        page:1
      }))
    }
  }

  return (
    <>
      {
        isLoading ?
          (
            <Loader />
          )
          : (
            <Grid container spacing={1} sx={{ margin: "10px 0 0 0"}}>
              <Grid item container xs={12} spacing={1} alignItems='center'>
                  <Grid item xs={12}  xl={2.5} >
                     <Typography
                          variant="h6"
                          noWrap
                          component="span"
                        >
                          {t('common_archive')}
                        </Typography>
                   </Grid> 
                  <Grid item container xs={12} md={12} lg={6} xl={4} spacing={1} justifyContent="flex-end" sx={{[theme.breakpoints.down('lg')]: {
                        justifyContent: 'flex-start'
                      }}} >
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label={t("common_search")}
                        id="searchInvoices"
                        variant="outlined"
                        margin="normal"
                        size="small"
                        onKeyDown={handleSearchKeyDown}
                        fullWidth
                        sx={{m:0,width: "100%" }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            size="small"
                            fullWidth
                            sx={{  width: "100%" }}
                            value={addressId || null}
                            isOptionEqualToValue={(option, value) => option.cid === value.cid}
                            ListboxProps={{
                              ref: companyListRef,
                              onScroll: handleScrollAddress,
                              className: "autocompleteList",
                              style:{maxHeight: '225px'}
                            }}
                            onChange={(event, value) => {
                              handleAddressChange(value);
                            }}
                            inputValue={searchColumnAddress}
                            onInputChange={(event, newInputValue) => {
                              isSearchAddress.current =true;
                              setSearchColumnAddress(newInputValue);
                            }}
                            options={addressData[0]}
                            getOptionLabel={(option) => option?.companyname}
                            renderOption={(props, option) => (
                              <Box component="li" {...props} key={option?.cid}>
                                {option?.companyname}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t("common_company_name") }
                              />
                            )}
                          />
                        </Grid>
                  </Grid>
                  {/* <Grid 
                      item 
                      container xs={12} md={12} lg={6} xl={5.5}
                      spacing={1} 
                      justifyContent='flex-end'
                      sx={{[theme.breakpoints.down('md')]: {
                        justifyContent: 'flex-start'
                        }}}
                      >
                        {(selectedRowIds.length > 0) && (
                          <Grid item xs={12} sm={4} md={2.5} >
                              <CustomBtn  loading={sendLoading} variant="contained" size="small" severity="sucess" title={t("common_resend")} onClick={handleOpenSendModal}>
                                {t('common_resend')}
                              </CustomBtn>
                            </Grid>
                        )}
                  </Grid>  */}
              </Grid>
              <Grid item container xs={12} spacing={2}>
                  <Grid item xs={12} md={6.5} style={{ minHeight: '755px'}}>
                    <CustomDataGrid
                      rows={serviceData}
                      getRowId={(row) => row.cid}
                      columns={columns}
                      pageSize={queueParams.limit}
                      onRowClick={handleRowClick}
                      onSelectionModelChange={handleSelectionModelChange}
                      selectionModel={selectedRowIds}
                      page={queueParams.page-1}
                      onSortModelChange={handleSortModelChange}
                      getRowClassName={getRowClassName}
                      onPageChange={handlePageChange}
                      onPageSizeChange={handlePageSizeChange}
                      loading={tableLoader}
                      rowHeight={33}
                      headerHeight={40}
                      pagination
                      paginationMode="server"
                      rowsPerPageOptions={[10, 20, 50]}
                      paginationComponent={Pagination}
                      rowCount={queueCount}
                      localeText={localeText}
                      scrollbarSize={0}
                      componentsProps={{
                        pagination: {
                          labelRowsPerPage: t('common_rows_per_page'),
                        }
                      }}
                      disableColumnMenu 
                      components={{
                        FilterPanel: CustomGridPanel,
                        LoadingOverlay: CustomLoadingOverlay,
                        NoRowsOverlay: CustomNoRowsOverlay
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={5.5}>
                    <CustomDiv sx={{ width: '100%', flex: '1 0 43%',height: '755px'}}>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <CustomTabs
                          value={value}
                          onChange={handleChange}
                          TabIndicatorProps={{
                            style:
                            {
                              backgroundColor: '#8B9635',
                            },
                          }}
                          aria-label="basic tabs example">
                          <Tab className='commonqueueTab' icon={<ListIcon />} iconPosition="start" label={t('header')} title={t('header')}  {...a11yProps(0)} />
                          <Tab className='commonqueueTab' icon={<FileCopyIcon />} iconPosition="start" label={t('items')} title={t('items')}  {...a11yProps(1)} />
                          {(isHistory) && (
                            <Tab className='commonqueueTab' icon={<HistoryIcon />} iconPosition="start" label={t('common_history')} title={t('common_history')}  {...a11yProps(1)} />)}
                          {(isPdf) && (
                            <Tab className='commonqueueTab' icon={<InsertDriveFileIcon />} iconPosition="start" label={t('common_pdf')} title={t('common_pdf')}  {...a11yProps(2)} />
                          )}
                        </CustomTabs>
                      </Box>
                      <TabPanel value={value} index={0}>
                        {
                          Object.keys(selectedRow).length !== 0 ? (
                            Object.keys(selectedRow).map((item, index) => (
                              (item !== 'items' &&  item !== 'street' && item !== 'cid' && item !== 'housenumber' 
                                && item !== 'header' 
                                && item !== 'bank'  
                                && item !== 'incomeaccount' && item !== 'expenseaccount'&& item !== 'header' 
                                && item !== 'customerinformation' && item !== 'accountingnoorprojectno'&& item !== 'our_sign' 
                                && item !== 'zip' && item !== 'city' && item !== 'country' && item !== 'iolibuid' 
                                && item !== 'qr_esr_iban' && item !== 'countryid' && item !== 'cust_sign'
                                && item !== 'addressemail' && item !== 'addressid'&& item !== 'free_text_header' 
                                && item !== 'taxcode'  && item !== 'templateid'
                                && item !== 'module'
                                && item !== 'currencycode' 
                                && item !== 'condition' 
                                && item !== 'languageid' 
                                && item !== 'companyname' && item !== 'invoicetype' 
                                && selectedRow[item]) ? <span style={{ display: 'flex' }} key={index}><span style={{ display: 'Flex', flex: '0 0 30%', padding: '10px 0px 0px 10px' }}>{t(item)}:</span><span style={{ padding: '10px 0px 0px 0px' }}>{(item === "invoice_date" || item === "senton" || item === "createddate") ? ((selectedRow[item]) && formatDate(selectedRow[item])) : (item === "status" ? ((selectedRow[item].startsWith("Sent")) ? "Sent" : selectedRow[item]) : ((item === "total_price" ? formattedAmount(parseFloat(selectedRow[item])):selectedRow[item])))}</span></span> : ''
                            ))
                          ) : (
                            <span>{t('common_no_rows_selected')}</span>
                          )
                        }
                      </TabPanel>
                      <TabPanel value={value} index={1}>
                        <div style={{ height: '635px' }}>
                          <CustomDataGridItem 
                            rows={selectedItem}
                            getRowId={(row) =>  selectedItem.indexOf(row)}
                            columns={itemsColumn}
                            localeText={localeText}
                          />
                        </div>
                      </TabPanel>
                      <TabPanel value={value} index={2}>
                        <div className='historySection'>
                          {
                            !historyLoader ?
                              (historyData.length !== 0 ?
                                historyData.map((d, index) => {
                                  return (
                                    <Timeline key={index} position="alternate">
                                      <TimelineItem>
                                        <TimelineOppositeContent color="text.secondary" sx={{ fontStyle: 'italic' }}>
                                          {formatDate(d.date)}
                                        </TimelineOppositeContent>
                                        <TimelineSeparator>
                                          <TimelineDot sx={{ py: '9px', px: '9px' }} />
                                          <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent>{(d.iswithoutpushtoproffix)&&(d.code.toLowerCase()==='done') ? 'This invoice has been Done without Proffix':d.message}
                                          <div style={{ color: '#8B9635' }}>{d.user && d.user}</div>
                                        </TimelineContent>
                                      </TimelineItem>
                                    </Timeline>
                                  )
                                })
                                : (
                                  <span>{t('common_no_history_found')}</span>
                                )) : (<Loader />)
                          }
                        </div>
                      </TabPanel>
                      <TabPanel className='pdfsection' value={value} index={3} sx={{ height: '100%' }}>
                        <div style={{ position: 'relative', height: '100%' }}>
                          {pdfData !== null ? (<iframe
                            title="PDF Viewer"
                            src={`data:application/pdf;base64,${pdfData}`}
                            width="100%"
                            height="100%"
                          ></iframe>) :
                            !pdfLoader ? (
                              <CustomCreateBtn variant="contained" size="small" severity="sucess" sx={{ position: "absolute", top: '35%', left: "45%" }} onClick={generateAndShowPdf}>
                                {t('common_generate_pdf')}
                              </CustomCreateBtn>
                            ) : (<Loader />)
                          }
                        </div>
                      </TabPanel>
                    </CustomDiv>
                  </Grid>
              </Grid>
              </Grid>
          )}
      <CustomModal
        openModal={openSendModal}
        onclose={handleCloseSendModal}
        header={t('common_resend')}
        content={sendModalContent} />
          
    </>
  )
}

export default Archive